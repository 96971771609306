import React, { Fragment } from "react";
// Import to Display skeleton while loading data
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
// Pagination
import Pagination from "../../components/Pagination";
import { connect, ConnectedProps } from "react-redux";
// Routing imports
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import "../style.css";
import { Menu, Transition } from "@headlessui/react";
import DeleteModal from "../../components/DeleteModal";

import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";

import AddEditRegisterInOutModal, {
  DocumentType,
  KeptAtType,
  RegisterMode,
  registerModes,
  RegisterType
} from "./AddEditRegisterInOut";
import { formatDate } from "../../helpers/formatDate";
import MultiSelect from "../../components/MultiSelect";
import { downloadFile } from "../../helpers/downloadFile";
import { Link } from "react-router-dom";
import Button from "../../components/Button";
import { ArrowDownTrayIcon, PlusIcon } from "@heroicons/react/24/outline";
import Popup from "../../components/Popup";
import TagManager from "react-gtm-module";
import timeTillMidnight, {
  timeFromMidnight
} from "../../helpers/timeTillMidnight";
import { formatClientName } from "../../helpers/formatClientName";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Register in out list"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export type BooleanString = "true" | "false" | null;

interface RegisterInOutDataListItem {
  _id: string;
  isDeleted: boolean;
  date: string;
  client: string;
  type: RegisterType;
  clientId: string;
  clientName: string;
  documentId: string;
  documentName: string;
  keptAtId: string;
  keptAtName: string;
  mode: RegisterMode;
  isOriginal: BooleanString;
  isReturnable: BooleanString;
  receivedBy: string;
  futureActionRequire: BooleanString;
  description: string;
}

const booleanOptions = ["Yes", "No"];
type BooleanType = (typeof booleanOptions)[number];

const searchTemplate = {
  dateFrom: "",
  dateTo: "",
  client: "",
  type: "" as RegisterType,
  document: "",
  manner: "",
  mode: "N/A" as RegisterMode,
  isOriginal: "" as BooleanType,
  isReturnable: "" as BooleanType,
  keptAt: "",
  receivedBy: "",
  futureActionRequire: "" as BooleanType,
  description: ""
};

type RegisterInOutSearchTemplate = typeof searchTemplate;

interface State {
  loading: boolean;
  downloading: boolean;
  rotate: boolean;
  posX: number;
  posY: number;
  showBackDrop: boolean;
  showSearch: boolean;
  searchTemp: RegisterInOutSearchTemplate;
  clientList: any[];
  documentList: DocumentType[];
  keptAtList: KeptAtType[];
  registerInOutList: RegisterInOutDataListItem[];
  totalRecords: number;
  modalOpen: boolean;
  typingTimeout: NodeJS.Timeout | undefined;
  selectedRow: RegisterInOutDataListItem | undefined;
  showEditModal: boolean;
  showDeleteModal: boolean;
  currPage: number;
  chunkSize: number;
  registerInOutRights: any;
}

class RegisterInOut extends React.Component<PropsFromRedux, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      downloading: false,
      rotate: false,
      posX: 0,
      posY: 0,
      showBackDrop: false,
      clientList: [],
      documentList: [],
      keptAtList: [],
      showSearch: false,
      searchTemp: searchTemplate,
      registerInOutList: [],
      totalRecords: 0,
      modalOpen: false,
      typingTimeout: undefined,
      selectedRow: undefined,
      showDeleteModal: false,
      showEditModal: false,
      currPage: 0,
      chunkSize: 25,
      registerInOutRights: (this.props as any).rights?.registerInAndOut
    };
  }

  headers = [
    "date",
    "client",
    "type",
    "document",
    "mode",
    "further action required",
    "description",
    "action"
  ];

  reports = [
    {
      text: "Pending for Action",
      path: "pending-for-action",
      icon: "outline/user"
    },
    {
      text: "Returnable",
      path: "returnable",
      icon: "outline/document-report"
    }
  ];

  mannerList = () => {
    return this.state.searchTemp.mode === "Hard Copy"
      ? ["Courier", "Hand Delivered"]
      : ["Email", "WhatsApp", "Other"];
  };

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 125 ? e.clientY - 125 : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: positionY,
      showBackDrop: true
    });
  };

  onDropdownClick = (showBackDrop: boolean, rotate: boolean) => {
    this.setState({ showBackDrop: showBackDrop, rotate: rotate });
  };

  getClientsList = () => {
    this.setState({ loading: true });
    agent.Clients.getClientList(
      (this.props as any).params?.firmId,
      0,
      100000,
      "",
      true
    )
      .then((res: any) => {
        this.setState({
          clientList: res.clients,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Error",
          "Error while fetching clients",
          "error"
        );
      });
  };

  openAddRegisterInOutModal = () => {
    if ((this.props as any).rights?.registerInAndOut) {
      this.setState({ modalOpen: true, showBackDrop: false });
      (this.props as any).updateCommon({
        currentModal: {
          modalName: "ADD_EDIT_REGISTER_IN_OUT_MODAL",
          fetchAgain: false
        }
      });
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  closeModal = (fetchAgain: boolean) => {
    (this.props as any).updateCommon({
      currentModal: { modalName: "", fetchAgain, editRegisterEntry: undefined }
    });
  };

  openDeleteModal = (selectedRow: RegisterInOutDataListItem) => {
    this.setState({ selectedRow, showBackDrop: false });
    this.deleteModalSetOpen(true);
  };

  deleteModalSetOpen = (open: boolean) => {
    this.setState({
      showDeleteModal: open
    });
  };

  openEditModal = (selectedRow: RegisterInOutDataListItem) => {
    if ((this.props as any).rights?.registerInAndOut) {
      this.setState({ showBackDrop: false });
      (this.props as any).updateCommon({
        currentModal: {
          modalName: "ADD_EDIT_REGISTER_IN_OUT_MODAL",
          fetchAgain: false,
          editRegisterEntry: selectedRow
        }
      });
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  getDocumentsList = () => {
    this.setState({ loading: true });
    const workSpaceId = (this.props as any).params.firmId;
    agent.RegisterInOut.listDocuments(workSpaceId, "", 0, 100000)
      .then((res: { documents: DocumentType[] }) => {
        this.setState({
          documentList: res.documents,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Error",
          "Error while fetching documents list",
          "error"
        );
      });
  };

  getKeptAtList = () => {
    this.setState({ loading: true });
    const workSpaceId = (this.props as any).params.firmId;
    agent.RegisterInOut.listKeptAt(workSpaceId, "", 0, 100000)
      .then((res: { documents: KeptAtType[] }) => {
        this.setState({
          keptAtList: res.documents,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Error",
          "Error while fetching kept at list",
          "error"
        );
      });
  };

  getRegisterInOutList = (download?: boolean) => {
    download
      ? this.setState({ downloading: true })
      : this.setState({ loading: true });
    const workSpaceId = (this.props as any).params.firmId;
    const { currPage, chunkSize, searchTemp } = this.state;

    const limit = chunkSize;
    const skip = chunkSize * currPage;

    const {
      dateFrom,
      dateTo,
      client,
      type,
      document,
      mode,
      manner,
      receivedBy,
      isOriginal,
      isReturnable,
      keptAt,
      futureActionRequire,
      description
    } = searchTemp;

    agent.RegisterInOut.getRegisterEntries(
      workSpaceId,
      download ? true : false,
      limit,
      skip,
      dateFrom ? timeFromMidnight(dateFrom).toISOString() : "",
      dateTo ? timeTillMidnight(dateTo).toISOString() : "",
      client,
      type,
      document,
      mode === "N/A" ? "" : mode,
      manner,
      receivedBy,
      isOriginal === "Yes" ? "true" : isOriginal === "No" ? "false" : null,
      isReturnable === "Yes" ? "true" : isReturnable === "No" ? "false" : null,
      keptAt,
      futureActionRequire === "Yes"
        ? "true"
        : futureActionRequire === "No"
        ? "false"
        : null,
      description
    )
      .then(
        (res: { filterResult: RegisterInOutDataListItem[]; total: number }) => {
          if (download) {
            downloadFile(res, "Register-in-out.xlsx");
            this.setState({ downloading: false });
            (this.props as any).onNotify(
              "Success",
              "Register in out list exported successfully",
              "success"
            );
          } else {
            const registerInOutList = res.filterResult.filter(
              (item: RegisterInOutDataListItem) => !item.isDeleted
            );

            this.setState({
              registerInOutList: res.filterResult,
              totalRecords: res.total,
              loading: false
            });
          }
        }
      )
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).onNotify(
          "Error",
          "Error while fetching register in out",
          "error"
        );
      });
  };

  exportRegisterInOutList = () => this.getRegisterInOutList(true);

  onSearchClick = () => this.setState({ showSearch: !this.state.showSearch });

  handleSearchCancel = () => {
    this.setState(
      { showSearch: false, searchTemp: searchTemplate },
      this.getRegisterInOutList
    );
  };

  clearSelection = (
    field: keyof RegisterInOutSearchTemplate,
    defaultValue = ""
  ) => {
    this.setState({
      searchTemp: { ...this.state.searchTemp, [field]: defaultValue }
    });
  };

  // onMount Load data
  componentDidMount() {
    document.title = "Register In Out - TaxPido PMS";
    if ((this.props as any).rights?.registerInAndOut) {
      this.getRegisterInOutList();
    } else if ((this.props as any).rights?.registerInAndOut === false) {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;
    if (prevFirmId !== currFirmId) {
      this.getRegisterInOutList();
      this.getDocumentsList();
      this.getKeptAtList();
      this.getClientsList();
    }

    if (
      prevState.showSearch !== this.state.showSearch &&
      this.state.showSearch
    ) {
      this.getDocumentsList();
      this.getKeptAtList();
      this.getClientsList();
    }

    if (
      (prevProps as any).rights?.registerInAndOut === undefined &&
      (this.props as any).rights?.registerInAndOut
    ) {
      this.getRegisterInOutList();
    }
    const prevModal = prevProps.currentModal;
    const currentModal = (this.props as any)?.currentModal;
    if (
      prevModal?.modalName === "ADD_EDIT_REGISTER_IN_OUT_MODAL" &&
      prevModal?.modalName !== currentModal?.modalName &&
      currentModal?.modalName === "" &&
      currentModal?.fetchAgain
    ) {
      this.getRegisterInOutList();
    }
    if (
      prevState.currPage !== this.state.currPage ||
      prevState.chunkSize !== this.state.chunkSize
    ) {
      this.getRegisterInOutList();
    }
  }

  onLoad = () => {
    this.setState({ searchTemp: searchTemplate }, this.getRegisterInOutList);
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {this.state.showEditModal && (
          <AddEditRegisterInOutModal
            onLoad={this.getRegisterInOutList}
            closeModal={this.closeModal}
          />
        )}
        {this.state.showDeleteModal && (
          <DeleteModal
            type="register in out"
            state={this.state}
            onLoad={this.onLoad}
            deleteModalSetOpen={this.deleteModalSetOpen}
          />
        )}
        <div className="max-w-full mx-auto lg:mx-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Register In Out
          </h1>
        </div>
        <div className="flex gap-4 justify-between mt-6 items-center lg:mx-8">
          <div className="hidden md:block">
            <Button
              name="Add Register In Out"
              icon={
                (this.props as any).rights?.registerInAndOut
                  ? PlusIcon
                  : "outline/lock-closed"
              }
              onClick={this.openAddRegisterInOutModal}
            />
          </div>
          {!this.state.showSearch && (
            <button
              className="w-64 flex gap-y-1 md:gap-3 items-center flex-col md:flex-row hover:border-indigo-700 focus:border-indigo-700 px-6 py-2 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none text-gray-500 text-sm whitespace-nowrap cursor-pointer"
              onClick={this.onSearchClick}
            >
              Search
            </button>
          )}
          <div className="md:hidden ml-auto">
            <Menu as="div" className="inline-block relative">
              <Menu.Button
                onClick={() => this.onDropdownClick(true, true)}
                className="relative inline-flex gap-x-2 items-center justify-between px-2 py-2 sm:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:bg-indigo-700 disabled:bg-indigo-500 w-fit sm:pl-4 sm:pr-2"
                disabled={!(this.props as any).rights?.registerInAndOut}
              >
                {(this.props as any).rights?.registerInAndOut ? (
                  <Icon
                    name="outline/settings"
                    className={`h-5 w-5`}
                    aria-hidden="true"
                  />
                ) : (
                  <Icon
                    name="outline/lock-closed"
                    className={`h-5 w-5`}
                    aria-hidden="true"
                  />
                )}

                <span>Options</span>
                <Icon
                  name="dropdown-arrow"
                  className={`h-5 w-5 ${this.state.rotate ? "rotate-90" : ""}`}
                  aria-hidden="true"
                />
              </Menu.Button>
              {this.state.showBackDrop && (
                <div
                  className="fixed top-0 left-0 z-10 w-full h-screen"
                  onClick={() => this.onDropdownClick(false, false)}
                ></div>
              )}

              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute z-[100] right-0">
                  <Menu.Items className="overscroll-none mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      <Menu.Item
                        as={"div"}
                        onClick={() => this.onDropdownClick(false, false)}
                      >
                        <Button
                          name="Add Register In Out"
                          icon={
                            (this.props as any).rights?.registerInAndOut
                              ? PlusIcon
                              : "outline/lock-closed"
                          }
                          onClick={this.openAddRegisterInOutModal}
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900 disabled:cursor-not-allowed"
                        />
                      </Menu.Item>
                      {this.reports.map(report => (
                        <Menu.Item
                          key={report.path}
                          as={"div"}
                          onClick={() => this.onDropdownClick(false, false)}
                        >
                          <Link
                            to={`/${
                              (this.props as any).params?.firmId
                            }/register-in-out/${report.path}`}
                            className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                          >
                            <Icon name={report.icon} className="h-5 w-5 mr-2" />
                            <span>{report.text}</span>
                          </Link>
                        </Menu.Item>
                      ))}
                      <Menu.Item
                        as={"div"}
                        onClick={() => this.onDropdownClick(false, false)}
                      >
                        <Button
                          name="Export Register In Out"
                          icon={ArrowDownTrayIcon}
                          onClick={this.exportRegisterInOutList}
                          className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                        />
                      </Menu.Item>
                    </div>
                  </Menu.Items>
                </div>
              </Transition>
            </Menu>
          </div>
          <div className="hidden md:block">
            {this.state.totalRecords > 0 ? (
              <div className="flex gap-2">
                <Menu as="div" className="inline-block relative">
                  {/* <Menu.Button
                    onClick={() => this.onDropdownClick(true, true)}
                    className="relative inline-flex gap-x-2 items-center justify-between px-2 py-2 sm:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-500 focus:bg-indigo-700"
                    disabled={!(this.props as any).rights?.registerInAndOut}
                  >
                    <Icon
                      name={
                        (this.props as any).rights?.registerInAndOut
                          ? "outline/document-text"
                          : "outline/lock-closed"
                      }
                      className="h-4 w-4"
                    />
                    <span>Reports</span>
                    <Icon
                      name="dropdown-arrow"
                      className={`h-4 w-4 ${
                        this.state.rotate ? "rotate-90" : ""
                      }`}
                      aria-hidden="true"
                    />
                  </Menu.Button> */}
                  {this.state.showBackDrop && (
                    <div
                      className="fixed top-0 left-0 z-10 w-full h-screen"
                      onClick={() => this.onDropdownClick(false, false)}
                    ></div>
                  )}
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <div className="absolute z-[100]">
                      <Menu.Items className="overscroll-none mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                          {this.reports.map(report => (
                            <Menu.Item key={report.path}>
                              <Link
                                to={`/${
                                  (this.props as any).params?.firmId
                                }/register-in-out/${report.path}`}
                                className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                              >
                                <Icon
                                  name={report.icon}
                                  className="h-5 w-5 mr-2"
                                />
                                <span>{report.text}</span>
                              </Link>
                            </Menu.Item>
                          ))}
                        </div>
                      </Menu.Items>
                    </div>
                  </Transition>
                </Menu>
                {/* <Button
                  name="Export"
                  icon={this.state.downloading ? "loading" : ArrowDownTrayIcon}
                  onClick={this.exportRegisterInOutList}
                /> */}
              </div>
            ) : (
              <span aria-hidden="true" className="sm:w-[14.5rem]">
                &#8203;
              </span>
            )}
          </div>
        </div>
        <div
          className={`max-w-full mx-auto lg:mx-8 ${
            this.state.showSearch
              ? "visible relative z-auto h-auto"
              : "invisible absolute -z-50 h-0"
          } transition-[top] duration-500`}
        >
          <form>
            <div className="grid grid-cols-2 md:grid-cols-4 lg:grid-cols-5 gap-x-4 gap-y-4 mt-6">
              <div id="date-from">
                <label
                  htmlFor="dateFrom"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date From
                </label>
                <div className="mt-1">
                  <input
                    type="date"
                    name="dateFrom"
                    id="dateFrom"
                    max={this.state.searchTemp.dateTo}
                    value={formatDate(this.state.searchTemp.dateFrom, true)}
                    onChange={e => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          dateFrom: e.target.value,
                          dateTo: this.state.searchTemp.dateTo
                            ? this.state.searchTemp.dateTo
                            : e.target.value
                        }
                      });
                    }}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div id="date-to" className="md:row-start-2">
                <label
                  htmlFor="dateTo"
                  className="block text-sm font-medium text-gray-700"
                >
                  Date To
                </label>
                <div className="mt-1 relative">
                  <input
                    type="date"
                    name="dateTo"
                    id="dateTo"
                    min={this.state.searchTemp.dateFrom}
                    value={formatDate(
                      this.state.searchTemp.dateTo ||
                        this.state.searchTemp.dateFrom,
                      true
                    )}
                    onChange={e => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          dateTo: e.target.value
                        }
                      });
                    }}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
              </div>
              <div id="client">
                <label
                  htmlFor="client"
                  className="block text-sm font-medium text-gray-700"
                >
                  Client
                </label>
                <div className="my-1">
                  <MultiSelect
                    items={this.state.clientList.map(item => {
                      return {
                        ...item,
                        _id: item._id,
                        name: formatClientName(item)
                      };
                    })}
                    selected={{
                      name:
                        formatClientName(
                          this.state.clientList.find(
                            item => item._id === this.state.searchTemp?.client
                          )
                        ) || ""
                    }}
                    type="type"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          client:
                            selected._id === this.state.searchTemp.client
                              ? ""
                              : selected._id
                        }
                      });
                    }}
                    placeholder="Select Client"
                    clearAction={() => this.clearSelection("client")}
                  />
                </div>
              </div>
              <div id="document" className="">
                <label
                  htmlFor="document"
                  className="block text-sm font-medium text-gray-700"
                >
                  Document
                </label>
                <div className="mt-1">
                  <MultiSelect
                    items={this.state.documentList}
                    selected={{
                      name:
                        this.state.documentList.find(
                          item => item._id === this.state.searchTemp?.document
                        )?.name || ""
                    }}
                    type="type"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          document:
                            selected._id === this.state.searchTemp.document
                              ? ""
                              : selected._id
                        }
                      });
                    }}
                    placeholder="Select Document"
                    clearAction={() => this.clearSelection("document")}
                  />
                </div>
              </div>
              <div id="mode" className="">
                <label
                  htmlFor="mode"
                  className="block text-sm font-medium text-gray-700"
                >
                  Mode
                </label>
                <div className="mt-1">
                  <MultiSelect
                    items={registerModes.map((item: any) => ({
                      _id: item,
                      name: item
                    }))}
                    selected={{
                      name: this.state.searchTemp.mode
                    }}
                    type="mode"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          mode:
                            selected.name === this.state.searchTemp.mode
                              ? "N/A"
                              : selected.name
                        }
                      });
                    }}
                    placeholder="Select Mode"
                    clearAction={
                      this.state.searchTemp.mode === "N/A"
                        ? undefined
                        : () => this.clearSelection("mode", "N/A")
                    }
                  />
                </div>
              </div>
              <div id="manner" className="">
                <label
                  htmlFor="manner"
                  className="block text-sm font-medium text-gray-700"
                >
                  Manner
                </label>
                <div className="mt-1">
                  <MultiSelect
                    items={this.mannerList().map((item: any) => ({
                      _id: item,
                      name: item
                    }))}
                    selected={{
                      name: this.state.searchTemp.manner
                    }}
                    type="manner"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          manner:
                            selected.name === this.state.searchTemp.manner
                              ? ""
                              : selected.name
                        }
                      });
                    }}
                    placeholder="Select Manner"
                    clearAction={() => this.clearSelection("manner")}
                  />
                </div>
              </div>
              <div id="type" className="">
                <label
                  htmlFor="type"
                  className="block text-sm font-medium text-gray-700"
                >
                  Type
                </label>
                <div className="mt-1">
                  <MultiSelect
                    items={booleanOptions.map((item: any) => ({
                      _id: item,
                      name: item
                    }))}
                    selected={{
                      name: this.state.searchTemp.type
                    }}
                    type="type"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          type:
                            selected._id === this.state.searchTemp.type
                              ? ""
                              : selected._id
                        }
                      });
                    }}
                    placeholder="Select Is Returnable"
                    clearAction={() => this.clearSelection("type")}
                  />
                </div>
              </div>
              <div id="isReturnable" className="">
                <label
                  htmlFor="isReturnable"
                  className="block text-sm font-medium text-gray-700"
                >
                  Is Returnable
                </label>
                <div className="mt-1">
                  <MultiSelect
                    items={booleanOptions.map((item: any) => ({
                      _id: item,
                      name: item
                    }))}
                    selected={{
                      name: this.state.searchTemp.isReturnable
                    }}
                    type="isReturnable"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          isReturnable:
                            selected._id === this.state.searchTemp.isReturnable
                              ? ""
                              : selected._id
                        }
                      });
                    }}
                    placeholder="Select Is Returnable"
                    clearAction={() => this.clearSelection("isReturnable")}
                  />
                </div>
              </div>
              <div id="isOriginal" className="">
                <label
                  htmlFor="isOriginal"
                  className="block text-sm font-medium text-gray-700"
                >
                  Is Original
                </label>
                <div className="mt-1">
                  <MultiSelect
                    items={booleanOptions.map((item: any) => ({
                      _id: item,
                      name: item
                    }))}
                    selected={{
                      name: this.state.searchTemp.isOriginal
                    }}
                    type="isOriginal"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          isOriginal:
                            selected._id === this.state.searchTemp.isOriginal
                              ? ""
                              : selected._id
                        }
                      });
                    }}
                    placeholder="Select Is Original"
                    clearAction={() => this.clearSelection("isOriginal")}
                  />
                </div>
              </div>
              <div id="futureActionRequire" className="">
                <label
                  htmlFor="futureActionRequire"
                  className="block text-sm font-medium text-gray-700 whitespace-nowrap truncate"
                >
                  Further Action Required
                </label>
                <div className="mt-1">
                  <MultiSelect
                    items={booleanOptions.map((item: any) => ({
                      _id: item,
                      name: item
                    }))}
                    selected={{
                      name: this.state.searchTemp.futureActionRequire
                    }}
                    type="futureActionRequire"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          futureActionRequire:
                            selected._id ===
                            this.state.searchTemp.futureActionRequire
                              ? ""
                              : selected._id
                        }
                      });
                    }}
                    placeholder="Select Further Action Required"
                    clearAction={() =>
                      this.clearSelection("futureActionRequire")
                    }
                  />
                </div>
              </div>
              <div id="keptAt" className="">
                <label
                  htmlFor="keptAt"
                  className="block text-sm font-medium text-gray-700"
                >
                  Kept At
                </label>
                <div className="mt-1">
                  <MultiSelect
                    items={this.state.keptAtList}
                    selected={{
                      name:
                        this.state.keptAtList.find(
                          item => item._id === this.state.searchTemp?.keptAt
                        )?.name || ""
                    }}
                    type="type"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          keptAt:
                            selected._id === this.state.searchTemp.keptAt
                              ? ""
                              : selected._id
                        }
                      });
                    }}
                    placeholder="Select Kept At"
                    clearAction={() => this.clearSelection("keptAt")}
                  />
                </div>
              </div>
              <div id="receivedBy" className="">
                <label
                  htmlFor="receivedBy"
                  className="block text-sm font-medium text-gray-700 whitespace-nowrap truncate"
                >
                  ReceivedBy/Given By
                </label>
                <div className="mt-1">
                  <MultiSelect
                    items={(this.props as any)?.users?.map((item: any) => ({
                      _id: item._id,
                      name: item.name
                    }))}
                    selected={{
                      name: (this.props as any)?.users?.find(
                        (item: any) =>
                          item._id === this.state.searchTemp?.receivedBy
                      )?.name
                    }}
                    type="manner"
                    onChange={(selected: any) => {
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          receivedBy:
                            selected._id === this.state.searchTemp.receivedBy
                              ? ""
                              : selected._id
                        }
                      });
                    }}
                    placeholder="Select Received By/Given By"
                    clearAction={() => this.clearSelection("receivedBy")}
                  />
                </div>
              </div>
              <div id="description" className="col-span-2 lg:col-span-3">
                <label
                  htmlFor="description"
                  className="block text-sm font-medium text-gray-700"
                >
                  Description
                </label>
                <div className="mt-1">
                  <textarea
                    id="description"
                    name="description"
                    rows={1}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Description"
                    value={this.state.searchTemp?.description}
                    onChange={e =>
                      this.setState({
                        searchTemp: {
                          ...this.state.searchTemp,
                          description: e.target.value
                        }
                      })
                    }
                  />
                </div>
              </div>
              <div
                id="cancel-button"
                className="md:row-start-4 md:col-start-3 lg:col-start-4 w-full place-self-end"
              >
                <button
                  type="button"
                  className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none w-full sm:text-sm"
                  onClick={this.handleSearchCancel}
                >
                  Cancel
                </button>
              </div>
              <div
                id="search-button"
                className="md:row-start-4 md:col-start-4 lg:col-start-5 w-full place-self-end"
              >
                <button
                  type="button"
                  disabled={this.state.loading}
                  className={
                    "inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:bg-indigo-700 focus:outline-none sm:mt-0 w-full sm:text-sm"
                  }
                  onClick={() => this.getRegisterInOutList(false)}
                >
                  <span className="w-full flex justify-end">
                    {this.state.loading ? (
                      <Icon name="loading" className="mr-2" />
                    ) : null}
                  </span>
                  <span>Search</span>
                  <span className="w-full"></span>
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="max-w-full mx-auto lg:mx-8">
          {/* Register In Out Payment List Cards */}
          {/* <div className="sm:hidden mt-6">
            {!this.state.loading && this.state.registerInOutList ? (
              this.state.totalRecords > 0 ||
              this.state.searchTemp.client.length > 0 ||
              this.state.searchTemp.date.length > 0 ||
              this.state.searchTemp.description.length > 0 ||
              this.state.searchTemp.document.length > 0 ||
              this.state.searchTemp.keptAt.length > 0 ||
              this.state.searchTemp.mode.length > 0 ||
              this.state.searchTemp.receivedBy.length > 0 ||
              this.state.searchTemp.type.length > 0 ||
              this.state.searchTemp.futureActionRequire === true ||
              this.state.searchTemp.futureActionRequire === false ||
              this.state.searchTemp.isOriginal === true ||
              this.state.searchTemp.isOriginal === false ||
              this.state.searchTemp.isReturnable === true ||
              this.state.searchTemp.isReturnable === false ? (
                <ul className="divide-y divide-gray-200 space-y-4">
                  {this.state.totalRecords === 0 ? (
                    <div className="bg-white shadow-md rounded-lg">
                      <div className="px-4 py-4 sm:px-6">
                        <p className="text-sm text-gray-800 text-center font-semibold">
                          No record found matching your search criteria
                        </p>
                      </div>
                    </div>
                  ) : (
                    this.state.registerInOutList.map(
                      (
                        registerInOut: RegisterInOutDataListItem,
                        index: any
                      ) => (
                        <li
                          key={registerInOut._id}
                          className="bg-white shadow-md rounded-lg"
                        >
                          <div className="px-4 py-4 sm:px-6 grid gap-x-4 gap-y-2 grid-cols-2 relative">
                            <span className="rounded-full bg-green-100 px-2 text-xs font-semibold leading-5 text-green-800 absolute -translate-y-1/2 -right-4 shadow">
                              {registerInOut?.type ? registerInOut?.type : "-"}
                            </span>
                            <p className="text-sm text-gray-800 text-center font-semibold">
                              <time dateTime={registerInOut?.date}>
                                {registerInOut?.date
                                  ? formatDate(registerInOut?.date, false)
                                  : "-"}
                              </time>
                            </p>
                            <div className="place-self-end mt-1">
                              <ListOptions
                                {...this.props}
                                state={this.state}
                                registerInOut={registerInOut}
                                onActionClick={this.onActionClick}
                                onDropdownClick={this.onDropdownClick}
                                openEditModal={this.openEditModal}
                                openDeleteModal={this.openDeleteModal}
                                smallScreen={true}
                              />
                            </div>
                            <div>
                              <p className="text-sm text-gray-800">
                                <span>Client - </span>
                                <span
                                  className={`font-bold ${
                                    registerInOut?.client
                                      ? ""
                                      : "inline-block w-20 text-center"
                                  }`}
                                >
                                  {registerInOut?.client
                                    ? registerInOut?.client
                                    : "-"}
                                </span>
                              </p>
                              <p className="text-sm text-gray-800">
                                <span>Source - </span>
                                <span className="font-bold">
                                  {registerInOut?.document
                                    ? registerInOut?.document
                                    : "-"}
                                </span>
                              </p>
                            </div>
                            <p className="truncate text-lg font-bold text-indigo-600 m-auto">
                              {registerInOut?.amount
                                ? convertNumber(registerInOut?.amount)
                                : "-"}
                            </p>
                          </div>
                        </li>
                      )
                    )
                  )}
                </ul>
              ) : (
                <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg">
                  <Icon
                    name="outline/document-add"
                    className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                    strokeWidth="1"
                  />
                  <h3 className="text-sm font-medium text-gray-900">
                    No Register In Out Entry
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Get started by adding a new Register In Out.
                  </p>
                  <div className="mt-6">
                    <button
                      type="button"
                      className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-500 disabled:cursor-not-allowed"
                      onClick={this.openAddRegisterInOutModal}
                    >
                      <Icon
                        name={
                          (this.props as any).rights?.registerInAndOut
                            ? "add"
                            : "outline/lock-closed"
                        }
                        className="h-4 w-4 mr-2"
                      />
                      Add Register In Out
                    </button>
                  </div>
                </div>
              )
            ) : (
              <div className="space-y-4">
                {[...Array(5)].map((e, i) => (
                  <div key={i} className="bg-white shadow-md rounded-lg">
                    <div
                      key={i}
                      className={`whitespace-wrap text-sm font-medium text-gray-900 px-4 py-4 sm:px-6 grid gap-4 grid-cols-3 items-center relative ${
                        i === 0 ? "text-left" : "text-center"
                      }`}
                    >
                      <span className="px-2 absolute top-0 -translate-y-1/2 -right-4 w-24">
                        <Skeleton
                          height="1rem"
                          className="w-full bg-green-100"
                        />
                      </span>
                      <Skeleton height="1rem" />
                      <Skeleton height="1rem" />
                      <Skeleton height="1rem" />
                      <Skeleton height="1rem" />
                      <Skeleton height="1rem" />
                      <Skeleton height="1rem" />
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div> */}
          {/* Register In Out Payment List Table */}
          {/* <div className="mt-6 hidden sm:block"> */}
          <div className="mt-6">
            {!this.state.loading ? (
              this.state.totalRecords > 0 ? (
                this.state.searchTemp.client.length > 0 ||
                this.state.searchTemp.dateFrom.length > 0 ||
                this.state.searchTemp.dateTo.length > 0 ||
                this.state.searchTemp.description.length > 0 ||
                this.state.searchTemp.document.length > 0 ||
                this.state.searchTemp.keptAt.length > 0 ||
                this.state.searchTemp.mode.length > 0 ||
                this.state.searchTemp.receivedBy.length > 0 ||
                this.state.searchTemp.type.length > 0 ||
                this.state.searchTemp.futureActionRequire ||
                this.state.searchTemp.isOriginal ||
                this.state.searchTemp.isReturnable ? (
                  <div id="table-scroll" className="overflow-auto">
                    <div className="inline-block min-w-full py-2 px-1 align-middle">
                      <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                        <table className="min-w-full border-collapse border shadow-sm">
                          <thead className="bg-gray-50">
                            <tr>
                              {this.headers.map((header, index) => (
                                <th
                                  key={header}
                                  style={{ zIndex: 8 }}
                                  scope="col"
                                  className={`sticky top-0 border-b border-gray-300 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider py-3 ${
                                    index === 0 ? "px-6" : "px-4"
                                  } ${
                                    index === this.headers.length - 1
                                      ? "text-center"
                                      : "text-left"
                                  }`}
                                >
                                  {header}
                                </th>
                              ))}
                            </tr>
                          </thead>
                          <tbody className="bg-white">
                            {this.state.registerInOutList.map(
                              (
                                registerInOut: RegisterInOutDataListItem,
                                index: any
                              ) => (
                                <tr
                                  key={registerInOut?._id}
                                  className={`${
                                    index % 2 === 0 ? undefined : "bg-gray-100"
                                  }`}
                                >
                                  <td className="whitespace-nowrap py-4 pl-6 pr-3 text-sm text-gray-900 font-bold">
                                    {registerInOut?.date
                                      ? formatDate(registerInOut?.date, false)
                                      : "-"}
                                  </td>
                                  <td className="max-w-[20ch] whitespace-nowrap py-4 px-3 text-sm text-gray-900">
                                    <Popup
                                      content={registerInOut?.clientName ?? "-"}
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {registerInOut?.clientName ?? "-"}
                                      </p>
                                    </Popup>
                                  </td>
                                  <td className="max-w-[20ch] whitespace-nowrap py-4 px-3 text-sm text-gray-900 font-bold capitalize">
                                    <Popup
                                      content={
                                        registerInOut?.type
                                          ? registerInOut?.type
                                          : "-"
                                      }
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {registerInOut?.type
                                          ? registerInOut?.type
                                          : "-"}
                                      </p>
                                    </Popup>
                                  </td>
                                  <td className="max-w-[12ch] whitespace-nowrap py-4 px-3 text-sm text-gray-900">
                                    <Popup
                                      content={
                                        registerInOut?.documentName ?? "-"
                                      }
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {registerInOut?.documentName ?? "-"}
                                      </p>
                                    </Popup>
                                  </td>
                                  <td className="max-w-[15ch] whitespace-nowrap py-4 px-3 text-sm text-gray-900 font-bold">
                                    <Popup
                                      content={
                                        registerInOut?.mode
                                          ? registerInOut?.mode
                                          : "-"
                                      }
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {registerInOut?.mode
                                          ? registerInOut?.mode
                                          : "-"}
                                      </p>
                                    </Popup>
                                  </td>
                                  <td className="whitespace-nowrap py-4 px-3 text-sm text-gray-900">
                                    {registerInOut?.futureActionRequire
                                      ? "Yes"
                                      : "No"}
                                  </td>
                                  <td className="max-w-[15ch] whitespace-nowrap py-4 px-3 text-sm text-gray-900">
                                    <Popup
                                      content={
                                        registerInOut?.description
                                          ? registerInOut?.description
                                          : "-"
                                      }
                                      className="w-full max-w-fit"
                                    >
                                      <p className="truncate">
                                        {registerInOut?.description
                                          ? registerInOut?.description
                                          : "-"}
                                      </p>
                                    </Popup>
                                  </td>
                                  <td className="px-6 py-3 mx-4 text-center whitespace-nowrap text-sm text-gray-500">
                                    <ListOptions
                                      {...this.props}
                                      state={this.state}
                                      registerInOut={registerInOut}
                                      onActionClick={this.onActionClick}
                                      onDropdownClick={this.onDropdownClick}
                                      openEditModal={this.openEditModal}
                                      openDeleteModal={this.openDeleteModal}
                                      smallScreen={false}
                                    />
                                  </td>
                                </tr>
                              )
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900">
                    No record found matching your search criteria
                  </div>
                )
              ) : (
                <div className="text-center my-10 border-2 border-gray-300 border-dashed p-16 md:mx-40 sm:mx-0 rounded-lg bg-gray-100">
                  <div>
                    <Icon
                      name="outline/document-add"
                      className="mx-auto mb-2 text-gray-300 flex-shrink-0 h-10 w-10"
                      strokeWidth="1"
                    />
                    <h3 className="text-sm font-medium text-gray-900">
                      No Register In Out Entry
                    </h3>
                    <p className="mt-1 text-sm text-gray-500">
                      Get started by adding a new Register In Out.
                    </p>
                    <div className="mt-6">
                      <button
                        type="button"
                        className="relative inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-500 disabled:cursor-not-allowed"
                        onClick={this.openAddRegisterInOutModal}
                      >
                        <Icon
                          name={
                            (this.props as any).rights?.registerInAndOut
                              ? "add"
                              : "outline/lock-closed"
                          }
                          className="h-4 w-4 mr-2"
                        />
                        Add Register In Out
                      </button>
                    </div>
                  </div>
                </div>
              )
            ) : (
              <div id="table-scroll" className="overflow-auto">
                <div className="inline-block min-w-full py-2 px-1 align-middle">
                  <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                    <table className="min-w-full border-collapse border shadow-sm">
                      <thead className="bg-gray-50">
                        <tr>
                          {this.headers.map((header, index) => (
                            <th
                              key={header}
                              style={{ zIndex: 8 }}
                              scope="col"
                              className={`sticky top-0 border-b border-gray-300 bg-gray-50 text-xs font-bold text-gray-500 uppercase tracking-wider py-3 ${
                                index === 0 ? "px-6" : "px-4"
                              } ${
                                index === this.headers.length - 1
                                  ? "text-center"
                                  : "text-left"
                              }`}
                            >
                              {header}
                            </th>
                          ))}
                        </tr>
                      </thead>
                      <tbody className="bg-white">
                        {[...Array(5)].map((e, i) => (
                          <tr key={i} className="bg-white">
                            {[...Array(this.headers.length)].map((e, i) => (
                              <td
                                key={i}
                                className={`px-3 py-3 whitespace-wrap text-sm font-medium text-gray-900 ${
                                  i === 0 ? "text-left" : "text-center"
                                }`}
                              >
                                <Skeleton />
                              </td>
                            ))}
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <Pagination
          displayRecords={this.state.registerInOutList}
          totalRecords={this.state.totalRecords}
          currPage={this.state.currPage}
          chunkSize={this.state.chunkSize}
          handlePageClick={this.handlePageClick}
          handleItemPerPage={this.handleItemPerPage}
          className="my-4"
        />
      </Dashboard>
    );
  }
}

class ListOptions extends React.Component<any> {
  render() {
    return (
      <Menu as="div" className="inline-block">
        <Menu.Button onClick={this.props.onActionClick}>
          <span className="sr-only">Open options</span>
          {this.props.smallScreen ? (
            <Icon name="vertical-dots" className="h-4 w-4" aria-hidden="true" />
          ) : (
            <Icon
              name="horizontal-dots"
              className="h-5 w-5"
              aria-hidden="true"
            />
          )}
        </Menu.Button>
        {this.props.state.showBackDrop && (
          <div
            className="fixed top-0 left-0 z-10 w-full h-screen"
            onClick={() => this.props.onDropdownClick(false, false)}
          ></div>
        )}

        <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <div
            style={{
              position: "fixed",
              zIndex: 100,
              top: `${this.props.state.posY}px`,
              left: `${this.props.state.posX - 230}px`,
              margin: "0.5rem"
            }}
          >
            <Menu.Items className="overscroll-none mt-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
              <div className="py-1">
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() =>
                      this.props.openEditModal(this.props.registerInOut)
                    }
                  >
                    <Icon name="edit" className="h-5 w-5 mr-2" />
                    <span>Edit</span>
                  </button>
                </Menu.Item>
                <Menu.Item>
                  <button
                    className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900"
                    onClick={() =>
                      this.props.openDeleteModal(this.props.registerInOut)
                    }
                  >
                    <Icon name="delete" className="h-5 w-5 mr-2" />
                    <span>Delete</span>
                  </button>
                </Menu.Item>
              </div>
            </Menu.Items>
          </div>
        </Transition>
      </Menu>
    );
  }
}

export default compose(
  connector,
  withRouter
)(RegisterInOut) as React.ComponentType;
