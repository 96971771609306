import React from "react";
import {
  Navigate,
  BrowserRouter as Router,
  Route,
  Routes
} from "react-router-dom";
import agent from "./agent";
import Notification from "./components/Notification";

// Page Imports
import GSTs from "./pages/Firms/Index";
import AddFirm from "./pages/Firms/Add";

// Tag Import
import Tags from "./pages/Tags/Index";
import AddTag from "./pages/Tags/Add";

//  Status Import
import Status from "./pages/Status/Index";
import AddStatus from "./pages/Status/Add";

//  Person Import
import ContactPerson from "./pages/ContactPerson/Index";
import AddPerson from "./pages/ContactPerson/Add";
import ContactPersonImport from "./pages/ContactPerson/Import/Index";

// User Import
import Users from "./pages/Users/Index";
import AddUser from "./pages/Users/Add";
import EditUser from "./pages/Users/Edit";
import SelfRIghts from "./pages/Users/SelfRIghts";

// Custom Field Import
import CustomField from "./pages/CustomField/Index";
import AddCustomField from "./pages/CustomField/Add";

// Client Group Import
import ClientGroups from "./pages/Groups/Index";
import AddClientGroups from "./pages/Groups/Add";
import GroupDetails from "./pages/Groups/GroupDetails";

// Clients Import
import Clients from "./pages/Clients/Index";
import ClientsImport from "./pages/Clients/Import/Index";
import AddClient from "./pages/Clients/Add";
import EditClient from "./pages/Clients/Edit";
import ClientDetails from "./pages/Clients/ClientDetails";

// Tasks Import
import Tasks from "./pages/Tasks/Index";
import AddTask from "./pages/Tasks/Add";
import ReturnTaskOverview from "./pages/Tasks/ReturnTaskOverview/Index";

// Login Import
import Login from "./pages/Login";
// import Settings from "./pages/Settings/Index";
// Sign Up Imports
import Signup from "./pages/Signup";
import SignupOTP from "./pages/Verify/SignupOTP";
import SignupToken from "./pages/Verify/SignupToken";
// Forgot Password Imports
import ForgetPassword from "./pages/ForgetPassword";
import ResetOTP from "./pages/Verify/ResetOTP";
import ResetToken from "./pages/Verify/ResetToken";

// Profile Import
import Profile from "./pages/Profile/Index";

// Connect to redux
import { LOGIN, UPDATE_COMMON } from "./store/types";
import { connect, ConnectedProps } from "react-redux";

// Todo Imports
import Todos, { getTodoList, todoList } from "./pages/Todo/Index";
import AddEditList from "./pages/Todo/AddEditList";

// Receipts & Payments Imports
import ReceiptsPayments from "./pages/ReceiptsPayments/Index";
import AddReceipt from "./pages/ReceiptsPayments/AddReceipt";
import Source from "./pages/ReceiptsPayments/Source";
import Category from "./pages/ReceiptsPayments/Category";
import ClientWiseReport from "./pages/ReceiptsPayments/ClientWiseReport";
import PendingBalanceReport from "./pages/ReceiptsPayments/PendingBalanceReport";

// Recurring Task Imports
import RecurringTask from "./pages/RecurringTask/Index";

// Dashboard Page Imports
import DashboardPage from "./pages/DashboardPage/Index";

// QRMP Imports
import QRMP from "./pages/Clients/QRMP/Index";

// Notifications List Imports
import NotificationList from "./pages/NotificationList/Index";

// Update Logs Imports
import UpdateLogs from "./pages/UpdateLogs/Index";
import NotFound from "./pages/NotFound";

// Messages Imports
import BulkMessages from "./pages/BulkMessages/Index";
import TemplateList from "./pages/BulkMessages/TemplateList";

// Importing Firebase
import { initNotification } from "./firebase";
import ConfirmationPopup from "./components/ConfirmationPopup";
import Maintenance from "./pages/Maintenance";

// Time Tracking Imports
import TimeTrackingModal from "./pages/TimeTracking";

// Reports Imports
import Reports from "./pages/Reports/Index";
import ReportListing from "./pages/Reports/ReportListing";

// Register In Out Imports
import RegisterInOut from "./pages/RegisterInOut";
import AddEditRegisterInOut from "./pages/RegisterInOut/AddEditRegisterInOut";
import BulkEdit from "./pages/BulkEdit";
import Document from "./pages/RegisterInOut/Document";
import KeptAt from "./pages/RegisterInOut/KeptAt";
import DSCStatus from "./pages/ContactPerson/DSC";

// Subscription Imports
// import Subscription from "./pages/Subscription"; /* For paytm payment gateway */
import Subscription from "./pages/Subscription/index-razorpay"; /* For razorpay payment gateway */
import PaymentResponse from "./pages/Subscription/PaymentReponse";
import { WithRouterProps } from "./helpers/withRouter";
import Invoices from "./pages/Subscription/Invoices";
import { AppDispatch, RootState } from "./store";
import { AuthAction } from "./store/reducers/userAuth";
import { CommonAction } from "./store/reducers/common";
import GstReturnAndQrmpDetails from "./pages/Clients/GstReturnAndQrmpDetails";

const mapStateToProps = (state: RootState) => ({
  ...state.authReducer,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  updateLoginState: (payload: AuthAction["payload"]) =>
    dispatch({ type: LOGIN, payload })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {
  showMaintenance?: boolean;
}

interface State {
  loading: boolean;
  addingEditingTodo: boolean;
  showConfirmationPopup: boolean;
}

class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
      addingEditingTodo: false,
      showConfirmationPopup: false
    };
  }

  PrivateRoute = ({ children }: { children: JSX.Element }) => {
    // Added logic of localstorage as well because at the time of first page load the props.isAuthenticated is not available because it is updated after the first page load from componentDidMount

    return this.props.isAuthenticated ||
      localStorage.getItem("loggedIn") === "true" ? (
      children
    ) : (
      <Navigate to="/" />
    );
  };

  PublicRoute = ({ children }: { children: JSX.Element }) => {
    // Added logic of localstorage as well because at the time of first page load the props.isAuthenticated is not available because it is updated after the first page load from componentDidMount

    return this.props.isAuthenticated ||
      localStorage.getItem("loggedIn") === "true" ? (
      <Navigate to="/dashboard" />
    ) : (
      children
    );
  };

  closeModal = (fetchAgain?: boolean) => {
    (this.props as any).updateCommon({
      currentModal: { modalName: "", fetchAgain }
    });
  };

  showNotificationConfirmationPopup = () => {
    if (localStorage.getItem("notification")) return;
    setTimeout(() => {
      this.setState({ showConfirmationPopup: true });
    }, 2000);
  };

  onConfirm = () => {
    this.setState({ showConfirmationPopup: false });
    initNotification();
  };

  onCancel = () => {
    this.setState({ showConfirmationPopup: false });
    localStorage.setItem("notification", "default");
  };

  componentDidMount() {
    // this.showNotificationConfirmationPopup();

    const loggedIn = localStorage.getItem("loggedIn");
    this.props.updateLoginState?.({ isAuthenticated: loggedIn === "true" });
  }

  render() {
    return this.props.showMaintenance ? (
      <Router>
        <Maintenance />
      </Router>
    ) : (
      <div>
        <Notification />
        {(this.props as any)?.currentModal?.modalName === "ADD_FIRM_MODAL" && (
          <AddFirm closeModal={this.closeModal} />
        )}
        {(this.props as any)?.currentModal?.modalName === "ADD_TAG_MODAL" && (
          <AddTag closeModal={this.closeModal} />
        )}
        {(this.props as any)?.currentModal?.modalName ===
          "ADD_STATUS_MODAL" && <AddStatus closeModal={this.closeModal} />}

        {(this.props as any)?.currentModal?.modalName ===
          "ADD_PERSON_MODAL" && <AddPerson closeModal={this.closeModal} />}

        {(this.props as any)?.currentModal?.modalName ===
          "ADD_CUSTOM_FIELD_MODAL" && (
          <AddCustomField closeModal={this.closeModal} />
        )}

        {(this.props as any)?.currentModal?.modalName === "ADD_GROUP_MODAL" && (
          <AddClientGroups closeModal={this.closeModal} />
        )}
        {!this.state.loading ? (
          <Router>
            {this.state.showConfirmationPopup && (
              <ConfirmationPopup
                title="Enable Notifications"
                message="Please enable push notifications to get notified about your tasks."
                confirmText="Enable"
                onConfirm={this.onConfirm}
                cancelText="Cancel"
                onCancel={this.onCancel}
              />
            )}
            {(this.props as any)?.currentModal?.modalName ===
              "TIME_TRACKING_MODAL" && (
              <TimeTrackingModal closeModal={this.closeModal} />
            )}
            {(this.props as any)?.currentModal?.modalName ===
              "ADD_TODO_MODAL" && (
              <AddEditList
                closeModal={this.closeModal}
                props={this.props}
                loading={this.state.addingEditingTodo}
                setLoading={(addingEditingTodo: boolean) =>
                  this.setState({ addingEditingTodo })
                }
                getTodoList={getTodoList}
                todoList={todoList}
              />
            )}

            {(this.props as any)?.currentModal?.modalName ===
              "ADD_RECEIPT_MODAL" && (
              <AddReceipt closeModal={this.closeModal} props={this.props} />
            )}
            {(this.props as any)?.currentModal?.modalName ===
              "ADD_EDIT_REGISTER_IN_OUT_MODAL" && (
              <AddEditRegisterInOut closeModal={this.closeModal} />
            )}
            <Routes>
              {/* <Route
                path="/login"
                element={
                  <this.PublicRoute>
                    <Login />
                  </this.PublicRoute>
                }
              /> */}
              {/* SignUp */}
              <Route
                path="/signup"
                element={
                  <this.PublicRoute>
                    <Signup />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/signup/otp/:id"
                element={
                  <this.PublicRoute>
                    <SignupOTP />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/signup/token/:token"
                element={
                  <this.PublicRoute>
                    <SignupToken />
                  </this.PublicRoute>
                }
              />
              {/* Forgot Password */}
              <Route
                path="/forgetPassword"
                element={
                  <this.PublicRoute>
                    <ForgetPassword />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/reset/otp/:id"
                element={
                  <this.PublicRoute>
                    <ResetOTP />
                  </this.PublicRoute>
                }
              />
              <Route
                path="/verify/reset/token/:token"
                element={
                  <this.PublicRoute>
                    <ResetToken />
                  </this.PublicRoute>
                }
              />
              {/* Profile Page */}
              <Route
                path="/:firmId/profile/:tab"
                element={
                  <this.PrivateRoute>
                    <Profile />
                  </this.PrivateRoute>
                }
              />
              {/* Firm Page */}
              <Route
                path="/firms"
                element={
                  <this.PrivateRoute>
                    <GSTs />
                  </this.PrivateRoute>
                }
              />
              {/* Subscription Page */}
              <Route
                path="/:firmId/subscriptions"
                element={
                  <this.PrivateRoute>
                    <Subscription />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/paymentresponse"
                element={
                  <this.PrivateRoute>
                    <PaymentResponse />
                  </this.PrivateRoute>
                }
              />
              {/* Invoices Page */}
              <Route
                path="/:firmId/invoices"
                element={
                  <this.PrivateRoute>
                    <Invoices />
                  </this.PrivateRoute>
                }
              />
              {/* Dashboard */}
              <Route
                path="/dashboard"
                element={
                  <this.PrivateRoute>
                    <DashboardPage />
                  </this.PrivateRoute>
                }
              />
              {/* Dashboard */}
              <Route
                path="/admin"
                element={
                  <this.PrivateRoute>
                    <DashboardPage />
                  </this.PrivateRoute>
                }
              />
              {/* Dashboard */}
              <Route
                path="/admin/dashboard"
                element={
                  <this.PrivateRoute>
                    <DashboardPage />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/dashboard"
                element={
                  <this.PrivateRoute>
                    <DashboardPage />
                  </this.PrivateRoute>
                }
              />
              {/* Tag Page */}
              <Route
                path="/:firmId/tags/list"
                element={
                  <this.PrivateRoute>
                    <Tags />
                  </this.PrivateRoute>
                }
              />
              {/* Status Page */}
              <Route
                path="/:firmId/status/list"
                element={
                  <this.PrivateRoute>
                    <Status />
                  </this.PrivateRoute>
                }
              />
              {/* Custom Field Page */}
              <Route
                path="/:firmId/custom-field/list"
                element={
                  <this.PrivateRoute>
                    <CustomField />
                  </this.PrivateRoute>
                }
              />
              {/* Contact Perosn Page */}
              <Route
                path="/:firmId/contact-person/list"
                element={
                  <this.PrivateRoute>
                    <ContactPerson />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/contact-person/dsc-status"
                element={
                  <this.PrivateRoute>
                    <DSCStatus />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/contact-person/import"
                element={
                  <this.PrivateRoute>
                    <ContactPersonImport />
                  </this.PrivateRoute>
                }
              />
              {/* User Page */}
              <Route
                path="/:firmId/user/list"
                element={
                  <this.PrivateRoute>
                    <Users />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/user/add"
                element={
                  <this.PrivateRoute>
                    <AddUser />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/user/edit"
                element={
                  <this.PrivateRoute>
                    <EditUser />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/user/self-rights"
                element={
                  <this.PrivateRoute>
                    <SelfRIghts />
                  </this.PrivateRoute>
                }
              />
              {/* Recuring Task */}
              <Route
                path="/:firmId/recurring-task/list/:taskType"
                element={
                  <this.PrivateRoute>
                    <RecurringTask />
                  </this.PrivateRoute>
                }
              />
              {/* Group */}
              <Route
                path="/:firmId/groups/list"
                element={
                  <this.PrivateRoute>
                    <ClientGroups />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/group-profile/:groupId"
                element={
                  <this.PrivateRoute>
                    <GroupDetails />
                  </this.PrivateRoute>
                }
              />
              {/* Clients */}
              <Route
                path="/:firmId/clients/list"
                element={
                  <this.PrivateRoute>
                    <Clients />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/clients/import"
                element={
                  <this.PrivateRoute>
                    <ClientsImport />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/clients/qrmp"
                element={
                  <this.PrivateRoute>
                    <QRMP />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/clients/add"
                element={
                  <this.PrivateRoute>
                    <AddClient />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/clients/edit/:clientId"
                element={
                  <this.PrivateRoute>
                    <EditClient />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/client-profile/:clientId"
                element={
                  <this.PrivateRoute>
                    <ClientDetails />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/gst-details/:gstin"
                element={
                  <this.PrivateRoute>
                    <GstReturnAndQrmpDetails />
                  </this.PrivateRoute>
                }
              />
              {/* Tasks  */}
              <Route
                path="/:firmId/tasks/list"
                element={
                  <this.PrivateRoute>
                    <Tasks />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/tasks/add"
                element={
                  <this.PrivateRoute>
                    <AddTask />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/tasks/return-task-overview"
                element={
                  <this.PrivateRoute>
                    <ReturnTaskOverview />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/notification/list"
                element={
                  <this.PrivateRoute>
                    <NotificationList />
                  </this.PrivateRoute>
                }
              />
              {/* Insights and settings */}
              {/* <this.PrivateRoute exact path="/insights" component={Settings} /> */}
              {/* <this.PrivateRoute
                exact
                path="/:userInfo/settings"
                component={Settings}
              /> */}
              {/* Todo Page */}
              <Route
                path="/:firmId/todo/list/:list"
                element={
                  <this.PrivateRoute>
                    <Todos />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/todo/:toDoListId"
                element={
                  <this.PrivateRoute>
                    <Todos />
                  </this.PrivateRoute>
                }
              />
              {/* Receipts and payments */}
              <Route
                path="/:firmId/receipts-payments/list"
                element={
                  <this.PrivateRoute>
                    <ReceiptsPayments />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/receipts-payments/client-wise-reports"
                element={
                  <this.PrivateRoute>
                    <ClientWiseReport />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/receipts-payments/pending-balance-reports"
                element={
                  <this.PrivateRoute>
                    <PendingBalanceReport />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/receipts-payments/source"
                element={
                  <this.PrivateRoute>
                    <Source />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/receipts-payments/category"
                element={
                  <this.PrivateRoute>
                    <Category />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/register-in-out/list"
                element={
                  <this.PrivateRoute>
                    <RegisterInOut />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/register-in-out/document"
                element={
                  <this.PrivateRoute>
                    <Document />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/register-in-out/kept-at"
                element={
                  <this.PrivateRoute>
                    <KeptAt />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/bulk-messages"
                element={
                  <this.PrivateRoute>
                    <BulkMessages />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/bulk-message-template/list"
                element={
                  <this.PrivateRoute>
                    <TemplateList />
                  </this.PrivateRoute>
                }
              />
              {/* Bulk Edit */}
              <Route
                path="/:firmId/:update/bulk-edit"
                element={
                  <this.PrivateRoute>
                    <BulkEdit />
                  </this.PrivateRoute>
                }
              />

              {/* Reports */}
              <Route
                path="/:firmId/reports/list"
                element={
                  <this.PrivateRoute>
                    <Reports />
                  </this.PrivateRoute>
                }
              />
              <Route
                path="/:firmId/reports/:reportType/:report"
                element={
                  <this.PrivateRoute>
                    <ReportListing />
                  </this.PrivateRoute>
                }
              />
              {/* Update Logs */}
              <Route
                path="/update-logs"
                element={
                  <this.PrivateRoute>
                    <UpdateLogs />
                  </this.PrivateRoute>
                }
              />
              {/* Login */}
              <Route
                path="/"
                element={
                  <this.PublicRoute>
                    <Login />
                  </this.PublicRoute>
                }
              />

              {/* No Match */}
              <Route
                path="*"
                element={
                  <NotFound loggedIn={this.props.isAuthenticated ?? false} />
                }
              />
            </Routes>
          </Router>
        ) : null}
      </div>
    );
  }
}

export default connector(App);
