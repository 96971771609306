import React, { Component, ComponentType } from "react";
// Import to Display skeleton while loading data
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../agent";
// Icons and styling
import Icon from "./Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../store/types";
import "../pages/style.css";
import TagManager from "react-gtm-module";
import { getTaskPeriod } from "../helpers/taskPeriod";
import capitalize from "../helpers/capitalize";
import MultiSelect from "./MultiSelect";
import Calender from "./Calender";
import { clientTypesList } from "../constants/clients";
import {
  dueDateList,
  DueDateListOptionType,
  getDueDateFromDays
} from "../helpers/calendar";
import MultiSelectCheckbox from "./MultiSelectCheckbox";
import { formatDateAsLocalString } from "../helpers/formatDate";
import { AppDispatch, RootState } from "../store";
import { NotifyType } from "../store/reducers/notification";
import { CommonAction } from "../store/reducers/common";
import { withRouter, WithRouterProps } from "../helpers/withRouter";
import { compose } from "redux";
import {
  formatClientName,
  getClientNameFromFormatedName
} from "../helpers/formatClientName";
import { Client } from "../helpers/types";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "gsts"
  },
  dataLayerName: "PageDataLayer"
};

const searchYesAndNoList = ["Yes", "No"];

const periodList = [
  { name: "Monthly", value: "monthly" },
  { name: "Quarterly", value: "quarterly" },
  { name: "Half-Yearly", value: "half-yearly" },
  { name: "Yearly", value: "yearly" }
];

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type TaskType = {
  _id: string;
  name: string;
  task: string;
  type: "IT" | "GST" | "OTHER";
  period: string;
};

type Props = Partial<PropsFromRedux & WithRouterProps> & {
  type: string;
  showAdvancedSearch: boolean;
  navigate: any;
  location: any;
  params: any;
  searchParams: any;
  setSearchParams: any;
  onTaskSearch: (filters: any) => void;
  onSearchCancelClick: (filters: any, isSearched: boolean) => void;
  onLoad: () => void;
  searchLoading: boolean;
  filters: any;
  selectedShowTaskType: any;
  clientName?: string;
  group?: any;
};

type State = {
  searchDueDate: any;
  searchCreatedOn: any;
  taskTypes: (TaskType | { name: string })[];
  itTask: any;
  gstTask: any;
  otherTask: any;
  selectedSearchTask: (TaskType | { name: string })[];
  selectedSearchStatus: any;
  period: any;
  selectedSearchPeriod: any;
  selectedSearchPeriodType: any;
  selectedSearchUser: any;
  selectedOtherUser: any;
  selectedSearchTag: any;
  selectedSearchStar: any;
  clients: Client[];
  selectedSearchClient: any;
  groups: any;
  fileNo: any;
  selectedSearchGroup: any;
  selectedSearchFileNo: any;
  customFields: any;
  selectedSearchCustomField: any;
  selectedSearchCustomValue: any;
  selectedSearchEntity: any;
  comment: string;
  filters: any;
  isSearched: boolean;
  searchQuery: any;
  selectedDueDate: DueDateListOptionType;
};

class TaskSearch extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      taskTypes: [],
      itTask: [],
      gstTask: [],
      otherTask: [],
      period: [],
      clients: [],
      groups: [],
      fileNo: [],
      searchDueDate: { showCalender: false, value: "", from: "", to: "" },
      searchCreatedOn: { showCalender: false, value: "" },
      customFields: [],
      selectedSearchTask: [],
      selectedSearchStatus: null,
      selectedSearchPeriod: null,
      selectedSearchPeriodType: null,
      selectedSearchUser: null,
      selectedOtherUser: [],
      selectedSearchTag: null,
      selectedSearchStar: null,
      selectedSearchClient: null,
      selectedSearchGroup: null,
      selectedSearchFileNo: null,
      selectedSearchCustomField: null,
      selectedSearchCustomValue: null,
      selectedSearchEntity: null,
      comment: "",
      filters: { ...this.props.filters },
      isSearched: false,
      searchQuery: {},
      selectedDueDate: dueDateList[0]
    };
  }

  setSelectedDueDate = (selectedDueDate: DueDateListOptionType) => {
    this.setState({ selectedDueDate });
  };

  // Chunk Size for number of table data displayed in each page during pagination
  //chunkSize = 12;
  // Selected pagination value
  //currPage = 0;

  //Get Task Data

  getTaskTypeList = () => {
    const workSpaceId = this.props.params?.firmId;
    agent.Tasks.getTaskNamesList(workSpaceId)
      .then((response: { data: TaskType[] }) => {
        const tasks = response.data.map(item => ({
          ...item,
          _id: item.task,
          name: item.task
        }));
        const itTask = [
          { _id: "All IT Tasks", name: "All IT Tasks", type: "IT" },
          ...tasks.filter(item => item.type === "IT")
        ];
        const gstTask = [
          { _id: "All GST Tasks", name: "All GST Tasks", type: "GST" },
          ...tasks.filter(item => item.type === "GST")
        ];
        const otherTask = [
          { _id: "All Other Tasks", name: "All Other Tasks", type: "OTHER" },
          ...tasks.filter(item => item.type === "OTHER")
        ];

        this.setState({
          itTask: itTask,
          gstTask: gstTask,
          otherTask: otherTask,
          taskTypes: [
            { _id: "All", name: "All" },
            ...itTask,
            ...gstTask,
            ...otherTask
          ]
        });
      })
      .catch((err: any) => {
        this.props.addNotification?.(
          "Could not load status task list",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  onSearchTaskChange = (task: any) => {
    const { selectedSearchTask, taskTypes, itTask, gstTask, otherTask } =
      this.state;
    const taskNotAlreadySelected =
      selectedSearchTask.findIndex((item: any) => item._id === task._id) === -1;

    const allSelectedTaskCount = selectedSearchTask.length;
    const allSelectedGstTaskCount = selectedSearchTask.filter(
      (item: any) => item.type === "GST"
    ).length;
    const allSelectedItTaskCount = selectedSearchTask.filter(
      (item: any) => item.type === "IT"
    ).length;
    const allSelectedOtherTaskCount = selectedSearchTask.filter(
      (item: any) => item.type === "OTHER"
    ).length;

    if (taskNotAlreadySelected) {
      if (
        task.name === "All" ||
        (allSelectedTaskCount === taskTypes.length - otherTask.length - 1 &&
          task.name === "All Other Tasks") ||
        (allSelectedTaskCount === taskTypes.length - itTask.length - 1 &&
          task.name === "All IT Tasks") ||
        (allSelectedTaskCount === taskTypes.length - gstTask.length - 1 &&
          task.name === "All GST Tasks")
      ) {
        this.setState({ selectedSearchTask: taskTypes }, this.selectedTasks);
      } else if (task.name === "All IT Tasks") {
        this.setState(
          {
            selectedSearchTask: [...selectedSearchTask, ...itTask]
          },
          this.selectedTasks
        );
      } else if (task.name === "All GST Tasks") {
        this.setState(
          {
            selectedSearchTask: [...selectedSearchTask, ...gstTask]
          },
          this.selectedTasks
        );
      } else if (task.name === "All Other Tasks") {
        this.setState(
          {
            selectedSearchTask: [...selectedSearchTask, ...otherTask]
          },
          this.selectedTasks
        );
      } else if (
        task.type === "GST" &&
        allSelectedGstTaskCount === gstTask.length - 2
      ) {
        this.setState(
          {
            selectedSearchTask: [...selectedSearchTask, task, gstTask[0]]
          },
          this.selectedTasks
        );
      } else if (
        task.type === "IT" &&
        allSelectedItTaskCount === itTask.length - 2
      ) {
        this.setState(
          {
            selectedSearchTask: [...selectedSearchTask, task, itTask[0]]
          },
          this.selectedTasks
        );
      } else if (
        task.type === "OTHER" &&
        allSelectedOtherTaskCount === otherTask.length - 2
      ) {
        this.setState(
          {
            selectedSearchTask: [...selectedSearchTask, task, otherTask[0]]
          },
          this.selectedTasks
        );
      } else {
        this.setState(
          { selectedSearchTask: [...selectedSearchTask, task] },
          this.selectedTasks
        );
      }
    } else {
      if (task.name === "All") {
        this.setState({ selectedSearchTask: [] }, this.selectedTasks);
      } else if (
        selectedSearchTask.length === taskTypes.length &&
        task.name === "All IT Tasks"
      ) {
        const removeTask = selectedSearchTask.filter(
          (item: any) => item.name !== "All" && item.type !== "IT"
        );
        this.setState({ selectedSearchTask: removeTask }, this.selectedTasks);
      } else if (
        selectedSearchTask.length === taskTypes.length &&
        task.name === "All GST Tasks"
      ) {
        const removeTask = selectedSearchTask.filter(
          (item: any) => item.name !== "All" && item.type !== "GST"
        );
        this.setState({ selectedSearchTask: removeTask }, this.selectedTasks);
      } else if (
        selectedSearchTask.length === taskTypes.length &&
        task.name === "All Other Tasks"
      ) {
        const removeTask = selectedSearchTask.filter(
          (item: any) => item.name !== "All" && item.type !== "OTHER"
        );
        this.setState({ selectedSearchTask: removeTask }, this.selectedTasks);
      } else if (task.name === "All IT Tasks") {
        const removeTask = selectedSearchTask.filter(
          (item: any) => item.type !== "IT"
        );
        this.setState({ selectedSearchTask: removeTask }, this.selectedTasks);
      } else if (task.name === "All GST Tasks") {
        const removeTask = selectedSearchTask.filter(
          (item: any) => item.type !== "GST"
        );
        this.setState({ selectedSearchTask: removeTask }, this.selectedTasks);
      } else if (task.name === "All Other Tasks") {
        const removeTask = selectedSearchTask.filter(
          (item: any) => item.type !== "OTHER"
        );
        this.setState({ selectedSearchTask: removeTask }, this.selectedTasks);
      } else if (
        task.type === "GST" &&
        allSelectedGstTaskCount === gstTask.length
      ) {
        const removeTask = selectedSearchTask.filter(
          (item: any) =>
            item.name !== "All GST Tasks" && item.name !== task.name
        );
        this.setState({ selectedSearchTask: removeTask }, this.selectedTasks);
      } else if (
        task.type === "IT" &&
        allSelectedItTaskCount === itTask.length
      ) {
        const removeTask = selectedSearchTask.filter(
          (item: any) => item.name !== "All IT Tasks" && item.name !== task.name
        );
        this.setState({ selectedSearchTask: removeTask }, this.selectedTasks);
      } else if (
        task.type === "OTHER" &&
        allSelectedOtherTaskCount === otherTask.length
      ) {
        const removeTask = selectedSearchTask.filter(
          (item: any) =>
            item.name !== "All Other Tasks" && item.name !== task.name
        );
        this.setState({ selectedSearchTask: removeTask }, this.selectedTasks);
      } else {
        const removeTask = selectedSearchTask.filter(
          (item: any) => item.name !== task.name
        );
        this.setState({ selectedSearchTask: removeTask }, this.selectedTasks);
      }
    }
  };

  selectedTasks = () => {
    const allTask =
      this.state.selectedSearchTask.length === this.state.taskTypes.length;
    const allItTask = this.state.selectedSearchTask.findIndex(
      (item: any) => item.name === "All IT Tasks"
    );
    const allGstTask = this.state.selectedSearchTask.findIndex(
      (item: any) => item.name === "All GST Tasks"
    );
    const allOtherTask = this.state.selectedSearchTask.findIndex(
      (item: any) => item.name === "All Other Tasks"
    );

    if (allTask) {
      this.setState({
        filters: {
          ...this.state.filters,
          name: []
        },
        searchQuery: {
          ...this.state.searchQuery,
          name: this.state.taskTypes.map(item => item.name)
        }
      });
    } else if (allItTask > -1 || allGstTask > -1 || allOtherTask > -1) {
      const tasks = this.state.selectedSearchTask
        .filter(
          item =>
            item.name !== "All" &&
            item.name !== "All IT Tasks" &&
            item.name !== "All GST Tasks" &&
            item.name !== "All Other Tasks"
        )
        .map(item => item.name);
      this.setState({
        filters: { ...this.state.filters, name: tasks },
        searchQuery: { ...this.state.searchQuery, name: tasks }
      });
    } else {
      this.setState({
        filters: {
          ...this.state.filters,
          name: this.state.selectedSearchTask.map(item => item.name)
        },
        searchQuery: {
          ...this.state.searchQuery,
          name: this.state.selectedSearchTask.map(item => item.name)
        }
      });
    }
  };

  getClientsList = () => {
    const workSpaceId = this.props.params?.firmId;
    const searchText = "";
    const active = true;
    const skip = 0;
    const limit = 100000;
    agent.Clients.getClientList(
      workSpaceId,
      skip,
      limit,
      searchText,
      active,
      {}
    )
      .then((response: any) => {
        const allFileNo: string[] = [];
        response.clients.forEach(
          (client: any) => client.fileNo && allFileNo.push(client.fileNo)
        );
        this.setState({
          clients: response.clients,
          fileNo: allFileNo
        });
      })
      .catch((err: any) => {
        this.props.addNotification?.(
          "Could not load Client Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getGroupList = () => {
    const workSpaceId = this.props.params?.firmId;
    const searchText = "";
    const active = true;
    agent.ClientGroups.getClientGroupList(
      workSpaceId,
      active,
      searchText,
      0,
      100000
    )
      .then((response: any) => {
        const groupQuery = this.props.searchParams.get("groups");
        if (groupQuery) {
          const group = response.groups.find(
            (item: any) => item._id === groupQuery
          );

          this.setState({
            groups: response.groups,
            selectedSearchGroup: group ?? null
          });
        } else {
          this.setState({
            groups: response.groups
          });
        }
      })
      .catch((err: any) => {
        this.props.addNotification?.(
          "Could not load Group Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getCustomFieldList = () => {
    const workSpaceId = this.props.params?.firmId;
    const active = true;
    const searchText = "";
    const skip = 0;
    const limit = 100000;
    agent.CustomField.getCustomFieldList(
      workSpaceId,
      active,
      searchText,
      limit,
      skip
    )
      .then((response: any) => {
        const customFields = response.customFields.filter(
          (item: any) => item.applicableFor === "task"
        );
        this.setState({ customFields });
      })
      .catch((err: any) => {
        this.props.addNotification?.(
          "Could not load Custom Field Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  queryFromDashboard = () => {
    if (
      this.props.location.pathname ===
        `/${this.props.params?.firmId}/tasks/list` &&
      this.props.location.search !== ""
    ) {
      const statusId = this.props.searchParams.get("statusIds");
      const period = this.props.searchParams.get("period");
      const periodType = this.props.searchParams.get("periodType");
      const name = this.props.searchParams.get("name");
      const userId = this.props.searchParams.get("user");
      const userName = this.props.searchParams.get("userName");
      const otherUserIds = this.props.searchParams.get("otherUser");
      const dueDateParam = this.props.searchParams.get("dueDate");

      const selectedDueDate = getDueDateFromDays(dueDateParam);
      const newFilters: any = {};

      const queries = this.props.searchParams.entries();
      for (const [key, value] of queries) {
        if (key === "dueDate") {
          this.addDueDateRange(
            new Date(selectedDueDate?.from).toDateString(),
            new Date(selectedDueDate?.to).toDateString(),
            value
          );
        } else if (key === "type") {
          newFilters.type = [value];
        } else if (key === "statusIds") {
          newFilters.statusIds = [value];
        } else if (key === "period") {
          newFilters.period = value.split(", ");
        } else if (key === "name") {
          newFilters.name = [value];
        } else if (key === "user") {
          newFilters.currentUserIds =
            value === "Unassigned"
              ? [""]
              : value.includes(",")
              ? value.split(",")
              : [value];
        } else if (key === "userName") {
          newFilters.currentUserName = value;
        } else if (key === "otherUser") {
          newFilters.users =
            newFilters?.users?.length > 0
              ? [...newFilters.users, value]
              : value
              ? [value]
              : [];
        }
      }

      this.setState({
        filters: {
          ...this.state.filters,
          ...newFilters
        },
        selectedSearchStatus:
          this.props?.status?.find((status: any) => status._id === statusId) ??
          "",
        selectedSearchTask: [{ name }],
        selectedSearchPeriod: { name: period },
        selectedSearchPeriodType: { name: periodType ?? "" },
        selectedSearchUser: userName
          ? { name: userName }
          : userId === "Unassigned"
          ? { name: "Unassigned" }
          : this.props?.users
              ?.filter(user => user._id === userId)
              ?.map((item: any) => ({ name: item.name, _id: item._id }))[0] ?? {
              name: ""
            },
        selectedOtherUser: this.props?.users
          ?.filter(user => otherUserIds?.includes(user._id))
          ?.map((item: any) => ({ name: item.name, _id: item._id })) ?? [
          {
            name: ""
          }
        ]
      });
    }
  };

  setFilterQueries = () => {
    const isSearched = this.props.searchParams.get("isSearched");
    if (isSearched) {
      const query = this.props.searchParams;
      const queryData: any = {};
      const filters: any = {};
      const searchValues: any = {};
      const custom: any = {};
      const searchQuery: any = {};
      for (const entry of query.entries()) {
        const [param, value] = entry;
        queryData[param] = value;
        if (param === "dueDate") {
          if (value.includes("-")) {
            const splitDate = value.split("-");
            const fromDate = splitDate?.[0]?.split("/");
            const toDate = splitDate?.[1]?.split("/");

            const fromLocalDate = new Date(
              fromDate[2],
              fromDate[1] - 1,
              fromDate[0]
            ).toDateString();
            const toLocalDate = new Date(
              toDate[2],
              toDate[1] - 1,
              toDate[0]
            ).toDateString();
            const fromIsoDate =
              value === "OverDue"
                ? new Date(2001, 0, 1).toISOString()
                : new Date(fromLocalDate + "GMT").toISOString();
            const toIsoDate =
              value === "OverDue"
                ? new Date().toISOString()
                : new Date(toLocalDate + "GMT").toISOString();

            searchValues.searchDueDate = {
              showCalender: false,
              value: value,
              from: fromLocalDate,
              to: toLocalDate
            };

            filters.dueDate = { from: fromIsoDate, to: toIsoDate };
            searchQuery[param] = value;
          } else {
            const selectedDueDate = getDueDateFromDays(value);
            this.addDueDateRange(
              new Date(selectedDueDate?.from).toDateString(),
              new Date(selectedDueDate?.to).toDateString(),
              value
            );
          }
        } else if (param === "dateRange") {
          const splitDate = value.split("-");
          const fromDate = splitDate[0].split("/");
          const toDate = splitDate[1].split("/");

          const fromLocalDate = new Date(
            fromDate[2],
            fromDate[1] - 1,
            fromDate[0]
          ).toDateString();
          const toLocalDate = new Date(
            toDate[2],
            toDate[1] - 1,
            toDate[0]
          ).toDateString();
          const fromIsoDate = new Date(fromLocalDate).toISOString();
          const toIsoDate = new Date(toLocalDate).toISOString();

          searchValues.searchCreatedOn = {
            showCalender: false,
            value: value,
            from: fromLocalDate,
            to: toLocalDate
          };

          filters.dateRange = { from: fromIsoDate, to: toIsoDate };
          searchQuery[param] = value;
        } else if (param === "clientName") {
          searchValues.selectedSearchClient = { name: value };
          filters.clientName = [encodeURIComponent(value)];
          searchQuery[param] = value;
        } else if (param === "groups") {
          filters.groups = [value];
          searchQuery[param] = value;
        } else if (param === "clientFileNo") {
          searchValues.selectedSearchFileNo = { name: value };
          filters.clientFileNo = value;
          searchQuery[param] = value;
        } else if (param === "clientType") {
          searchValues.selectedSearchEntity = { name: value };
          filters.clientType = value;
          searchQuery[param] = value;
        } else if (param === "star") {
          searchValues.selectedSearchStar = { name: value };
          filters.star = value === "Yes";
          searchQuery[param] = value;
        } else if (param === "tags") {
          searchValues.selectedSearchTag = { name: value };
          filters.tags = [value];
          searchQuery[param] = value;
        } else if (param === "currentUserIds") {
          searchValues.selectedSearchUser = this.props?.users
            ?.filter(user => user._id === value)
            ?.map((item: any) => ({ name: item.name, _id: item._id })) ?? {
            name: ""
          };
          filters.currentUserIds =
            filters?.currentUserIds?.length > 0
              ? [...filters.currentUserIds, value]
              : [value];
          searchQuery[param] = value;
        } else if (param === "otherUser") {
          searchValues.selectedOtherUser = this.props?.users
            ?.filter(user => user._id === value)
            ?.map((item: any) => ({ name: item.name, _id: item._id })) ?? {
            name: ""
          };
          filters.users =
            filters?.users?.length > 0 ? [...filters.users, value] : [value];
          searchQuery[param] = [value];
        } else if (param === "userName") {
          filters.currentUserName = value;
        } else if (param === "statusIds") {
          filters.statusIds = [value];
          searchQuery[param] = value;
        } else if (param === "name") {
          if (value) {
            searchValues.selectedSearchTask = [{ name: value }];
            searchQuery[param] = [value];
          }
        } else if (param === "comment") {
          searchValues.comment = value;
          filters.comment = value;
          searchQuery[param] = value;
        } else if (param === "periodType") {
          searchValues.selectedSearchPeriodType = { name: value };
          searchValues.period = getTaskPeriod(value.toLowerCase(), "", "");
          searchQuery[param] = value;
        } else if (param === "period") {
          searchValues.selectedSearchPeriod = { name: value };
          filters.period = [value];
          searchQuery[param] = value;
        } else if (param === "customField") {
          searchValues.selectedSearchCustomField = { name: value };
          custom.name = value;
          searchQuery[param] = value;
        } else if (param === "customFieldType") {
          custom.type = value;
          searchValues.selectedSearchCustomField = {
            ...searchValues.selectedSearchCustomField,
            type: value
          };
          searchQuery[param] = value;
        } else if (param === "customFieldValue") {
          const customValue =
            custom.type === "date" ? new Date(value).toISOString() : value;
          searchValues.selectedSearchCustomValue =
            custom.type === "boolean" ? { name: value } : customValue;
          filters.selectedSearchCustomValue = {
            name: custom.name,
            value: customValue
          };
          searchQuery[param] = value;
        }
      }
      this.setState({
        ...searchValues,
        isSearched: true,
        filters,
        searchQuery
      });
    } else {
      this.queryFromDashboard();
    }
  };

  // onMount Load data
  componentDidMount() {
    this.props.showAdvancedSearch && this.getClientsList();
    this.props.showAdvancedSearch && this.getGroupList();
    this.getTaskTypeList();
    this.setFilterQueries();
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = this.props.params?.firmId;

    if (prevFirmId !== currFirmId) {
      this.props.showAdvancedSearch && this.getClientsList();
      this.props.showAdvancedSearch && this.getGroupList();
      this.getTaskTypeList();
    }

    if (
      prevProps.showAdvancedSearch !== this.props.showAdvancedSearch &&
      this.props.showAdvancedSearch
    ) {
      this.getClientsList();
      this.getGroupList();
    }
  }

  onSearchCancelClick = () => {
    const { isSearched } = this.state;
    const { selectedShowTaskType } = this.props;
    const statusId = this.props.status?.find(
      (s: any) => s.name === selectedShowTaskType?.name
    )?._id;
    const isOpenTask =
      selectedShowTaskType?.name === "Open"
        ? { openTask: true }
        : { openTask: false, statusIds: [statusId] };

    const clientName =
      this.props.type === "client"
        ? { clientName: [this.props.clientName] }
        : {};

    const groupId = this.props.params?.groupId;
    const groups = this.props.type === "group" ? { groups: [groupId] } : {};

    this.setState(
      {
        filters: { ...isOpenTask, ...clientName, ...groups },
        searchDueDate: { showCalender: false, value: "", from: "", to: "" },
        selectedSearchTask: [],
        selectedSearchStatus: null,
        selectedSearchPeriodType: null,
        selectedSearchPeriod: null,
        selectedSearchUser: null,
        selectedOtherUser: [],
        selectedSearchGroup:
          this.props.type === "group" ? this.props.group : null,
        selectedSearchFileNo: null,
        selectedSearchCustomValue: null,
        selectedSearchCustomField: null,
        selectedSearchEntity: null,
        selectedSearchTag: null,
        selectedSearchStar: null,
        selectedSearchClient:
          this.props.type === "client" ? { name: this.props.clientName } : null,
        searchCreatedOn: { showCalender: false, value: "", from: "", to: "" },
        comment: "",
        isSearched: false,
        searchQuery: {}
      },
      () => {
        this.removeSearchQuery();
        this.props.onSearchCancelClick(this.state.filters, isSearched);
      }
    );
  };

  removeSearchQuery = () => {
    this.props.setSearchParams({});
  };

  addFilterQueries = () => {
    this.setState(
      { searchQuery: { ...this.state.searchQuery, isSearched: "TRUE" } },
      () => this.props.setSearchParams({ ...this.state.searchQuery })
    );
  };

  onSearchTask = () => {
    this.setState({ isSearched: true });
    this.props.onTaskSearch({ ...this.props.filters, ...this.state.filters });
    this.addFilterQueries();
  };

  updateState = (field: string) => (ev: any) => {
    this.setState({
      ...this.state,
      [field]: capitalize(ev.target.value),
      filters: {
        ...this.state.filters,
        comment: encodeURIComponent(ev.target.value)
      },
      searchQuery: {
        ...this.state.searchQuery,
        comment: ev.target.value
      }
    });
  };

  onSearchStatusChange = (item: any) => {
    const { selectedSearchStatus } = this.state;

    // if (selectedSearchStatus?._id === item._id) {
    //   this.setState({
    //     selectedSearchStatus: null,
    //     filters: {
    //       ...this.state.filters,
    //       statusIds: []
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       statusIds: []
    //     }
    //   });

    //   return;
    // }

    this.setState({
      selectedSearchStatus: item,
      filters: {
        ...this.state.filters,
        statusIds: [item._id]
      },
      searchQuery: {
        ...this.state.searchQuery,
        statusIds: item._id
      }
    });
  };

  onSearchPeriodTypeChange = (item: any) => {
    // if (item.value === this.state.selectedSearchPeriodType?.value) {
    //   this.setState({
    //     selectedSearchPeriodType: null,
    //     selectedSearchPeriod: null,
    //     filters: {
    //       ...this.state.filters,
    //       periodType: ""
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       periodType: ""
    //     }
    //   });

    //   return;
    // }

    const startPeriod = item.name.toLowerCase() === "yearly" ? "2001-02" : "";
    const endPeriod = "";
    const period = getTaskPeriod(item.value, startPeriod, endPeriod);
    this.setState({
      selectedSearchPeriodType: item,
      period,
      selectedSearchPeriod: null,
      searchQuery: {
        ...this.state.searchQuery,
        periodType: item.name
      }
    });
  };

  onSearchPeriodChange = (item: any) => {
    this.setState({
      selectedSearchPeriod: item,
      filters: { ...this.state.filters, period: [item.name] },
      searchQuery: {
        ...this.state.searchQuery,
        period: item.name
      }
    });
  };

  onSearchUserChange = (item: any) => {
    // if (item._id === this.state.selectedSearchUser?._id) {
    //   this.setState({
    //     selectedSearchUser: null,
    //     filters: {
    //       ...this.state.filters,
    //       currentUserIds: []
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       currentUserIds: []
    //     }
    //   });

    //   return;
    // }

    this.setState({
      selectedSearchUser: item,
      filters: {
        ...this.state.filters,
        currentUserIds: [item._id === "Unassigned" ? "" : item._id]
      },
      searchQuery: {
        ...this.state.searchQuery,
        currentUserIds: [item._id, ...this.state.selectedOtherUser]
      }
    });
  };

  onSearchOtherUserChange = (user: any) => {
    const index = this.state.selectedOtherUser.findIndex(
      (item: any) => item._id === user._id
    );
    if (index === -1) {
      this.setState({
        selectedOtherUser: [...this.state.selectedOtherUser, user],
        filters: {
          ...this.state.filters,
          users: [
            ...this.state.selectedOtherUser.map((user: any) => user._id),
            user._id
          ]
        }
      });
    } else {
      const filteredUsers = this.state.selectedOtherUser.filter(
        (item: any) => item._id !== user._id
      );
      const prevFilters = this.state.filters;
      filteredUsers.length === 0 && delete prevFilters.users;
      this.setState({
        selectedOtherUser: filteredUsers,
        filters:
          filteredUsers.length > 0
            ? {
                ...prevFilters,
                users: filteredUsers.map((user: any) => user._id)
              }
            : prevFilters
      });
    }
  };

  onSearchTagChange = (item: any) => {
    // if (item.name === this.state.selectedSearchTag?.name) {
    //   this.setState({
    //     selectedSearchTag: null,
    //     filters: {
    //       ...this.state.filters,
    //       tags: []
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       tags: []
    //     }
    //   });

    //   return;
    // }

    this.setState({
      selectedSearchTag: item,
      filters: { ...this.state.filters, tags: [item.name] },
      searchQuery: {
        ...this.state.searchQuery,
        tags: item.name
      }
    });
  };

  onSearchStarChange = (item: any) => {
    // if (item.name === this.state.selectedSearchStar?.name) {
    //   this.setState({
    //     selectedSearchStar: null,
    //     filters: {
    //       ...this.state.filters,
    //       star: false
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       star: ""
    //     }
    //   });

    //   return;
    // }

    this.setState({
      selectedSearchStar: item,
      filters: { ...this.state.filters, star: item.name === "Yes" },
      searchQuery: {
        ...this.state.searchQuery,
        star: item.name
      }
    });
  };

  onSearchClientChange = (item: any) => {
    // if (item.name === this.state.selectedSearchClient?.name) {
    //   this.setState({
    //     selectedSearchClient: null,
    //     filters: {
    //       ...this.state.filters,
    //       clientName: []
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       clientName: ""
    //     }
    //   });

    //   return;
    // }

    this.setState({
      selectedSearchClient: item,
      filters: {
        ...this.state.filters,
        clientName: [
          encodeURIComponent(getClientNameFromFormatedName(item.name).name)
        ]
      },
      searchQuery: {
        ...this.state.searchQuery,
        clientName: getClientNameFromFormatedName(item.name).name
      }
    });
  };

  onSearchGroupChange = (item: any) => {
    // if (item._id === this.state.selectedSearchGroup?._id) {
    //   this.setState({
    //     selectedSearchGroup: null,
    //     filters: {
    //       ...this.state.filters,
    //       groups: []
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       groups: ""
    //     }
    //   });

    //   return;
    // }

    this.setState({
      selectedSearchGroup: item,
      filters: { ...this.state.filters, groups: [item._id] },
      searchQuery: {
        ...this.state.searchQuery,
        groups: item._id
      }
    });
  };

  onSearchFileNoChange = (item: any) => {
    // if (item.name === this.state.selectedSearchFileNo?.name) {
    //   this.setState({
    //     selectedSearchFileNo: null,
    //     filters: {
    //       ...this.state.filters,
    //       clientFileNo: ""
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       clientFileNo: ""
    //     }
    //   });

    //   return;
    // }

    this.setState({
      selectedSearchFileNo: item,
      filters: { ...this.state.filters, clientFileNo: item.name },
      searchQuery: {
        ...this.state.searchQuery,
        clientFileNo: item.name
      }
    });
  };

  onSearchCustomFieldChange = (item: any) => {
    // if (item.name === this.state.selectedSearchCustomField?.name) {
    //   this.setState({
    //     selectedSearchCustomField: null,
    //     selectedSearchCustomValue: null,
    //     filters: {
    //       ...this.state.filters,
    //       customField: {
    //         name: "",
    //         value: ""
    //       }
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       customField: "",
    //       customFieldType: "",
    //       customFieldValue: ""
    //     }
    //   });

    //   return;
    // }

    this.setState({
      selectedSearchCustomField: item,
      selectedSearchCustomValue: null,
      searchQuery: {
        ...this.state.searchQuery,
        customField: item.name,
        customFieldType: item.type
      }
    });
  };

  onSearchCustomValueChange = (item: any) => {
    // if (item.name === this.state.selectedSearchCustomValue?.name) {
    //   this.setState({
    //     selectedSearchCustomValue: null,
    //     filters: {
    //       ...this.state.filters,
    //       customField: {
    //         name: "",
    //         value: ""
    //       }
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       customFieldValue: ""
    //     }
    //   });

    //   return;
    // }

    const { selectedSearchCustomField } = this.state;
    this.setState({
      selectedSearchCustomValue: item,
      filters: {
        ...this.state.filters,
        customField: {
          name: selectedSearchCustomField?.name,
          value: item.name
        }
      },
      searchQuery: {
        ...this.state.searchQuery,
        customFieldValue: item.name
      }
    });
  };

  onSearchCustomTextChange = (e: any) => {
    const { selectedSearchCustomField } = this.state;
    if (selectedSearchCustomField?.type === "date") {
      this.setState({
        selectedSearchCustomValue: e.target.value,
        filters: {
          ...this.state.filters,
          customField: {
            name: selectedSearchCustomField?.name,
            value: new Date(e.target.value).toISOString()
          }
        },
        searchQuery: {
          ...this.state.searchQuery,
          customFieldValue: e.target.value
        }
      });
    } else {
      this.setState({
        selectedSearchCustomValue: e.target.value,
        filters: {
          ...this.state.filters,
          customField: {
            name: selectedSearchCustomField?.name,
            value: e.target.value
          }
        },
        searchQuery: {
          ...this.state.searchQuery,
          customFieldValue: e.target.value
        }
      });
    }
  };

  onSearchEntityChange = (item: any) => {
    // if (item.name === this.state.selectedSearchEntity?.name) {
    //   this.setState({
    //     selectedSearchEntity: null,
    //     filters: {
    //       ...this.state.filters,
    //       clientType: ""
    //     },
    //     searchQuery: {
    //       ...this.state.searchQuery,
    //       clientType: ""
    //     }
    //   });

    //   return;
    // }

    this.setState({
      selectedSearchEntity: item,
      filters: { ...this.state.filters, clientType: item.name },
      searchQuery: {
        ...this.state.searchQuery,
        clientType: item.name
      }
    });
  };

  openDueDateCalendar = () => {
    const { searchDueDate } = this.state;
    this.setState({
      searchDueDate: {
        ...searchDueDate,
        showCalender: !searchDueDate.showCalender
      }
    });
  };

  closeDueDateCalendar = () => {
    const { searchDueDate } = this.state;
    this.setState({
      searchDueDate: {
        ...searchDueDate,
        showCalender: false
      }
    });
  };

  addDueDateRange = (
    from: string,
    to: string,
    dueType:
      | "0"
      | "1"
      | "7"
      | "overdueupto7days"
      | "overduemorethan7days"
      | "OverDue"
      | boolean
  ) => {
    const queryValue = dueType
      ? "OverDue"
      : `${formatDateAsLocalString(from)} - ${formatDateAsLocalString(to)}`;
    this.setState({
      searchDueDate: {
        showCalender: false,
        value:
          dueType === "0"
            ? "Today"
            : dueType === "1"
            ? "Tomorrow"
            : dueType === "7"
            ? "Due in Next 7 days"
            : dueType === "overdueupto7days"
            ? "OverDue up to 7 days"
            : dueType === "overduemorethan7days"
            ? "OverDue more than 7 days"
            : dueType === "OverDue"
            ? "OverDue"
            : `${formatDateAsLocalString(from)} - ${formatDateAsLocalString(
                to
              )}`,
        from: !dueType ? from : "",
        to: !dueType ? to : ""
      },
      filters: {
        ...this.state.filters,
        dueDate: { from, to }
      },
      searchQuery: { ...this.state.searchQuery, dueDate: queryValue }
    });
  };

  openCreatedOnCalendar = () => {
    const { searchCreatedOn } = this.state;
    this.setState({
      searchCreatedOn: {
        ...searchCreatedOn,
        showCalender: !searchCreatedOn.showCalender
      }
    });
  };

  closeCreatedOnCalendar = () => {
    const { searchCreatedOn } = this.state;
    this.setState({
      searchCreatedOn: {
        ...searchCreatedOn,
        showCalender: false
      }
    });
  };

  addCreatedOnRange = (from: string, to: string) => {
    const queryValue = `${formatDateAsLocalString(
      from
    )} - ${formatDateAsLocalString(to)}`;
    this.setState({
      searchCreatedOn: {
        showCalender: false,
        value: `${formatDateAsLocalString(from)} - ${formatDateAsLocalString(
          to
        )}`,
        from,
        to
      },
      filters: {
        ...this.state.filters,
        dateRange: { from, to }
      },
      searchQuery: { ...this.state.searchQuery, dateRange: queryValue }
    });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <div className="max-w-screen-2xl mx-auto shadow bg-white border px-6 pb-6 my-4">
        <h4 className="block text-lg font-medium text-gray-800 py-3">
          Search Tasks
        </h4>
        <div className="grid grid-cols-5 gap-x-4 gap-y-8">
          <div className="relative">
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                value={this.state.searchDueDate.value}
                onChange={() => null}
                id="search_due_date"
                className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                placeholder="Select Due Date"
                onFocus={this.openDueDateCalendar}
              />
            </div>
            {this.state.searchDueDate.showCalender && (
              <Calender
                onCancelClick={this.closeDueDateCalendar}
                onAddClick={this.addDueDateRange}
                position={"top-4 left-0"}
                showShortcuts={true}
                selectedFromDate={this.state.searchDueDate.from}
                selectedToDate={this.state.searchDueDate.to}
                selectedDueDate={this.state.selectedDueDate}
                setSelectedDueDate={this.setSelectedDueDate}
              />
            )}
          </div>
          <div>
            <MultiSelectCheckbox
              items={this.state.taskTypes.map((item: any) => {
                return {
                  ...item,
                  _id: item._id,
                  name: item.name
                };
              })}
              selected={this.state.selectedSearchTask.map((item: any) => {
                return {
                  ...item,
                  _id: item._id,
                  name: item.name
                };
              })}
              type="Tasks"
              onChange={this.onSearchTaskChange}
              placeholder="Select Task"
              allTaskCount={this.state.taskTypes?.length}
              gstTaskCount={this.state.gstTask.length}
              itTaskCount={this.state.itTask.length}
              otherTaskCount={this.state.otherTask.length}
            />
          </div>
          <div
            className={`${
              this.props.selectedShowTaskType?.name !== "Open"
                ? "opacity-50"
                : ""
            }`}
          >
            {this.props.status && (
              <MultiSelect
                items={this.props.status?.map((item: any) => {
                  return {
                    _id: item._id,
                    name: item.name
                  };
                })}
                selected={{
                  name: this.state.selectedSearchStatus?.name
                }}
                type="status"
                onChange={this.onSearchStatusChange}
                placeholder="Select Status"
                disabled={this.props.selectedShowTaskType?.name !== "Open"}
              />
            )}
          </div>
          <div>
            <MultiSelect
              items={periodList?.map(item => {
                return {
                  ...item,
                  _id: item.name,
                  name: item.name
                };
              })}
              selected={{
                name: this.state.selectedSearchPeriodType?.name
              }}
              type="period"
              onChange={this.onSearchPeriodTypeChange}
              placeholder="Select Period Type"
            />
          </div>
          <div>
            <div
              className={`${
                this.state.selectedSearchPeriodType === null ? "opacity-50" : ""
              }`}
            >
              <MultiSelect
                items={this.state.period?.map((item: any) => {
                  return {
                    ...item,
                    _id: item.name,
                    name: item.name
                  };
                })}
                selected={{
                  name: this.state.selectedSearchPeriod?.name
                }}
                type="period"
                onChange={this.onSearchPeriodChange}
                placeholder={`Select ${
                  this.state.selectedSearchPeriodType
                    ? this.state.selectedSearchPeriodType?.name
                    : "M/Q/H/Y"
                } Period`}
                disabled={
                  this.state.selectedSearchPeriodType === null ? true : false
                }
              />
            </div>
          </div>

          {this.props.showAdvancedSearch && (
            <>
              <div>
                <MultiSelect
                  items={[
                    { name: "Unassigned", _id: "Unassigned" },
                    ...(this.props?.users
                      ? this.props?.users?.map(item => {
                          return {
                            ...item,
                            _id: item._id,
                            name: item.name
                          };
                        })
                      : [])
                  ]}
                  selected={{
                    name: this.state.selectedSearchUser?.name
                  }}
                  type="currentuser"
                  onChange={this.onSearchUserChange}
                  placeholder="Select Working User"
                />
              </div>
              <div>
                {this.props?.users && (
                  <MultiSelectCheckbox
                    items={this.props?.users?.map(item => {
                      return {
                        ...item,
                        _id: item._id,
                        name: item.name
                      };
                    })}
                    selected={this.state.selectedOtherUser.map((item: any) => {
                      return {
                        ...item,
                        _id: item._id,
                        name: item.name
                      };
                    })}
                    type="otheruser"
                    onChange={this.onSearchOtherUserChange}
                    placeholder="Select Other User"
                  />
                )}
              </div>
              <div>
                {this.props?.tags && (
                  <MultiSelect
                    items={this.props?.tags?.map((item: any) => {
                      return {
                        ...item,
                        _id: item._id,
                        name: item.name
                      };
                    })}
                    selected={{
                      name: this.state.selectedSearchTag?.name
                    }}
                    type="tag"
                    onChange={this.onSearchTagChange}
                    placeholder="Select Tag"
                  />
                )}
              </div>
              <div>
                <MultiSelect
                  items={clientTypesList?.map((item: any) => {
                    return {
                      _id: item,
                      name: item
                    };
                  })}
                  selected={{
                    name: this.state.selectedSearchEntity?.name
                  }}
                  type="entity"
                  onChange={this.onSearchEntityChange}
                  placeholder="Select Type of Entity"
                />
              </div>
              <div>
                <MultiSelect
                  items={searchYesAndNoList?.map((item: any) => {
                    return {
                      _id: item,
                      name: item
                    };
                  })}
                  selected={{
                    name: this.state.selectedSearchStar?.name
                  }}
                  type="entity"
                  onChange={this.onSearchStarChange}
                  placeholder="Select Star"
                />
              </div>
              <div className="relative">
                <div className="mt-1 flex rounded-md shadow-sm">
                  <input
                    type="text"
                    value={this.state.searchCreatedOn.value}
                    onChange={() => null}
                    id="created"
                    className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                    placeholder="Select Created On"
                    onFocus={this.openCreatedOnCalendar}
                  />
                </div>
                {this.state.searchCreatedOn.showCalender && (
                  <Calender
                    onCancelClick={this.closeCreatedOnCalendar}
                    onAddClick={this.addCreatedOnRange}
                    position={"top-4"}
                    showShortcuts={false}
                    selectedFromDate={this.state.searchCreatedOn.from}
                    selectedToDate={this.state.searchCreatedOn.to}
                  />
                )}
              </div>
              <div>
                <MultiSelect
                  items={this.state.clients?.map(item => {
                    return {
                      ...item,
                      _id: item._id,
                      name: formatClientName(item)
                    };
                  })}
                  selected={{
                    name: formatClientName(this.state.selectedSearchClient)
                  }}
                  type="client"
                  onChange={this.onSearchClientChange}
                  placeholder="Select Client"
                  disabled={this.props.type === "client"}
                />
              </div>
              <div>
                <MultiSelect
                  items={this.state.groups?.map((item: any) => {
                    return {
                      ...item,
                      _id: item._id,
                      name: item.name
                    };
                  })}
                  selected={{
                    name: this.state.selectedSearchGroup?.name
                  }}
                  type="group"
                  onChange={this.onSearchGroupChange}
                  placeholder="Select Group"
                  disabled={this.props.type === "group"}
                />
              </div>
              <div>
                <MultiSelect
                  items={this.state.fileNo?.map((item: any) => {
                    return {
                      _id: item,
                      name: item
                    };
                  })}
                  selected={{
                    name: this.state.selectedSearchFileNo?.name
                  }}
                  type="fileno"
                  onChange={this.onSearchFileNoChange}
                  placeholder="Select File No"
                />
              </div>
              {/* <div>
                  <MultiSelect
                    items={this.state.customFields?.map((item: any) => {
                      return {
                        ...item,
                        _id: item._id,
                        name: item.name
                      };
                    })}
                    selected={{
                      name: this.state.selectedSearchCustomField?.name
                    }}
                    type="customfield"
                    onChange={this.onSearchCustomFieldChange}
                    placeholder="Select Custom Field"
                  />
                </div>
                <div
                  className={`${
                    this.state.selectedSearchCustomField === null
                      ? "opacity-50"
                      : ""
                  }`}
                >
                  <div>
                    {(this.state.selectedSearchCustomField?.type ===
                      "shorttext" ||
                      this.state.selectedSearchCustomField?.type ===
                        "longtext") && (
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          value={this.state.selectedSearchCustomValue || ""}
                          onChange={this.onSearchCustomTextChange}
                          id="text"
                          className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                          placeholder="Select Custom Value"
                        />
                      </div>
                    )}
                    {this.state.selectedSearchCustomField?.type === "date" && (
                      <div className="mt-1 flex rounded-md shadow-sm">
                        <input
                          type="text"
                          value={this.state.selectedSearchCustomValue || ""}
                          onChange={this.onSearchCustomTextChange}
                          id="created"
                          className="focus:ring-indigo-500 focus:border-indigo-500 flex-1 block w-full rounded-md sm:text-sm border-gray-300"
                          placeholder="Add Custom Value"
                          onFocus={e => (e.target.type = "date")}
                          onBlur={e => (e.target.type = "text")}
                        />
                      </div>
                    )}
                    {(this.state.selectedSearchCustomField?.type ===
                      "boolean" ||
                      this.state.selectedSearchCustomField === null) && (
                      <MultiSelect
                        items={searchYesAndNoList?.map((item: any) => {
                          return {
                            _id: item,
                            name: item
                          };
                        })}
                        selected={{
                          name: this.state.selectedSearchCustomValue?.name
                        }}
                        type="customvalue"
                        onChange={this.onSearchCustomValueChange}
                        placeholder="Select Custom Value"
                        disabled={
                          this.state.selectedSearchCustomField === null
                            ? true
                            : false
                        }
                      />
                    )}
                  </div>
                </div> */}
              <div className="col-span-3">
                <div className="mt-1">
                  <textarea
                    id="comment"
                    name="comment"
                    rows={1}
                    className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                    placeholder="Search in Comment"
                    value={this.state.comment}
                    onChange={this.updateState("comment")}
                  />
                </div>
              </div>
            </>
          )}
          <div className="w-full col-end-5 mt-1 place-self-end">
            <button
              type="button"
              className="inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:bg-gray-50 focus:outline-none w-full sm:text-sm"
              onClick={this.onSearchCancelClick}
            >
              Cancel
            </button>
          </div>
          <div className="w-full col-end-6 mt-1 place-self-end">
            <button
              type="button"
              className="inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:bg-indigo-700 focus:outline-none sm:mt-0 w-full sm:text-sm"
              onClick={this.onSearchTask}
            >
              <span className="w-full flex justify-end">
                {this.props.searchLoading ? (
                  <Icon name="loading" className="mr-2" />
                ) : null}
              </span>
              <span>Search</span>
              <span className="w-full"></span>
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default compose<ComponentType<Props>>(connector, withRouter)(TaskSearch);
