import React from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Dashboard from "../../components/Dashboard";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import TagManager from "react-gtm-module";
import Icon from "../../components/Icon";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";
import MultiSelect from "../../components/MultiSelect";
import TaskTable, { UserTypeToShow } from "../../components/TaskTable";
import TaskSearch from "../../components/TaskSearch";
import { ShowTaskType, showTaskType, userTypeOptions } from "../Tasks/Index";
import { downloadFile } from "../../helpers/downloadFile";
import BulkActionModal from "../Tasks/BulkActionModal";
import Button from "../../components/Button";
import Pagination from "../../components/Pagination";
import Switch from "../../components/switch";
import { AppDispatch, RootState } from "../../store";
import { CommonAction } from "../../store/reducers/common";
import { NotifyType } from "../../store/reducers/notification";
import { Client } from "../../helpers/types";
import { DocumentTextIcon } from "@heroicons/react/24/outline";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Client Details"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {}

type State = {
  clientDetails: Client | undefined;
  name: string;
  fileNo: string;
  tradeName: string;
  searchLoading: boolean;
  completedTaskLoading: boolean;
  displayCompletedTaskDetails: any;
  totalCompletedRecords: number;
  filters: any;
  chunkSize: number;
  currPage: number;
  tasks: any;
  loading: boolean;
  totalRecords: number;
  displayTaskDetails: any;
  selectedTasks: any;
  showClientDetails: boolean;
  isClientPresent: boolean;
  selectedShowTaskType: any;
  showSearch: boolean;
  showAdvancedSearch: boolean;
  exportLoading: boolean;
  showBulkActionModal: boolean;
  selectedUserTypeToShow: UserTypeToShow;
  showCompletedTask: boolean;
};

//Main Classs
class ClientDetails extends React.Component<Props, State> {
  state: State = {
    completedTaskLoading: false,
    displayCompletedTaskDetails: [],
    totalCompletedRecords: 0,
    clientDetails: undefined,
    name: "",
    tradeName: "",
    fileNo: "",
    filters: { openTask: true },
    chunkSize: 10,
    currPage: 0,
    tasks: [],
    loading: false,
    totalRecords: 0,
    displayTaskDetails: [],
    searchLoading: false,
    selectedTasks: [],
    showClientDetails: false,
    isClientPresent: true,
    selectedShowTaskType: { _id: "Open", name: "Open" },
    showSearch: false,
    showAdvancedSearch: false,
    exportLoading: false,
    showBulkActionModal: false,
    selectedUserTypeToShow:
      (localStorage.getItem("userTypeToShow") as UserTypeToShow) || "working",
    showCompletedTask: false
  };

  handlePageClick = (data: { selected: number }) => {
    this.setState({ currPage: data.selected }, () => {
      this.getTaskData();
    });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 }, () =>
      this.getTaskData()
    );
  };

  getTaskData = <TFilter,>(download = false, completedTaskFilter?: TFilter) => {
    const workSpaceId = this.props.params?.firmId;
    let limit: number = this.state.chunkSize;
    let skip: number = this.state.chunkSize * this.state.currPage;
    const searchText = "";
    const filters = completedTaskFilter
      ? completedTaskFilter
      : this.state.filters;
    download
      ? this.setState({ exportLoading: true })
      : this.setState({ loading: true });

    completedTaskFilter && this.setState({ completedTaskLoading: true });

    workSpaceId &&
      agent.Tasks.getTaskList(
        workSpaceId,
        completedTaskFilter ? 10 : limit,
        completedTaskFilter ? 0 : skip,
        searchText,
        filters,
        download ? true : false
      )
        .then((response: any) => {
          if (download) {
            this.setState({ exportLoading: false });
            downloadFile(response, "TaxPido PMS Tasks List.xlsx");
          } else if (completedTaskFilter) {
            this.setState({
              displayCompletedTaskDetails: response.tasks,
              totalCompletedRecords: response.count,
              completedTaskLoading: false
            });
          } else {
            this.setState({
              tasks: response.tasks,
              loading: false,
              totalRecords: response.count,
              displayTaskDetails: response.tasks,
              searchLoading: false,
              selectedTasks: []
            });
          }
        })
        .catch((err: any) => {
          if (download) {
            this.setState({ exportLoading: false });
            const data = new Blob([err.response.data], {
              type: err.response.data.type
            });
            data.text().then(text => {
              const error = JSON.parse(text);
              this.props.onNotify?.(
                "Could not export Task Details",
                error?.message || error,
                "danger"
              );
            });
          } else {
            this.setState({
              loading: false,
              searchLoading: false,
              completedTaskLoading: false
            });
            this.props.onNotify?.(
              "Could not load Task Details",
              err?.response?.data?.message || err?.message || err,
              "danger"
            );
          }
        });
  };

  setFilterQueries = () => {
    const isSearched = this.props.searchParams?.get("isSearched");
    if (isSearched) {
      const query = (this.props as any).searchParams;
      const filters: any = {};
      const custom: any = {};
      for (const entry of query.entries()) {
        const [param, value] = entry;
        if (param === "dueDate") {
          const splitDate = value.split("-");
          const fromDate = splitDate[0].split("/");
          const toDate = splitDate[1].split("/");
          const fromIsoDate =
            value === "OverDue"
              ? new Date(2001, 0, 1).toISOString()
              : new Date(
                  fromDate[2],
                  fromDate[1] - 1,
                  fromDate[0]
                ).toISOString();
          const toIsoDate =
            value === "OverDue"
              ? new Date().toISOString()
              : new Date(toDate[2], toDate[1] - 1, toDate[0]).toISOString();

          filters.dueDate = { from: fromIsoDate, to: toIsoDate };
        } else if (param === "dateRange") {
          const splitDate = value.split("-");
          const fromDate = splitDate[0].split("/");
          const toDate = splitDate[1].split("/");

          const fromIsoDate = new Date(
            fromDate[2],
            fromDate[1] - 1,
            fromDate[0]
          ).toISOString();
          const toIsoDate = new Date(
            toDate[2],
            toDate[1] - 1,
            toDate[0]
          ).toISOString();

          filters.dateRange = { from: fromIsoDate, to: toIsoDate };
        } else if (param === "clientName") {
          filters.clientName = [encodeURIComponent(value)];
        } else if (
          param === "groups" ||
          param === "currentUserIds" ||
          param === "tags" ||
          param === "period"
        ) {
          filters[param] = [value];
        } else if (param === "statusIds") {
          filters[param] = [value];
          filters.openTask = false;
        } else if (param === "star") {
          filters.star = value === "Yes";
        } else if (param === "customField") {
          custom.name = value;
        } else if (param === "customFieldType") {
          custom.type = value;
        } else if (param === "customFieldValue") {
          const customValue =
            custom.type === "date" ? new Date(value).toISOString() : value;
          filters.customField = {
            name: custom.name,
            value: customValue
          };
        } else if (param === "periodType" || param === "isSearched") {
        } else {
          filters[param] = value;
        }
      }
      return filters;
    }
    return {};
  };

  getClientDetails = () => {
    const workSpaceId = this.props.params?.firmId;
    const clientId = this.props.params?.clientId;
    this.setState({ loading: true });
    workSpaceId &&
      clientId &&
      agent.Clients.getOneClientById(workSpaceId, clientId)
        .then((response: any) => {
          const client = response.client[0];
          const clientName = client?.name ? client.name : "";
          const queryFilters = this.setFilterQueries();

          this.setState(
            {
              isClientPresent: client ? true : false,
              loading: false,
              filters: {
                ...this.state.filters,
                ...queryFilters,
                clientName: [encodeURIComponent(clientName)]
              },
              clientDetails: client,
              name: client?.name ? client.name : "",
              tradeName: client?.tradeName ? client.tradeName : "",
              fileNo: client?.fileNo ? client.fileNo : ""
            },
            () => {
              if (this.state.isClientPresent) {
                this.getTaskData();
                this.props.status?.length && this.getCompletedTaskData();
              }
            }
          );
        })
        .catch((err: any) => {
          this.setState({ loading: false, isClientPresent: false });
          this.props.onNotify?.(
            "Could not load Client Details",
            typeof err?.response?.data?.message === "object"
              ? "Could not load Client Details"
              : err?.response?.data?.message || err?.message || err,
            "danger"
          );
        });
  };

  getCompletedTaskData = () => {
    if (this.state.selectedShowTaskType.name === "Open") {
      const completedStatusId = this.props.status?.find(
        (s: any) => s.name.toLowerCase() === "completed"
      )?._id;

      const filters = {
        ...this.state.filters,
        openTask: false,
        statusIds: [completedStatusId]
      };

      this.getTaskData(false, filters);
    }
  };

  componentDidMount() {
    const workSpaceId = this.props.params?.firmId;
    const clientId = this.props.params?.clientId;
    workSpaceId && clientId && this.getClientDetails();
    document.title = "Clients Profile - TaxPido PMS";
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = this.props.params?.firmId;
    if (prevFirmId !== currFirmId && currFirmId) {
      this.getClientDetails();
    }
    const prevClientId = prevProps.params?.clientId;
    const currClientId = this.props.params?.clientId;
    if (prevClientId !== currClientId && currClientId) {
      this.getClientDetails();
    }
    if (prevProps.status !== this.props.status && this.props.status?.length) {
      this.getCompletedTaskData();
    }
  }

  onUserTypeToShowChange = (item: { _id: UserTypeToShow }) => {
    this.setState({ selectedUserTypeToShow: item._id });
    localStorage.setItem("userTypeToShow", item._id);
  };

  onTaskCheckBoxChange = (task: any) => {
    const { selectedTasks } = this.state;
    const clientIndex = selectedTasks.findIndex((c: any) => c._id === task._id);
    if (clientIndex === -1) {
      this.setState({ selectedTasks: [...selectedTasks, task] });
    } else {
      const removeClient = selectedTasks.filter((c: any) => c._id !== task._id);
      this.setState({ selectedTasks: removeClient });
    }
  };

  onSelectAllTask = () => {
    const { displayTaskDetails, selectedTasks } = this.state;
    if (selectedTasks.length === displayTaskDetails.length) {
      this.setState({ selectedTasks: [] });
    } else {
      this.setState({ selectedTasks: displayTaskDetails });
    }
  };

  openAddTaskPage = () => {
    const taskRights = this.props?.rights?.clientRights;
    const createRight = taskRights?.create;
    const currentFirmId = this.props.params?.firmId;
    if (createRight) {
      this.props.navigate?.(`/${currentFirmId}/tasks/add`);
    } else {
      this.props.onNotify?.(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  onShowTaskChange = (item: { name: ShowTaskType }) => {
    if (item.name === "Open") {
      const filters = this.state.filters;
      delete filters.statusIds;
      this.setState(
        {
          selectedShowTaskType: item,
          filters: { ...filters, openTask: true },
          currPage: 0
        },
        () => this.getTaskData()
      );
    } else {
      const statusId = this.props.status?.find(
        (s: any) => s.name === item.name
      )._id;
      this.setState(
        {
          selectedShowTaskType: item,
          currPage: 0,
          filters: {
            ...this.state.filters,
            openTask: false,
            statusIds: [statusId]
          }
        },
        () => this.getTaskData()
      );
    }
  };

  onAdvancedSearchClick = () => {
    this.setState({
      showAdvancedSearch: !this.state.showAdvancedSearch,
      showSearch: false
    });
  };

  onSearchClick = () => {
    this.setState({
      showSearch: !this.state.showSearch,
      showAdvancedSearch: false
    });
  };

  openBulkActionModal = () => {
    const { selectedTasks } = this.state;
    if (selectedTasks.length === 0) {
      this.props.onNotify?.(
        "No Task Selected",
        "Please select at least one task to perform bulk action.",
        "danger"
      );
    } else {
      this.setState({
        showBulkActionModal: true
      });
    }
  };

  onTaskSearch = (filters: any) => {
    this.setState({ currPage: 0, searchLoading: true, filters }, () => {
      this.getTaskData();
    });
  };

  onSearchCancelClick = (filters: any, isSearched: boolean) => {
    this.setState(
      {
        showSearch: false,
        showAdvancedSearch: false,
        filters
      },
      () => {
        isSearched && this.getTaskData();
      }
    );
  };

  bulkActionModalSetOpen = (open: boolean) => {
    this.setState({
      showBulkActionModal: open,
      selectedTasks: []
    });
  };

  openClientDetails = (type: "edit" | "view") => {
    const clientRights = this.props?.rights?.clientRights;
    const editRight = clientRights?.edit;
    if (editRight) {
      this.props.updateCommon?.({
        editClient: this.state.clientDetails,
        clientViewMode: type
      });
    } else {
      this.props.onNotify?.(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  handleHideShowCompletedTask = () => {
    this.setState({ showCompletedTask: !this.state.showCompletedTask });
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {this.state.showBulkActionModal && (
          <BulkActionModal
            state={this.state}
            bulkActionModalSetOpen={this.bulkActionModalSetOpen}
            onLoad={() => {
              this.getTaskData();
              this.getCompletedTaskData();
            }}
          />
        )}
        <div className="w-full mx-auto">
          <div className="mt-5 md:mt-0">
            <div className="shadow sm:rounded-md bg-white">
              <div className="px-4 pt-5 pb-3 space-y-6">
                <div
                  className={`flex ${
                    this.state.clientDetails?.gstApplicable
                      ? "flex-col"
                      : "items-center"
                  } justify-between gap-4`}
                >
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    {(this.state.fileNo ? `${this.state.fileNo} - ` : "") +
                      (this.state.name ? `${this.state.name}` : "") +
                      (this.state.tradeName
                        ? ` - ${this.state.tradeName}`
                        : "") +
                      (this.state.clientDetails?.gstin
                        ? ` - ${this.state.clientDetails?.gstin}`
                        : "")}
                  </h3>
                  <div className="flex gap-4 flex-wrap items-center justify-between">
                    {this.state.clientDetails?.gstApplicable && (
                      <Button
                        type="link"
                        name="Show Return status"
                        icon={DocumentTextIcon}
                        to={`/${this.props.params?.firmId}/gst-details/${this.state.clientDetails?.gstin}`}
                      />
                    )}
                    <div className="flex gap-4 items-center justify-between">
                      <Button
                        type="link"
                        name="Edit Client"
                        icon="edit"
                        to={`/${
                          (this.props as any).params.firmId
                        }/clients/edit/${(this.props as any).params.clientId}`}
                        onClick={() => this.openClientDetails("edit")}
                      />
                      <Button
                        type="link"
                        name="Show Details"
                        icon={DocumentTextIcon}
                        to={`/${
                          (this.props as any).params.firmId
                        }/clients/edit/${(this.props as any).params.clientId}`}
                        onClick={() => this.openClientDetails("view")}
                      />
                    </div>
                  </div>
                </div>
                <div className="border-t">
                  <div className="flex items-center justify-between gap-4 mt-6">
                    <div className="w-fit flex items-center sm:flex-row flex-col gap-4">
                      <button
                        type="button"
                        className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                        onClick={this.openAddTaskPage}
                      >
                        {this.props?.rights?.clientRights.create ? (
                          <Icon name="add" className="h-4 w-4 mr-2" />
                        ) : (
                          <Icon
                            name="outline/lock-closed"
                            className="h-4 w-4 mr-2"
                          />
                        )}
                        Add Task
                      </button>
                      <div className="w-40">
                        <MultiSelect
                          items={showTaskType?.map(item => {
                            return {
                              _id: item,
                              name: item
                            };
                          })}
                          selected={{
                            name: this.state.selectedShowTaskType?.name
                          }}
                          type="showTask"
                          onChange={this.onShowTaskChange}
                          placeholder="Show Task"
                        />
                      </div>
                    </div>

                    {this.state.totalRecords > -1 && (
                      <div className="flex sm:justify-between sm:flex-row flex-col gap-4 w-64">
                        <button
                          type="button"
                          className="text-sm text-gray-600 hover:text-indigo-700 underline"
                          onClick={this.onSearchClick}
                        >
                          Search
                        </button>
                        <button
                          type="button"
                          className="text-sm text-gray-600 hover:text-indigo-700 underline"
                          onClick={this.onAdvancedSearchClick}
                        >
                          Advance Search
                        </button>
                      </div>
                    )}
                    <div className="flex items-center gap-4">
                      <div className="w-40">
                        <MultiSelect
                          items={userTypeOptions?.map((item: any) => {
                            return {
                              _id: item,
                              name: item
                            };
                          })}
                          selected={{
                            name: this.state.selectedUserTypeToShow
                          }}
                          type="UserTypeToShow"
                          onChange={this.onUserTypeToShowChange}
                          placeholder="Show User Type"
                        />
                      </div>
                      <div className="flex sm:flex-row flex-col gap-4 mt-1">
                        <button
                          type="button"
                          className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                          onClick={this.openBulkActionModal}
                        >
                          Bulk Action
                        </button>
                        <button
                          type="button"
                          className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                          onClick={() => this.getTaskData(true)}
                        >
                          {this.state.exportLoading ? (
                            <Icon name="loading" className="h-4 w-4 mr-2" />
                          ) : (
                            <Icon
                              name="outline/download"
                              className="h-4 w-4 mr-2"
                            />
                          )}
                          Export
                        </button>
                      </div>
                    </div>
                  </div>

                  <div>
                    {(this.state.showSearch ||
                      this.state.showAdvancedSearch) && (
                      <TaskSearch
                        type={"client"}
                        clientName={this.state.name}
                        showAdvancedSearch={this.state.showAdvancedSearch}
                        navigate={this.props.navigate}
                        location={this.props.location}
                        params={this.props.params}
                        setSearchParams={this.props.setSearchParams}
                        searchParams={this.props.searchParams}
                        onTaskSearch={this.onTaskSearch}
                        onSearchCancelClick={this.onSearchCancelClick}
                        onLoad={this.getTaskData}
                        searchLoading={this.state.searchLoading}
                        filters={this.state.filters}
                        selectedShowTaskType={this.state.selectedShowTaskType}
                      />
                    )}
                  </div>
                  <div>
                    <TaskTable
                      type="client"
                      userTypeToShow={this.state.selectedUserTypeToShow}
                      loading={this.state.loading}
                      displayTaskDetails={this.state.displayTaskDetails}
                      filters={this.state.filters}
                      totalRecords={this.state.totalRecords}
                      onLoad={() => {
                        this.getTaskData();
                        this.getCompletedTaskData();
                      }}
                      selectedTasks={this.state.selectedTasks}
                      onSelectAllTask={this.onSelectAllTask}
                      onTaskCheckBoxChange={this.onTaskCheckBoxChange}
                      navigate={this.props.navigate}
                      location={this.props.location}
                      params={this.props.params}
                      searchParams={this.props.searchParams}
                      setSearchParams={this.props.setSearchParams}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Pagination
            displayRecords={this.state.displayTaskDetails}
            totalRecords={this.state.totalRecords}
            chunkSize={this.state.chunkSize}
            currPage={this.state.currPage}
            handlePageClick={this.handlePageClick}
            handleItemPerPage={this.handleItemPerPage}
            className="my-4"
          />
          {this.state.displayCompletedTaskDetails.length > 0 &&
            this.state.selectedShowTaskType.name === "Open" && (
              <div className="shadow sm:rounded-md mt-12 bg-white">
                <div className="px-4 pt-3 pb-3 space-y-6">
                  <h3 className="text-lg font-medium leading-6 text-gray-900 flex items-center justify-between">
                    <button
                      onClick={this.handleHideShowCompletedTask}
                      className="inline-block w-full cursor-pointer py-2 px-1 text-left"
                    >
                      Last 10 Completed Tasks
                    </button>
                    <div className="flex items-center gap-2 text-sm">
                      <span>Hide</span>
                      <Switch
                        openIcon="subtract"
                        closeIcon="add"
                        label={"Hide Show Completed Tasks List"}
                        enabled={this.state.showCompletedTask}
                        onChange={this.handleHideShowCompletedTask}
                      />
                      <span>Show</span>
                    </div>
                  </h3>
                  {this.state.showCompletedTask && (
                    <TaskTable
                      type="client"
                      userTypeToShow={this.state.selectedUserTypeToShow}
                      loading={this.state.completedTaskLoading}
                      displayTaskDetails={
                        this.state.displayCompletedTaskDetails
                      }
                      filters={this.state.filters}
                      totalRecords={this.state.totalCompletedRecords}
                      onLoad={() => {
                        this.getTaskData();
                        this.getCompletedTaskData();
                      }}
                      selectedTasks={this.state.selectedTasks}
                      onSelectAllTask={this.onSelectAllTask}
                      onTaskCheckBoxChange={this.onTaskCheckBoxChange}
                      navigate={this.props.navigate}
                      location={this.props.location}
                      params={this.props.params}
                      searchParams={this.props.searchParams}
                      setSearchParams={this.props.setSearchParams}
                    />
                  )}
                </div>
              </div>
            )}
        </div>
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(ClientDetails) as React.ComponentType;
