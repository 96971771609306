import { ChangeEvent, Component, Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Icon from "../../components/Icon";
import MultiSelect from "../../components/MultiSelect";
import { formatDate } from "../../helpers/formatDate";
import { ADD_NOTIFICATION } from "../../store/types";
import AddEditModal, { ModalType } from "./AddEditModal";
import { formatClientName } from "../../helpers/formatClientName";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

type Props = {
  closeModal: (fetchAgain: boolean) => void;
  onLoad?: (forSearch: boolean) => void;
};

export const registerModes = ["Hard Copy", "Soft Copy", "N/A"] as const;

export type RegisterType = "In" | "Out" | "";
export type RegisterMode = (typeof registerModes)[number];
export type BooleanType = "true" | "false" | "";

export interface DocumentType {
  _id: string;
  name: string;
}

export interface KeptAtType {
  _id: string;
  name: string;
}

interface AddEditTemplate {
  _id?: string;
  date: string;
  client: { name: string; _id: string };
  type: RegisterType;
  document: { name: string; _id: string };
  mode: RegisterMode;
  manner: string;
  isOriginal: BooleanType;
  isReturnable: BooleanType;
  keptAt: { name: string; _id: string };
  receivedBy: { name: string; _id: string };
  futureActionRequire: BooleanType;
  description: string;
}

const addEditTemplate: AddEditTemplate = {
  date: formatDate(new Date(), true),
  client: { name: "", _id: "" },
  type: "",
  document: { name: "", _id: "" },
  mode: "N/A",
  manner: "",
  isOriginal: "false",
  isReturnable: "false",
  keptAt: { name: "", _id: "" },
  receivedBy: { name: "", _id: "" },
  futureActionRequire: "",
  description: ""
};

interface State {
  loading: boolean;
  currentModalType: "add" | "edit";
  parentModalVisible: boolean;
  showAddModal: boolean;
  modalType: ModalType;
  workSpaceId: string;
  clientList: { name: string; _id: string }[];
  documentList: DocumentType[];
  keptAtList: KeptAtType[];
  addEditTemp: AddEditTemplate;
}

class AddRegisterInOut extends Component<Props & PropsFromRedux, State> {
  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      currentModalType: "add",
      parentModalVisible: true,
      showAddModal: false,
      modalType: undefined,
      workSpaceId: (this.props as any).currentFirm._id,
      clientList: [],
      documentList: [],
      keptAtList: [],
      addEditTemp: addEditTemplate
    };
  }

  mannerList = () => {
    return this.state.addEditTemp.mode === "Hard Copy"
      ? ["Courier", "Hand Delivered"]
      : ["Email", "WhatsApp", "Other"];
  };

  onKeyUpFunction = (event: any) => {
    if (!this.state.showAddModal) {
      if (event.keyCode === 27) {
        this.closeAddEditRegisterModal(false);
      }

      if (event.keyCode === 13) {
        this.addRegisterInOut();
      }
    }
  };

  getClients = () => {
    this.setState({ loading: true });
    agent.Clients.getClientList(this.state.workSpaceId, 0, 1000, "", true)
      .then((res: any) => {
        this.setState({
          clientList: res.clients,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          "Error while fetching clients",
          "error"
        );
      });
  };

  getDocumentsList = () => {
    this.setState({ loading: true });
    agent.RegisterInOut.listDocuments(this.state.workSpaceId, "", 0, 100000)
      .then((res: { documents: DocumentType[] }) => {
        this.setState({
          documentList: res.documents,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          "Error while fetching documents list",
          "error"
        );
      });
  };

  getKeptAtList = () => {
    this.setState({ loading: true });
    agent.RegisterInOut.listKeptAt(this.state.workSpaceId, "", 0, 100000)
      .then((res: { documents: KeptAtType[] }) => {
        this.setState({
          keptAtList: res.documents,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        (this.props as any).addNotification(
          "Error",
          "Error while fetching kept at list",
          "error"
        );
      });
  };

  updateAddEditTemp = (
    ev: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    this.setState(prevState => ({
      addEditTemp: {
        ...prevState.addEditTemp,
        [ev.target.name]: ev.target.value
      }
    }));
  };

  clearSelection = (field: "manner" | "keptAt" | "receivedBy") => {
    this.setState({
      addEditTemp: {
        ...this.state.addEditTemp,
        [field]: field === "manner" ? "" : { _id: "", name: "" }
      }
    });
  };

  addRegisterInOut = () => {
    const workSpaceId = this.state.workSpaceId;
    const {
      date,
      client,
      type,
      document,
      mode,
      manner,
      isOriginal,
      isReturnable,
      keptAt,
      receivedBy,
      futureActionRequire,
      description
    } = this.state.addEditTemp;
    if (!date) {
      (this.props as any).addNotification(
        "Empty Date field",
        "Date is required",
        "danger"
      );
    } else if (!client._id) {
      (this.props as any).addNotification(
        "Empty Client field",
        "Client is required",
        "danger"
      );
    } else if (!type) {
      (this.props as any).addNotification(
        "Empty Type field",
        "Type is required",
        "danger"
      );
    } else if (!document._id) {
      (this.props as any).addNotification(
        "Empty Document field",
        "Document is required",
        "danger"
      );
    } else if (!futureActionRequire) {
      (this.props as any).addNotification(
        "Empty Future Action Required field",
        "Future Action Required is required",
        "danger"
      );
    } else {
      this.setState({ loading: true });
      agent.RegisterInOut.addRegisterEntry(
        workSpaceId,
        date,
        client._id,
        type,
        document._id,
        mode,
        manner,
        isOriginal === "true",
        isReturnable === "true",
        keptAt._id,
        receivedBy._id,
        futureActionRequire === "true",
        description
      )
        .then((res: any) => {
          this.setState({ loading: false });
          (this.props as any).addNotification(
            "Success",
            "Register entry added successfully",
            "success"
          );
          this.closeAddEditRegisterModal(true);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          (this.props as any).addNotification(
            "Error",
            typeof err?.response?.data?.message === "object"
              ? "Error while adding register entry"
              : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message,
            "error"
          );
        });
    }
  };

  editRegisterInOut = () => {
    const workSpaceId = this.state.workSpaceId;
    const {
      _id,
      date,
      client,
      type,
      document,
      mode,
      manner,
      isOriginal,
      isReturnable,
      keptAt,
      receivedBy,
      futureActionRequire,
      description
    } = this.state.addEditTemp;
    if (!client._id) {
      (this.props as any).addNotification(
        "Empty Client field",
        "Client is required",
        "danger"
      );
    } else if (!type) {
      (this.props as any).addNotification(
        "Empty Type field",
        "Type is required",
        "danger"
      );
    } else if (!document._id) {
      (this.props as any).addNotification(
        "Empty Document field",
        "Document is required",
        "danger"
      );
    } else if (!futureActionRequire) {
      (this.props as any).addNotification(
        "Empty Future Action Required field",
        "Future Action Required is required",
        "danger"
      );
    } else {
      this.setState({ loading: true });
      agent.RegisterInOut.editRegisterEntry(
        workSpaceId,
        _id!,
        date,
        client._id,
        type,
        document._id,
        mode,
        manner,
        isOriginal === "true",
        isReturnable === "true",
        keptAt._id,
        receivedBy._id,
        futureActionRequire === "true",
        description
      )
        .then((res: any) => {
          this.setState({ loading: false });
          (this.props as any).addNotification(
            "Success",
            "Register entry edited successfully",
            "success"
          );
          this.closeAddEditRegisterModal(true);
        })
        .catch((err: any) => {
          this.setState({ loading: false });
          (this.props as any).addNotification(
            "Error",
            typeof err?.response?.data?.message === "object"
              ? "Error while editing register entry"
              : err?.response?.data?.message ||
                  err?.response?.data?.error ||
                  err?.message,
            "error"
          );
        });
    }
  };

  onSave = () =>
    (this.props as any).currentModal.editRegisterEntry
      ? this.editRegisterInOut()
      : this.addRegisterInOut();

  closeAddEditRegisterModal = (fetchAgain: boolean) => {
    this.props.closeModal(fetchAgain);
  };

  openAddModal = (type: "addDocument" | "addKeptAt") => {
    this.setState({
      showAddModal: true,
      modalType: type,
      parentModalVisible: false
    });
  };

  closeAddModal = () => {
    this.setState({
      showAddModal: false,
      modalType: undefined,
      parentModalVisible: true
    });
  };

  componentDidMount() {
    document.addEventListener("keydown", this.onKeyUpFunction, false);
    this.getClients();
    this.getDocumentsList();
    this.getKeptAtList();
    const editRegisterEntry = (this.props as any).currentModal
      .editRegisterEntry;
    if (editRegisterEntry) {
      this.setState({
        currentModalType: "edit",
        addEditTemp: {
          ...this.state.addEditTemp,
          ...editRegisterEntry,
          isOriginal: editRegisterEntry.isOriginal ? "true" : "false",
          isReturnable: editRegisterEntry.isReturnable ? "true" : "false",
          receivedBy: (this.props as any).users.find(
            (user: any) => user._id === editRegisterEntry.receivedBy
          ) ?? { name: "", _id: "" },
          futureActionRequire: editRegisterEntry.futureActionRequire
            ? "true"
            : "false",
          keptAt: {
            _id: editRegisterEntry.keptAtId,
            name: editRegisterEntry.keptAtName
          },
          client: {
            _id: editRegisterEntry.clientId,
            name: editRegisterEntry.clientName
          },
          document: {
            _id: editRegisterEntry.documentId,
            name: editRegisterEntry.documentName
          }
        }
      });
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    if (prevState.addEditTemp.mode !== this.state.addEditTemp.mode) {
      this.setState({
        addEditTemp: {
          ...this.state.addEditTemp,
          manner: ""
        }
      });
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.onKeyUpFunction, false);
  }

  render() {
    return (
      <>
        {this.state.showAddModal && (
          <AddEditModal
            modalType={this.state.modalType}
            showParentModal={() => this.setState({ parentModalVisible: true })}
            closeParentModal={() => this.closeAddEditRegisterModal(false)}
            showModal={this.state.showAddModal}
            closeModal={this.closeAddModal}
            onLoad={
              this.state.modalType === "addDocument"
                ? this.getDocumentsList
                : this.getKeptAtList
            }
          />
        )}
        <Transition.Root
          show={
            (this.props as any)?.currentModal?.modalName ===
              "ADD_EDIT_REGISTER_IN_OUT_MODAL" && this.state.parentModalVisible
          }
          as={Fragment}
        >
          <Dialog
            as="div"
            className="fixed z-10 inset-0 overflow-y-auto"
            onClose={() => null}
          >
            <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              </Transition.Child>

              <span
                className="hidden sm:inline-block sm:align-middle sm:h-screen"
                aria-hidden="true"
              >
                &#8203;
              </span>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg w-full sm:p-6">
                  <div>
                    <div>
                      <h3 className="text-lg font-medium leading-6 text-gray-900">
                        {(this.props as any).currentModal.editRegisterEntry
                          ? "Edit"
                          : "Add"}{" "}
                        Register In/Out Entry
                      </h3>
                    </div>
                    <div>
                      <form onSubmit={e => e.preventDefault()}>
                        <div className="mt-4">
                          <label
                            htmlFor="date"
                            className="mt-2 block text-sm font-medium text-gray-700"
                          >
                            Date <span className="text-red-500">*</span>
                          </label>
                          <div className="my-1">
                            <input
                              type="date"
                              name="date"
                              id="date"
                              value={formatDate(
                                this.state.addEditTemp.date,
                                true
                              )}
                              onChange={this.updateAddEditTemp}
                              required
                              readOnly={this.state.currentModalType === "edit"}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md"
                            />
                          </div>
                          <div className="mt-2 block text-sm font-medium text-gray-700">
                            Client <span className="text-red-500">*</span>
                          </div>
                          <div className="my-1">
                            <MultiSelect
                              items={this.state.clientList.map(item => {
                                return {
                                  ...item,
                                  _id: item._id,
                                  name: formatClientName(item)
                                };
                              })}
                              selected={{
                                name:
                                  formatClientName(
                                    this.state.clientList.find(
                                      item =>
                                        item._id ===
                                        this.state.addEditTemp?.client._id
                                    )
                                  ) || ""
                              }}
                              type="type"
                              onChange={(selected: any) => {
                                this.setState({
                                  addEditTemp: {
                                    ...this.state.addEditTemp,
                                    client: selected
                                  }
                                });
                              }}
                              placeholder="Select Client"
                            />
                          </div>
                          <div className="mt-3 mb-2 grid grid-cols-3 items-center">
                            <div className="block text-sm font-medium text-gray-700">
                              Type <span className="text-red-500">*</span>
                            </div>
                            <div className="flex items-center gap-8">
                              <div className="flex items-center gap-3">
                                <input
                                  id="In"
                                  name="type"
                                  type="radio"
                                  value="In"
                                  required
                                  checked={this.state.addEditTemp.type === "In"}
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                  onChange={this.updateAddEditTemp}
                                />
                                <label
                                  htmlFor="In"
                                  className="text-sm font-medium text-gray-700 cursor-pointer capitalize"
                                >
                                  In
                                </label>
                              </div>
                              <div className="flex items-center gap-3">
                                <input
                                  id="Out"
                                  name="type"
                                  type="radio"
                                  value="Out"
                                  required
                                  checked={
                                    this.state.addEditTemp.type === "Out"
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                  onChange={this.updateAddEditTemp}
                                />
                                <label
                                  htmlFor="Out"
                                  className="text-sm font-medium text-gray-700 cursor-pointer capitalize"
                                >
                                  Out
                                </label>
                              </div>
                            </div>
                          </div>
                          <div className="mt-2 block text-sm font-medium text-gray-700">
                            Document <span className="text-red-500">*</span>
                          </div>
                          <div className="my-1">
                            <MultiSelect
                              items={this.state.documentList}
                              selected={{
                                name: this.state.addEditTemp.document.name,
                                _id: this.state.addEditTemp.document._id
                              }}
                              type="type"
                              onChange={(selected: any) => {
                                this.setState({
                                  addEditTemp: {
                                    ...this.state.addEditTemp,
                                    document: {
                                      name: selected.name,
                                      _id: selected._id
                                    }
                                  }
                                });
                              }}
                              placeholder="Select Document"
                            />
                          </div>
                          <div className="my-2">
                            <button
                              type="button"
                              className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:bg-indigo-200 focus:outline-none"
                              onClick={() => this.openAddModal("addDocument")}
                            >
                              <Icon name="add" className="h-3.5 w-3.5 mr-1" />
                              Add Document
                            </button>
                          </div>
                          <div className="mt-3 mb-2 grid grid-cols-3 items-center">
                            <div className="block text-sm font-medium text-gray-700">
                              Mode
                            </div>
                            <div className="col-span-2 flex items-center gap-4">
                              {registerModes.map((mode: RegisterMode) => (
                                <div
                                  key={mode}
                                  className="flex items-center gap-3"
                                >
                                  <input
                                    id={mode}
                                    name="mode"
                                    type="radio"
                                    value={mode}
                                    checked={
                                      this.state.addEditTemp.mode === mode
                                    }
                                    className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                    onChange={this.updateAddEditTemp}
                                  />
                                  <label
                                    htmlFor={mode}
                                    className="text-sm font-medium text-gray-700 cursor-pointer capitalize"
                                  >
                                    {mode}
                                  </label>
                                </div>
                              ))}
                            </div>
                          </div>
                          <div className="mt-2 block text-sm font-medium text-gray-700">
                            Manner
                          </div>
                          <div className="my-1">
                            <MultiSelect
                              items={this.mannerList().map((item: any) => ({
                                _id: item,
                                name: item
                              }))}
                              selected={{
                                name: this.state.addEditTemp.manner
                              }}
                              type="manner"
                              onChange={(selected: any) => {
                                this.setState({
                                  addEditTemp: {
                                    ...this.state.addEditTemp,
                                    manner:
                                      selected.name ===
                                      this.state.addEditTemp.manner
                                        ? ""
                                        : selected.name
                                  }
                                });
                              }}
                              placeholder="Select Manner"
                              clearAction={() => this.clearSelection("manner")}
                            />
                          </div>
                          {this.state.addEditTemp.mode === "Hard Copy" && (
                            <>
                              <div className="mt-3 mb-2 grid grid-cols-3 items-center">
                                <div className="block text-sm font-medium text-gray-700">
                                  Is Original{" "}
                                  <span className="text-red-500">*</span>
                                </div>
                                <div className="flex items-center gap-8">
                                  <div className="flex items-center gap-3">
                                    <input
                                      id="isOriginalTrue"
                                      name="isOriginal"
                                      type="radio"
                                      value="true"
                                      required
                                      checked={
                                        this.state.addEditTemp.isOriginal ===
                                        "true"
                                      }
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                      onChange={this.updateAddEditTemp}
                                    />
                                    <label
                                      htmlFor="isOriginalTrue"
                                      className="text-sm font-medium text-gray-700 cursor-pointer capitalize"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="flex items-center gap-3">
                                    <input
                                      id="isOriginalFalse"
                                      name="isOriginal"
                                      type="radio"
                                      value="false"
                                      required
                                      checked={
                                        this.state.addEditTemp.isOriginal ===
                                        "false"
                                      }
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                      onChange={this.updateAddEditTemp}
                                    />
                                    <label
                                      htmlFor="isOriginalFalse"
                                      className="text-sm font-medium text-gray-700 cursor-pointer capitalize"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                              <div className="mt-3 mb-2 grid grid-cols-3 items-center">
                                <div className="block text-sm font-medium text-gray-700">
                                  Is Returnable{" "}
                                  <span className="text-red-500">*</span>
                                </div>
                                <div className="flex items-center gap-8">
                                  <div className="flex items-center gap-3">
                                    <input
                                      id="isReturnableTrue"
                                      name="isReturnable"
                                      type="radio"
                                      value="true"
                                      required
                                      checked={
                                        this.state.addEditTemp.isReturnable ===
                                        "true"
                                      }
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                      onChange={this.updateAddEditTemp}
                                    />
                                    <label
                                      htmlFor="isReturnableTrue"
                                      className="text-sm font-medium text-gray-700 cursor-pointer capitalize"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="flex items-center gap-3">
                                    <input
                                      id="isReturnableFalse"
                                      name="isReturnable"
                                      type="radio"
                                      value="false"
                                      required
                                      checked={
                                        this.state.addEditTemp.isReturnable ===
                                        "false"
                                      }
                                      className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                      onChange={this.updateAddEditTemp}
                                    />
                                    <label
                                      htmlFor="isReturnableFalse"
                                      className="text-sm font-medium text-gray-700 cursor-pointer capitalize"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </>
                          )}
                          {this.state.addEditTemp.type === "In" && (
                            <>
                              <div className="mt-2 block text-sm font-medium text-gray-700">
                                Kept At
                              </div>
                              <div className="my-1">
                                <MultiSelect
                                  items={this.state.keptAtList}
                                  selected={{
                                    name: this.state.addEditTemp.keptAt.name,
                                    _id: this.state.addEditTemp.keptAt._id
                                  }}
                                  type="keptAt"
                                  onChange={(selected: any) => {
                                    this.setState({
                                      addEditTemp: {
                                        ...this.state.addEditTemp,
                                        keptAt:
                                          selected._id ===
                                            this.state.addEditTemp.keptAt._id &&
                                          this.state.addEditTemp.keptAt._id
                                            ? { _id: "", name: "" }
                                            : {
                                                _id: selected._id,
                                                name: selected.name
                                              }
                                      }
                                    });
                                  }}
                                  placeholder="Select Kept At"
                                  clearAction={() =>
                                    this.clearSelection("keptAt")
                                  }
                                />
                              </div>
                              <div className="my-2">
                                <button
                                  type="button"
                                  className="inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 text-xs font-medium text-indigo-700 hover:bg-indigo-200 focus:bg-indigo-200 focus:outline-none"
                                  onClick={() => this.openAddModal("addKeptAt")}
                                >
                                  <Icon
                                    name="add"
                                    className="h-3.5 w-3.5 mr-1"
                                  />
                                  Add Kept At
                                </button>
                              </div>
                            </>
                          )}
                          {this.state.addEditTemp.type && (
                            <>
                              <div className="mt-2 block text-sm font-medium text-gray-700">
                                {this.state.addEditTemp.type === "In"
                                  ? "Received By"
                                  : "Given By"}
                              </div>
                              <div className="my-1">
                                <MultiSelect
                                  items={(this.props as any).users.map(
                                    (item: any) => ({
                                      _id: item._id,
                                      name: item.name
                                    })
                                  )}
                                  selected={{
                                    name: this.state.addEditTemp.receivedBy
                                      .name,
                                    _id: this.state.addEditTemp.receivedBy._id
                                  }}
                                  type="receivedBy"
                                  onChange={(selected: any) => {
                                    this.setState({
                                      addEditTemp: {
                                        ...this.state.addEditTemp,
                                        receivedBy:
                                          selected._id ===
                                            this.state.addEditTemp.receivedBy
                                              ._id &&
                                          this.state.addEditTemp.receivedBy._id
                                            ? { _id: "", name: "" }
                                            : {
                                                name: selected.name,
                                                _id: selected._id
                                              }
                                      }
                                    });
                                  }}
                                  placeholder={`Select ${
                                    this.state.addEditTemp.type === "In"
                                      ? "Received By"
                                      : "Given By"
                                  }`}
                                  clearAction={() =>
                                    this.clearSelection("receivedBy")
                                  }
                                />
                              </div>
                            </>
                          )}

                          <div className="mt-3 mb-2 grid grid-cols-2 items-center">
                            <div className="block text-sm font-medium text-gray-700">
                              Further Action Required{" "}
                              <span className="text-red-500">*</span>
                            </div>
                            <div className="flex items-center gap-8">
                              <div className="flex items-center gap-3">
                                <input
                                  id="futureActionRequireTrue"
                                  name="futureActionRequire"
                                  type="radio"
                                  value="true"
                                  required
                                  checked={
                                    this.state.addEditTemp
                                      .futureActionRequire === "true"
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                  onChange={this.updateAddEditTemp}
                                />
                                <label
                                  htmlFor="futureActionRequireTrue"
                                  className="text-sm font-medium text-gray-700 cursor-pointer capitalize"
                                >
                                  Yes
                                </label>
                              </div>
                              <div className="flex items-center gap-3">
                                <input
                                  id="futureActionRequireFalse"
                                  name="futureActionRequire"
                                  type="radio"
                                  value="false"
                                  checked={
                                    this.state.addEditTemp
                                      .futureActionRequire === "false"
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                  onChange={this.updateAddEditTemp}
                                />
                                <label
                                  htmlFor="futureActionRequireFalse"
                                  className="text-sm font-medium text-gray-700 cursor-pointer capitalize"
                                >
                                  No
                                </label>
                              </div>
                            </div>
                          </div>
                          <label
                            htmlFor="description"
                            className="mt-2 block text-sm font-medium text-gray-700"
                          >
                            Description
                          </label>
                          <div className="my-1">
                            <textarea
                              id="description"
                              name="description"
                              rows={3}
                              placeholder="Description"
                              value={this.state.addEditTemp.description}
                              onChange={this.updateAddEditTemp}
                              className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md text-sm"
                            />
                          </div>
                        </div>

                        <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
                          <button
                            type="button"
                            className="w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 focus:bg-gray-100 focus:outline-none sm:w-32 sm:text-sm"
                            onClick={() =>
                              this.closeAddEditRegisterModal(false)
                            }
                          >
                            Cancel
                          </button>
                          <button
                            type="button"
                            className={
                              "mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:bg-indigo-800 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm"
                            }
                            onClick={this.onSave}
                            disabled={this.state.loading}
                          >
                            <span className="w-full flex justify-end">
                              {this.state.loading ? (
                                <Icon name="loading" className="mr-2 w-4 h-4" />
                              ) : null}
                            </span>
                            <span>
                              {(this.props as any).currentModal
                                .editRegisterEntry
                                ? "Update"
                                : "Save"}
                            </span>
                            <span className="w-full"></span>
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>
      </>
    );
  }
}

export default connector(AddRegisterInOut);
