import React, { Component } from "react";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { Link } from "react-router-dom";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../../store/types";
import { CurrentTab, UserRightsFilter, UserTypeFilter } from "./Index";
import TaskTable, { UserTypeToShow } from "../../components/TaskTable";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props {
  state: {
    loading: boolean;
    workSpaceId?: string;
    currentTab: CurrentTab;
    dueDaysReport: any;
    showDueDaysReport: string;
    fetchingTaskBasedOnDueDays: boolean;
    taskRecordsBasedOnDueDays: any[];
    taskRecordsBasedOnDueDaysLength: number;
    userTypeFilter: UserTypeFilter;
    userRightsFilter: UserRightsFilter;
    selectedUser: { _id: string; name: string };
  };
  currentUser?: { _id: string; name: string };
  setShowDueDaysReport: any;
  onTableStatusChange: (
    task: any,
    prevStatusName: string
  ) => (status: any) => void;
  searchParameters: any;
}

class DueDaysReport extends Component<Props & PropsFromRedux> {
  state: {
    taskBasedOnDueDaysHeaders: string[];
    users: any[];
    selectedUserTypeToShow: UserTypeToShow;
  };

  constructor(props: Props) {
    super(props);

    this.state = {
      taskBasedOnDueDaysHeaders: [
        "File",
        "Client (Trade Name)",
        "Task",
        "Period",
        "Status",
        "Due Date"
      ],
      users: [],
      selectedUserTypeToShow:
        (localStorage.getItem("userTypeToShow") as UserTypeToShow) || "working"
    };
  }

  openClientDetailsPage = (task: any) => {
    const firmId = (this.props as any).params?.firmId;
    const clientId = task.clientId;
    const clientName = task.clientName;
    (this.props as any).navigate(`/${firmId}/client-profile/${clientId}`, {
      state: { clientName }
    });
  };

  openTaskDetailsModal = (task: any) => {
    const firmId = (this.props as any).params?.firmId;
    const taskId = task.taskId;
    const location = (this.props as any).location.pathname;
    (this.props as any).navigate(`/${firmId}/taskId=${taskId}`, {
      state: { from: location }
    });
  };

  componentDidMount(): void {
    if (
      (this.props.state.userRightsFilter === "admin" ||
        this.props.state.userRightsFilter === "manager") &&
      !this.state.taskBasedOnDueDaysHeaders.includes("Users")
    ) {
      this.setState({
        taskBasedOnDueDaysHeaders: [
          ...this.state.taskBasedOnDueDaysHeaders,
          "Users"
        ]
      });
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    if (
      prevProps.state.userRightsFilter !== this.props.state.userRightsFilter
    ) {
      if (
        (this.props.state.userRightsFilter === "admin" ||
          this.props.state.userRightsFilter === "manager") &&
        !this.state.taskBasedOnDueDaysHeaders.includes("Users")
      ) {
        this.setState({
          taskBasedOnDueDaysHeaders: [
            ...this.state.taskBasedOnDueDaysHeaders,
            "Users"
          ]
        });
      }
      if (this.props.state.userRightsFilter === "employee") {
        this.setState({
          taskBasedOnDueDaysHeaders:
            this.state.taskBasedOnDueDaysHeaders.filter(
              (header: string) => header !== "Users"
            )
        });
      }
    }
    if (prevProps.users !== (this.props as any).users) {
      this.setState({
        users: (this.props as any).users
      });
    }
  }

  render() {
    return (
      <>
        <div className="flex flex-wrap gap-2 lg:gap-x-6 items-stretch w-full">
          {!this.props.state.loading
            ? Object.keys(this.props.state.dueDaysReport).map(heading => (
                <button
                  key={`dueDaysHeading-${heading}`}
                  className={`divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow basis-32 flex-grow shrink focus:outline-none hover:shadow-xl focus:shadow-2xl focus:ring-2 focus:ring-offset-2 ${
                    heading.includes("upto7")
                      ? "text-red-600 focus:ring-red-600"
                      : heading.includes("morethan7")
                      ? "text-red-800 focus:ring-red-800"
                      : heading.includes("Today")
                      ? "text-yellow-600 focus:ring-yellow-500"
                      : heading.includes("Tomorrow")
                      ? "text-green-600 focus:ring-green-600"
                      : "text-indigo-600 focus:ring-indigo-600"
                  }`}
                  onClick={() => {
                    this.props.setShowDueDaysReport(heading);
                  }}
                >
                  <div className="flex flex-col items-stretch justify-between px-3 py-4">
                    <span className="capitalize text-gray-600 font-medium">
                      {heading.includes("Today")
                        ? "due"
                        : heading.includes("Tomorrow")
                        ? "due"
                        : heading.includes("upto7")
                        ? "overdue"
                        : heading.includes("morethan7")
                        ? "overdue"
                        : "due"}
                    </span>
                    <span className={`text-6xl font-medium capitalize`}>
                      {this.props.state.dueDaysReport[heading as keyof object]}
                    </span>
                    <span className="capitalize text-gray-600 font-medium">
                      {heading.includes("Today")
                        ? "today"
                        : heading.includes("Tomorrow")
                        ? "tomorrow"
                        : heading.includes("upto7")
                        ? "up to 7 days"
                        : heading.includes("morethan7")
                        ? "more than 7 days"
                        : "in 7 days"}
                    </span>
                  </div>
                </button>
              ))
            : [...Array(5)].map((_, index) => (
                <button
                  key={`dueDaysSkeleton-${index}`}
                  className={`divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow basis-32 flex-grow shrink focus:outline-none hover:shadow-xl focus:shadow-2xl focus:ring-2 focus:ring-offset-2`}
                >
                  <div className="flex flex-col items-stretch justify-between p-3 gap-y-2">
                    <Skeleton />
                    <Skeleton className="text-5xl" />
                    <Skeleton />
                  </div>
                </button>
              ))}
        </div>
        {this.props.state.showDueDaysReport && (
          <div
            className={`divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow ${
              this.props.state.showDueDaysReport ? "block" : "hidden"
            }`}
          >
            <header className="py-3 px-4 md:px-6">
              <h3 className="text-base leading-6 font-semibold text-gray-900 capitalize">
                Tasks{" "}
                {this.props.state.showDueDaysReport.includes("Today")
                  ? "due today"
                  : this.props.state.showDueDaysReport.includes("Tomorrow")
                  ? "due tomorrow"
                  : this.props.state.showDueDaysReport.includes("upto7")
                  ? "overdue up to 7 days"
                  : this.props.state.showDueDaysReport.includes("morethan7")
                  ? "overdue more than 7 days"
                  : "due in 7 days"}
              </h3>
            </header>
            <div className="px-4 pb-4">
              {this.props.state.taskRecordsBasedOnDueDays.length > 0 ? (
                <>
                  <TaskTable
                    type="dueDaysReport"
                    userTypeToShow={this.state.selectedUserTypeToShow}
                    loading={this.props.state.fetchingTaskBasedOnDueDays}
                    displayTaskDetails={
                      this.props.state.taskRecordsBasedOnDueDays
                    }
                    filters={{ openTask: true }}
                    totalRecords={
                      this.props.state.taskRecordsBasedOnDueDays.length
                    }
                    onLoad={() => null}
                    selectedTasks={[]}
                    onSelectAllTask={() => null}
                    onTaskCheckBoxChange={() => null}
                    navigate={(this.props as any).navigate}
                    location={(this.props as any).location}
                    params={(this.props as any).params}
                    searchParams={(this.props as any).searchParams}
                    setSearchParams={(this.props as any).setSearchParams}
                  />
                  {this.props.state.taskRecordsBasedOnDueDaysLength > 5 && (
                    <div className="bg-white px-3 py-2 whitespace-nowrap text-gray-900 font-semibold capitalize text-center">
                      <Link
                        className="text-sm inline-flex items-center rounded border border-transparent bg-indigo-100 px-2.5 py-1.5 font-medium text-indigo-700 hover:bg-indigo-200 focus:bg-indigo-200 focus:outline-none"
                        to={`/${
                          this.props.state.workSpaceId
                        }/tasks/list?${this.props.searchParameters(
                          "type",
                          this.props.state.currentTab === "Common"
                            ? ""
                            : this.props.state.currentTab
                        )}&${this.props.searchParameters("dueDate")}${
                          this.props.state.userTypeFilter === "otherUser" ||
                          this.props.state.userTypeFilter === "any"
                            ? `&${this.props.searchParameters(
                                "otherUser",
                                this.props.state.userRightsFilter === "employee"
                                  ? this.props.currentUser?._id
                                  : this.props.state.selectedUser._id
                              )}`
                            : this.props.state.userRightsFilter ===
                                "employee" &&
                              this.props.state.userTypeFilter === "currentUser"
                            ? `&${this.props.searchParameters(
                                "user",
                                this.props.currentUser?._id
                              )}`
                            : `&${this.props.searchParameters(
                                "user",
                                this.props.state.selectedUser._id
                              )}`
                        }`}
                      >
                        View All
                      </Link>
                    </div>
                  )}
                </>
              ) : (
                <div className="text-center w-full p-4 rounded-lg bg-white shadow-lg">
                  No Records Found
                </div>
              )}
            </div>
          </div>
        )}
      </>
    );
  }
}

export default compose(
  connector,
  withRouter
)(DueDaysReport) as React.ComponentType<Props>;
