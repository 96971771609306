import { Fragment, useEffect, useRef, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { XMarkIcon } from "@heroicons/react/24/outline";
import Skeleton from "react-loading-skeleton";
import Pagination from "./Pagination";
import agent from "../agent";

// Redux mapping
import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION } from "../store/types";
import { formatDate, formatDateAndTime } from "../helpers/formatDate";
import MultiSelect from "./MultiSelect";
import Icon from "./Icon";
import ScrollToTopBottom from "./ScrollToTopBottom";
import Popup from "./Popup";

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface LogsModalProps {
  type: string;
  showLogsModal: boolean;
  selectedRow: any;
  setOpen: (value: boolean) => void;
}

function LogsModal(props: LogsModalProps & PropsFromRedux) {
  const { type, showLogsModal, selectedRow, setOpen } = props;
  const workSpaceId = (props as any)?.currentFirm._id;

  const closeButtonRef = useRef<HTMLButtonElement>(null);

  const [loading, setLoading] = useState<boolean>(false);
  const [sorting, setSorting] = useState<string>("newest first");
  const [logList, setLogList] = useState<any[]>([]);
  const [chunkSize, setChunkSize] = useState<number>(25);
  const [currPage, setCurrPage] = useState(0);
  const skip = currPage * chunkSize;
  const [displayRecords, setDisplayRecords] = useState<any[]>([]);
  const [totalRecords, setTotalRecords] = useState(0);
  const [startDate, setStartDate] = useState<string>("");
  const [endDate, setEndDate] = useState<string>("");

  const getClientLogs = () => {
    setLoading(true);
    agent.Logs.getClientLogs(workSpaceId, selectedRow._id)
      .then((response: any) => {
        const res =
          sorting === "oldest first" ? response.data : response.data.reverse();
        setLogList(res);
        setTotalRecords(res.length);
        setDisplayRecords(res.slice(skip, chunkSize));
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        (props as any).addNotification(
          "Could not load Client Logs",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  const getStatusLogs = () => {
    setLoading(true);
    agent.Logs.getStatusLogs(workSpaceId, selectedRow._id)
      .then((response: any) => {
        const res =
          sorting === "oldest first" ? response.data : response.data.reverse();
        setLogList(res);
        setTotalRecords(res.length);
        setDisplayRecords(res.slice(skip, chunkSize));
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        (props as any).addNotification(
          "Could not load Status Logs",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  const getTagLogs = () => {
    setLoading(true);
    agent.Logs.getTagLogs(workSpaceId, selectedRow._id)
      .then((response: any) => {
        const res =
          sorting === "oldest first" ? response.data : response.data.reverse();
        setLogList(res);
        setTotalRecords(res.length);
        setDisplayRecords(res.slice(skip, chunkSize));
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        (props as any).addNotification(
          "Could not load Tag Logs",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  const getUserLogs = () => {
    setLoading(true);
    agent.Logs.getUserLogs(workSpaceId, selectedRow._id, startDate, endDate)
      .then((response: any) => {
        const res =
          sorting === "oldest first" ? response.data : response.data.reverse();
        setLogList(res);
        setTotalRecords(res.length);
        setDisplayRecords(res.slice(skip, chunkSize));
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        (props as any).addNotification(
          "Could not load User Logs",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  const getCustomFieldLogs = () => {
    setLoading(true);
    agent.Logs.getCustomFieldLogs(workSpaceId, selectedRow._id)
      .then((response: any) => {
        const res =
          sorting === "oldest first" ? response.data : response.data.reverse();
        setLogList(res);
        setTotalRecords(res.length);
        setDisplayRecords(res.slice(skip, chunkSize));
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        (props as any).addNotification(
          "Could not load Custom Field Logs",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  const getGroupLogs = () => {
    setLoading(true);
    agent.Logs.getGroupLogs(workSpaceId, selectedRow._id)
      .then((response: any) => {
        const res =
          sorting === "oldest first" ? response.data : response.data.reverse();
        setLogList(res);
        setTotalRecords(res.length);
        setDisplayRecords(res.slice(skip, chunkSize));
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        (props as any).addNotification(
          "Could not load Group Logs",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  const getContactPersonLogs = () => {
    setLoading(true);
    agent.Logs.getContactPersonLogs(workSpaceId, selectedRow._id)
      .then((response: any) => {
        const res =
          sorting === "oldest first" ? response.data : response.data.reverse();
        setLogList(res);
        setTotalRecords(res.length);
        setDisplayRecords(res.slice(skip, chunkSize));
        setLoading(false);
      })
      .catch((err: any) => {
        setLoading(false);
        (props as any).addNotification(
          "Could not load Contact Person Logs",
          err?.response?.data?.error || err?.message || err,
          "danger"
        );
      });
  };

  const getLogsList = () => {
    switch (type) {
      case "client":
        return getClientLogs();
      case "status":
        return getStatusLogs();
      case "tag":
        return getTagLogs();
      case "user":
        return getUserLogs();
      case "custom-field":
        return getCustomFieldLogs();
      case "group":
        return getGroupLogs();
      case "person":
        return getContactPersonLogs();
      default:
        return;
    }
  };

  const onKeyUpFunction = (e: any) => {
    if (e.keyCode === 27) {
      setOpen(false);
    }
  };

  useEffect(() => {
    type !== "user" && getLogsList();
    closeButtonRef.current!.focus();
    document.addEventListener("keydown", onKeyUpFunction, false);
    return () => {
      document.removeEventListener("keydown", onKeyUpFunction, false);
    }; // eslint-disable-next-line
  }, []);

  useEffect(() => {
    type === "user" && startDate && endDate && getLogsList();
  }, [startDate, endDate]);

  const handlePageClick = (data: any) => {
    setCurrPage(data.selected);
  };

  const handleItemPerPage = (value: any) => {
    setChunkSize(value.name);
  };

  useEffect(() => {
    setLogList(logList.reverse());
  }, [sorting]);

  useEffect(() => {
    setDisplayRecords(logList.slice(skip, skip + chunkSize));
  }, [skip, chunkSize, sorting, logList]);

  return (
    <>
      <Transition.Root show={showLogsModal} as={Fragment}>
        <Dialog as="div" className="relative z-30" onClose={() => null}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed z-10 inset-0 overflow-y-auto">
            <ScrollToTopBottom id="logs-modal">
              <div className="flex items-center justify-center min-h-[calc(100vh-.5rem)] p-4 text-center sm:p-0">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                  enterTo="opacity-100 translate-y-0 sm:scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                  leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                >
                  <Dialog.Panel className="relative bg-white rounded-lg px-4 pt-4 pb-6 mx-4 my-6 text-left shadow-xl transform transition-all sm:px-6 sm:pt-6 min-w-[80%]">
                    <div className="flex justify-between pb-6">
                      <div className="rounded-md">
                        <h2 className="text-lg leading-6 font-medium text-gray-900 capitalize">
                          Activity for '{selectedRow.name}'
                        </h2>
                      </div>
                      <div className="flex items-center gap-x-4">
                        {type === "user" && (
                          <div className="flex gap-4 items-center">
                            <label
                              htmlFor="startDate"
                              className="text-sm flex md:items-center gap-x-3 gap-y-1 flex-col md:flex-row"
                            >
                              Start Date
                              <input
                                type="date"
                                name="startDate"
                                id="startDate"
                                className="text-sm border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                value={formatDate(startDate, true)}
                                onChange={e =>
                                  setStartDate(
                                    new Date(e.target.value).toISOString()
                                  )
                                }
                              />
                            </label>
                            <label
                              htmlFor="endDate"
                              className="text-sm flex md:items-center gap-x-3 gap-y-1 flex-col md:flex-row"
                            >
                              End Date
                              <input
                                type="date"
                                name="endDate"
                                id="endDate"
                                className="text-sm border border-gray-300 rounded-md px-2 py-1 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                                min={formatDate(startDate, true)}
                                value={formatDate(endDate, true)}
                                onChange={e =>
                                  setEndDate(
                                    new Date(e.target.value).toISOString()
                                  )
                                }
                              />
                            </label>
                          </div>
                        )}
                        <div className="relative flex items-center">
                          <span
                            style={{
                              transform:
                                sorting === "newest first"
                                  ? "rotateX(180deg)"
                                  : "rotateX(0deg)"
                            }}
                            className={`absolute z-10 top-4 left-2 transition-transform duration-300`}
                          >
                            <Icon
                              name="sort"
                              className="h-4 w-4 fill-gray-600"
                            />
                          </span>
                          <MultiSelect
                            items={[
                              {
                                _id: "oldest first",
                                name: "oldest first"
                              },
                              {
                                _id: "newest first",
                                name: "newest first"
                              }
                            ]}
                            placeholder="Sorting Order"
                            selected={{ name: sorting }}
                            type={"sort"}
                            onChange={(selected: any) => {
                              setSorting(selected.name);
                            }}
                          />
                        </div>
                        <button
                          type="button"
                          ref={closeButtonRef}
                          className="block bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close</span>
                          <XMarkIcon className="h-6 w-6" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <LogsTable
                      type={type}
                      selectedRow={selectedRow}
                      endDate={endDate}
                      loading={loading}
                      displayRecords={displayRecords}
                      totalRecords={totalRecords}
                      users={(props as any)?.users}
                    />
                    <Pagination
                      displayRecords={displayRecords}
                      totalRecords={totalRecords}
                      currPage={currPage}
                      chunkSize={chunkSize}
                      handlePageClick={handlePageClick}
                      hidechunkSizeSelector={true}
                      className="my-4"
                    />
                    {/* <div className="mt-5 sm:mt-4 sm:flex sm:justify-end">
										<button
											type="button"
											className="whitespace-nowrap w-full inline-flex justify-center rounded-md border border-gray-300 shadow-sm py-2  text-base bg-white font-medium text-gray-700 hover:bg-gray-50 disabled:bg-gray-50 focus:outline-none sm:w-32 sm:text-sm"
											onClick={() => setOpen(false)}
										>
											Cancel
										</button>
										<button
											type="button"
											disabled={loading}
											className="whitespace-nowrap mt-3 sm:ml-4 w-full inline-flex items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 disabled:bg-indigo-700 focus:outline-none sm:mt-0 sm:w-32 sm:text-sm"
											onClick={getLogsList}
										>
											<span className="w-full flex justify-end">
												{loading ? (
													<Icon name="loading" className="mr-2" />
												) : null}
											</span>
											<span>Get Logs</span>
											<span className="w-full"></span>
										</button>
									</div> */}
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </ScrollToTopBottom>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}

export default connector(LogsModal);

interface LogsTableProps {
  forReport?: boolean;
  type: string;
  selectedRow?: any;
  endDate?: string;
  loading: boolean;
  displayRecords: any[];
  totalRecords: number;
  users: any[];
  groupedByReport?: boolean;
}

export function LogsTable({
  forReport = false,
  type,
  selectedRow,
  endDate,
  loading,
  displayRecords,
  totalRecords,
  users,
  groupedByReport = false
}: LogsTableProps) {
  const headers = [
    ...(forReport && type === "task"
      ? ["Client Name", "Task Name", "Period"]
      : groupedByReport
      ? [`${type} Name`]
      : []),
    "Type",
    "From",
    "To",
    "Date",
    "User"
  ];

  const getLogValue = (logs: any, logKey: string, type: "from" | "to") => {
    return logKey === "star"
      ? logs[logKey]?.[type] === true
        ? "Mark"
        : logs[logKey]?.[type] === false
        ? "Unmark"
        : "-"
      : logs[logKey]?.[type] === true
      ? "Active"
      : logs[logKey]?.[type] === false
      ? "Inactive"
      : logKey.toLowerCase() === "statusname" //adding the logic to show the status name by Rohit
      ? logs[logKey]?.[type]
      : logKey.toLowerCase() === "duedate" &&
        logs[logKey]?.[type]?.includes("T")
      ? logs[logKey]?.[type]?.split("T")[0]
      : Object.keys(logs[logKey]?.[type] ?? {}).length === 0
      ? "-"
      : typeof logs[logKey]?.[type] === "object" && logKey === "description"
      ? Object.keys(logs[logKey]?.[type]).length === 0
        ? ""
        : logs[logKey]?.[type]?.text
      : typeof logs[logKey]?.[type] === "object" && logKey === "currentUser"
      ? logs[logKey]?.[type]?.currentUserName || "-"
      : logs[logKey]?.[type]?.text ||
        logs[logKey]?.[type]?.message ||
        logs[logKey]?.[type]; // this is necessary to show other log values
  };

  const getLogName = (
    log: any,
    logType:
      | "task-client"
      | "task-name"
      | "task-period"
      | "client"
      | "status"
      | "tag"
      | "customfield"
      | "group"
      | "contactperson"
  ) => {
    switch (logType) {
      case "task-client":
        return log.clientName;
      case "task-name":
        return log.taskName;
      case "task-period":
        return log?.period;
      case "client":
        return log.clientName;
      case "status":
        return log.statusName;
      case "tag":
        return log.tagName;
      case "customfield":
        return log.customFieldName || "Custom Field";
      case "group":
        return log.groupName;
      case "contactperson":
        return log.contactPersonName || "Contact Person";
      default:
        return logType;
    }
  };

  const getLogType = (logKey: string) => {
    return logKey.toLowerCase() === "statusname"
      ? "Status Change"
      : `${
          logKey.toLowerCase() === "currentuser"
            ? "Current User"
            : logKey.toLowerCase() === "duedate"
            ? "Due Date"
            : logKey
        } Update`;
  };

  const getUser = (userId: string) => {
    return users.find((user: any) => user._id === userId);
  };

  return (
    <div className="bg-white shadow rounded-md">
      <div className="flex flex-col">
        <div id="table-scroll" className="overflow-x-auto">
          <div className="inline-block min-w-full align-middle">
            <div className="shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    {headers.map((header, index) => (
                      <th
                        key={header}
                        scope="col"
                        className="px-4 py-3 text-xs text-gray-500 uppercase tracking-wider text-left"
                      >
                        {header}
                      </th>
                    ))}
                  </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                  {!loading ? (
                    totalRecords > 0 ? (
                      displayRecords.map((record: any, index: number) => {
                        return record?.type === "add" ? (
                          <tr
                            key={record._id}
                            className="odd:bg-white even:bg-gray-50"
                          >
                            {forReport && (
                              <>
                                <td className="max-w-[20ch] px-4 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                  <Popup
                                    className="w-fit max-w-full"
                                    content={
                                      getLogName(
                                        record,
                                        record.logType === "task"
                                          ? "task-client"
                                          : record.logType
                                      ) ?? "-"
                                    }
                                  >
                                    <p className="truncate">
                                      {getLogName(
                                        record,
                                        record.logType === "task"
                                          ? "task-client"
                                          : record.logType
                                      ) ?? "-"}
                                    </p>
                                  </Popup>
                                </td>
                                {record.logType === "task" && (
                                  <>
                                    <td className="max-w-[20ch] px-4 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                      <Popup
                                        className="w-fit max-w-full"
                                        content={
                                          getLogName(record, "task-name") ?? "-"
                                        }
                                      >
                                        <p className="truncate">
                                          {getLogName(record, "task-name") ??
                                            "-"}
                                        </p>
                                      </Popup>
                                    </td>
                                    <td className="max-w-[20ch] px-4 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                      <Popup
                                        className="w-fit max-w-full"
                                        content={
                                          getLogName(record, "task-period") ??
                                          "-"
                                        }
                                      >
                                        <p className="truncate">
                                          {getLogName(record, "task-period") ??
                                            "-"}
                                        </p>
                                      </Popup>
                                    </td>
                                  </>
                                )}
                              </>
                            )}
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize max-w-[25ch]">
                              {forReport ? record.logType : type} Add
                            </td>
                            <td></td>
                            <td></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                              {formatDateAndTime(record?.createdAt) ?? "-"}
                            </td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                              {record?.userId.name ?? "-"}
                            </td>
                          </tr>
                        ) : record?.type === "delete" ? (
                          <tr
                            key={record._id}
                            className="odd:bg-white even:bg-gray-50"
                          >
                            {forReport && (
                              <>
                                <td className="max-w-[20ch] px-4 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                  <Popup
                                    className="w-fit max-w-full"
                                    content={
                                      getLogName(
                                        record,
                                        record.logType === "task"
                                          ? "task-client"
                                          : record.logType
                                      ) ?? "-"
                                    }
                                  >
                                    <p className="truncate">
                                      {getLogName(
                                        record,
                                        record.logType === "task"
                                          ? "task-client"
                                          : record.logType
                                      ) ?? "-"}
                                    </p>
                                  </Popup>
                                </td>
                                {record.logType === "task" && (
                                  <>
                                    <td className="max-w-[20ch] px-4 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                      <Popup
                                        className="w-fit max-w-full"
                                        content={
                                          getLogName(record, "task-name") ?? "-"
                                        }
                                      >
                                        <p className="truncate">
                                          {getLogName(record, "task-name") ??
                                            "-"}
                                        </p>
                                      </Popup>
                                    </td>
                                    <td className="max-w-[20ch] px-4 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                      <Popup
                                        className="w-fit max-w-full"
                                        content={
                                          getLogName(record, "task-period") ??
                                          "-"
                                        }
                                      >
                                        <p className="truncate">
                                          {getLogName(record, "task-period") ??
                                            "-"}
                                        </p>
                                      </Popup>
                                    </td>
                                  </>
                                )}
                              </>
                            )}
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize max-w-[25ch]">
                              {forReport ? record.logType : type} Delete
                            </td>
                            <td></td>
                            <td></td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                              {formatDateAndTime(record?.createdAt) ?? "-"}
                            </td>
                            <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                              {record?.userId.name ?? "-"}
                            </td>
                          </tr>
                        ) : (
                          <Fragment key={record._id}>
                            {record?.logs &&
                              Object.keys(record?.logs).map((logKey, index) => {
                                return (
                                  <tr
                                    key={`${record?._id}-${logKey}`}
                                    className="odd:bg-white even:bg-gray-50"
                                  >
                                    {forReport && (
                                      <>
                                        <td className="max-w-[20ch] px-4 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                          <Popup
                                            className="w-fit max-w-full"
                                            content={
                                              getLogName(
                                                record,
                                                record.logType === "task"
                                                  ? "task-client"
                                                  : record.logType
                                              ) ?? "-"
                                            }
                                          >
                                            <p className="truncate">
                                              {getLogName(
                                                record,
                                                record.logType === "task"
                                                  ? "task-client"
                                                  : record.logType
                                              ) ?? "-"}
                                            </p>
                                          </Popup>
                                        </td>
                                        {record.logType === "task" && (
                                          <>
                                            <td className="max-w-[20ch] px-4 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                              <Popup
                                                className="w-fit max-w-full"
                                                content={
                                                  getLogName(
                                                    record,
                                                    "task-name"
                                                  ) ?? "-"
                                                }
                                              >
                                                <p className="truncate">
                                                  {getLogName(
                                                    record,
                                                    "task-name"
                                                  ) ?? "-"}
                                                </p>
                                              </Popup>
                                            </td>
                                            <td className="max-w-[20ch] px-4 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                              <Popup
                                                className="w-fit max-w-full"
                                                content={
                                                  getLogName(
                                                    record,
                                                    "task-period"
                                                  ) ?? "-"
                                                }
                                              >
                                                <p className="truncate">
                                                  {getLogName(
                                                    record,
                                                    "task-period"
                                                  ) ?? "-"}
                                                </p>
                                              </Popup>
                                            </td>
                                          </>
                                        )}
                                      </>
                                    )}
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize max-w-[25ch]">
                                      {getLogType(logKey)}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize max-w-[25ch]">
                                      <Popup
                                        className="w-fit max-w-full"
                                        content={
                                          getLogType(logKey).toLowerCase() ===
                                          "due date update"
                                            ? formatDate(
                                                getLogValue(
                                                  record?.logs,
                                                  logKey,
                                                  "from"
                                                ),
                                                false
                                              )
                                            : getLogValue(
                                                record?.logs,
                                                logKey,
                                                "from"
                                              )
                                        }
                                      >
                                        <p className="truncate">
                                          {getLogType(logKey).toLowerCase() ===
                                          "due date update"
                                            ? formatDate(
                                                getLogValue(
                                                  record?.logs,
                                                  logKey,
                                                  "from"
                                                ),
                                                false
                                              )
                                            : getLogValue(
                                                record?.logs,
                                                logKey,
                                                "from"
                                              )}
                                        </p>
                                      </Popup>
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize max-w-[25ch]">
                                      <Popup
                                        className="w-fit max-w-full"
                                        content={
                                          getLogType(logKey).toLowerCase() ===
                                          "due date update"
                                            ? formatDate(
                                                getLogValue(
                                                  record?.logs,
                                                  logKey,
                                                  "to"
                                                ),
                                                false
                                              )
                                            : getLogValue(
                                                record?.logs,
                                                logKey,
                                                "to"
                                              )
                                        }
                                      >
                                        <p className="truncate">
                                          {getLogType(logKey).toLowerCase() ===
                                          "due date update"
                                            ? formatDate(
                                                getLogValue(
                                                  record?.logs,
                                                  logKey,
                                                  "to"
                                                ),
                                                false
                                              )
                                            : getLogValue(
                                                record?.logs,
                                                logKey,
                                                "to"
                                              )}
                                        </p>
                                      </Popup>
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                      {formatDateAndTime(record?.updatedAt) ??
                                        "-"}
                                    </td>
                                    <td className="px-3 py-4 whitespace-nowrap text-sm text-gray-900 odd:font-bold capitalize">
                                      {record?.userId.name ?? "-"}
                                    </td>
                                  </tr>
                                );
                              })}
                          </Fragment>
                        );
                      })
                    ) : totalRecords === 0 && !endDate && type === "user" ? (
                      <tr>
                        <td
                          colSpan={headers.length}
                          className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 w-full bg-white"
                        >
                          Choose Start and End date to view Activity for user '
                          {selectedRow.name}
                          '.
                        </td>
                      </tr>
                    ) : (
                      <tr>
                        <td
                          colSpan={headers.length}
                          className="px-6 py-3 whitespace-nowrap text-sm font-medium text-gray-900 w-full bg-white"
                        >
                          No Logs found.
                        </td>
                      </tr>
                    )
                  ) : (
                    [...Array(5)].map((e, i) => (
                      <tr key={`tr-${i}`} className="bg-white">
                        {[...Array(headers.length)].map((e, i) => (
                          <td
                            key={`td-${i}`}
                            className="px-2 py-3 whitespace-wrap text-sm font-medium text-gray-900"
                          >
                            <Skeleton />
                          </td>
                        ))}
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
