import { ChangeEvent, Component } from "react";
import TagManager from "react-gtm-module";
import { connect, ConnectedProps } from "react-redux";
import { compose } from "redux";
import Dashboard from "../../components/Dashboard";
import MultiSelect from "../../components/MultiSelect";
import { withRouter } from "../../helpers/withRouter";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import agent from "../../agent";
import { getTaskPeriod } from "../../helpers/taskPeriod";
import Button from "../../components/Button";
import Pagination from "../../components/Pagination";
import Popup from "../../components/Popup";
import { FilterType, MessageType, TaksType } from "../../helpers/types";
import Switch from "../../components/switch";
import {
  clientTypesList,
  filterList,
  returnFileList
} from "../../constants/clients";
import Icon from "../../components/Icon";
import TemplateSelectorModal from "./TemplateSelectorModal";
import MultiSelectCheckbox from "../../components/MultiSelectCheckbox";
import AddEditTemplateModal from "./AddEditTemplateModal";
import ReactQuill from "react-quill";
import { modules } from "../../components/Editor";
import { decodeHtml, parseHtml } from "../../helpers/htmlToString";
import {
  ClipboardDocumentIcon,
  CursorArrowRippleIcon
} from "@heroicons/react/20/solid";
import TableMultiSelect from "../../components/TableMultiSelect";
import { createSearchParams } from "react-router-dom";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Bulk Messages"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const taskType = [
  { _id: "GST", name: "GST" },
  { _id: "IT", name: "Income Tax" },
  { _id: "OTHER", name: "Other" }
];
const contactPersonTypeToMessage = [
  "Primary Contact",
  "Other than Primary",
  "All"
];
const messageType = ["WhatsApp", "Email" /* , "SMS" */];
const messageTo = ["Primary", "Other", "All"];

type ShowClients = "withContact" | "withoutContact" | "all";

interface State {
  showAddTemplateModal: boolean;
  showTaskFilters: boolean;
  selectedTaskType: { [key: string]: TaksType };
  returnTaskLoading: boolean;
  returnTask: any[];
  selectedReturnTask: { [key: string]: string };
  periodLoading: boolean;
  period: { [key: string]: string }[];
  selectedPeriod: { [key: string]: string } | null;
  statusList: { [key: string]: string }[];
  selectedStatus: { [key: string]: string }[];
  showTaskNotCreated: boolean;

  // client filters
  showClientFilters: boolean;
  clientTypeFilter: { [key: string]: string };
  gstFilterSelect: { [key: string]: string } | null;
  incomeTaxFilterSelect: { [key: string]: string } | null;
  itrFileReturnSelect: { [key: string]: string } | null;
  ownershipFilterSelect: { [key: string]: string } | null;
  filterGstStatus: string;
  groupFilterList: any[];
  groupLoading: boolean;
  clientGroupFilterSelect: { [key: string]: string } | null;
  tagsFilterSelect: { [key: string]: string } | null;
  tagLoading: boolean;
  registrationStateFilterSelect: { [key: string]: string } | null;
  filters: FilterType | {};

  searchText: string;
  searchTimeout: NodeJS.Timeout | null;
  showClients: ShowClients;

  messageType: MessageType;
  maxCharCount: number;
  messageTo: string;
  contactPersonTypeToMessage: string;
  showTemplateModal: boolean;
  emailSubject: string;
  selectedTemplate: { [key: string]: string };
  clientTableHeader: string[];
  clients: any[];
  displayClients: any[];
  selectedClients: any[];
  clientWithoutContacts: any[];
  selectedClientsContacts: string[];
  uniqueSelectedClientsContacts: string[];
  totalRecords: number;
  currPage: number;
  chunkSize: number;
}

class BulkMessages extends Component<PropsFromRedux, State> {
  constructor(props: any) {
    super(props);
    this.state = {
      showAddTemplateModal: false,
      showTaskFilters: true,
      selectedTaskType: { _id: "", name: "" },
      returnTaskLoading: false,
      returnTask: [],
      selectedReturnTask: { _id: "", name: "" },
      periodLoading: false,
      period: [],
      selectedPeriod: { _id: "", name: "" },
      statusList: [],
      selectedStatus: [{ _id: "All", name: "All" }],
      showTaskNotCreated: false,

      // client filters
      showClientFilters: true,
      clientTypeFilter: {},
      gstFilterSelect: null,
      incomeTaxFilterSelect: null,
      itrFileReturnSelect: null,
      ownershipFilterSelect: null,
      filterGstStatus: "",
      groupFilterList: [],
      groupLoading: false,
      clientGroupFilterSelect: null,
      tagsFilterSelect: null,
      tagLoading: false,
      registrationStateFilterSelect: null,

      filters: {},
      searchText: "",
      searchTimeout: null,
      showClients: "all",

      messageType: "",
      maxCharCount: 160,
      messageTo: "",
      contactPersonTypeToMessage: "",
      showTemplateModal: false,
      emailSubject: "",
      selectedTemplate: { template: "" },

      clientTableHeader: [
        "checkbox",
        "Client Name (Trade Name)",
        "Total Number of Contacts"
      ],
      clients: [],
      displayClients: [],
      selectedClients: [],
      clientWithoutContacts: [],
      selectedClientsContacts: [],
      uniqueSelectedClientsContacts: [],
      totalRecords: 0,
      currPage: 0,
      chunkSize: 500
    };
  }

  openAddTemplateModal = () => this.setState({ showAddTemplateModal: true });

  closeAddTemplateModal = () => this.setState({ showAddTemplateModal: false });

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 });
  };

  getClients = (forSearch: boolean) => {
    const {
      currPage,
      chunkSize,
      searchText,
      selectedPeriod,
      selectedReturnTask,
      selectedTaskType,
      filters
    } = this.state;
    const workSpaceId = (this.props as any).params.firmId;
    const limit = chunkSize;
    const skip = currPage * chunkSize;
    const period = selectedPeriod?.name ? selectedPeriod?.name : "";
    const task = selectedReturnTask?.name ? selectedReturnTask.name : "";
    const taskType = selectedTaskType?.name ? selectedTaskType._id : "";

    agent.Messages.getClientsForMessage(
      workSpaceId,
      limit,
      skip,
      forSearch ? searchText : "",
      period,
      task,
      taskType,
      filters
    )
      .then((res: any) => {
        this.setState({
          clients: res.clients,
          displayClients: this.showClient(res.clients),
          totalRecords: res.count
        });
      })
      .catch((err: any) => {
        (this.props as any).addNotification(
          "Error",
          typeof err?.response?.data?.message === "object"
            ? "Error getting Clients."
            : err?.response?.data?.message || err?.message || err,
          "error"
        );
      });
  };

  getGroupList = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const searchText = "";
    const active = true;
    this.setState({ groupLoading: true });
    agent.ClientGroups.getClientGroupList(
      workSpaceId,
      active,
      searchText,
      0,
      100000
    )
      .then((response: any) => {
        this.setState({
          groupFilterList: response.groups,
          groupLoading: false
        });
      })
      .catch((err: any) => {
        this.setState({ groupLoading: false });
        (this.props as any).onNotify(
          "Could not load Firm Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  onTaskFilterToggle = () => {
    const { showTaskFilters } = this.state;
    if (showTaskFilters) {
      this.resetTaskFilter();
    }
    this.setState({ showTaskFilters: !this.state.showTaskFilters });
  };

  resetTaskFilter = () => {
    this.setState({
      selectedTaskType: { _id: "", name: "" },
      selectedReturnTask: { _id: "", name: "" },
      selectedPeriod: null,
      selectedStatus: [{ _id: "All", name: "All" }],
      messageType: "",
      contactPersonTypeToMessage: "",
      messageTo: "",
      selectedTemplate: { template: "" },
      emailSubject: "",
      clients: [],
      selectedClients: [],
      selectedClientsContacts: [],
      uniqueSelectedClientsContacts: [],
      currPage: 0
    });
  };

  showTemplateModal = () => {
    this.setState({ showTemplateModal: true });
  };

  hideTemplateModal = () => {
    this.setState({ showTemplateModal: false });
  };

  selectTemplate = (template: { [key: string]: string }) => {
    this.setState({
      selectedTemplate: {
        ...template,
        template: template.template,
        emailHtml: decodeHtml(template.emailHtml)
      }
    });
  };

  onClientFilterToggle = () => {
    const { showClientFilters } = this.state;
    if (showClientFilters) {
      this.resetClientFilter();
    }
    this.setState({
      showClientFilters: !this.state.showClientFilters,
      clientTypeFilter: {}
    });
  };

  resetClientFilter = () => {
    this.setState({
      clientTypeFilter: {},
      gstFilterSelect: null,
      incomeTaxFilterSelect: null,
      ownershipFilterSelect: null,
      clientGroupFilterSelect: null,
      tagsFilterSelect: null,
      registrationStateFilterSelect: null,
      itrFileReturnSelect: null,
      filterGstStatus: "",
      filters: {}
    });
  };

  onFilterChange = (item: any) => {
    if (item.name === "All") {
      this.setState(
        {
          clientTypeFilter: item,
          gstFilterSelect: null,
          incomeTaxFilterSelect: null,
          itrFileReturnSelect: null,
          ownershipFilterSelect: null,
          clientGroupFilterSelect: null,
          tagsFilterSelect: null,
          registrationStateFilterSelect: null,
          filters: {},
          currPage: 0
        },
        () => this.getClients(false)
      );
    } else if (item.name === "Only Other Client") {
      this.setState(
        {
          clientTypeFilter: item,
          gstFilterSelect: null,
          incomeTaxFilterSelect: null,
          itrFileReturnSelect: null,
          ownershipFilterSelect: null,
          clientGroupFilterSelect: null,
          tagsFilterSelect: null,
          registrationStateFilterSelect: null,
          currPage: 0,
          filters: { otherClient: true }
        },
        () => this.getClients(false)
      );
    } else {
      this.setState({
        clientTypeFilter: item,
        gstFilterSelect: null,
        incomeTaxFilterSelect: null,
        itrFileReturnSelect: null,
        ownershipFilterSelect: null,
        clientGroupFilterSelect: null,
        tagsFilterSelect: null,
        registrationStateFilterSelect: null
      });
    }
  };

  onGstFilterChange = (item: any) => {
    if (item.name === "All GST") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          currPage: 0,
          filters: {
            gst: {
              sub: [{ key: "gstApplicable", value: "true" }]
            }
          }
        },
        () => this.getClients(false)
      );
    } else if (item.name === "GST Composition Clients") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "registrationType", value: "composition" }]
            }
          }
        },
        () => this.getClients(false)
      );
    } else if (item.name === "GST Regular Clients") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "registrationType", value: "regular" }]
            }
          }
        },
        () => this.getClients(false)
      );
    } /* else if (item.name === "GST Audit Applicable") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "gstApplicable", value: "true" }]
            }
          }
        },
        () => this.getClients(false)
      );
    } else if (item.name === "GSTR-9 Compulsory") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "gstr9_compulsory", value: "true" }]
            }
          }
        },
        () => this.getClients(false)
      );
    } else if (item.name === "GSTR-9C Applicable") {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: "",
          filters: {
            gst: {
              sub: [{ key: "gstr9c_compulsory", value: "true" }]
            }
          }
        },
        () => this.getClients(false)
      );
    }  */ else {
      this.setState(
        {
          gstFilterSelect: item,
          registrationStateFilterSelect: null,
          filterGstStatus: ""
        },
        () => this.getClients(false)
      );
    }
  };

  onGstStatusChange = (event: any) => {
    this.setState(
      {
        filterGstStatus: event.target.value,
        currPage: 0,
        filters: {
          gst: {
            sub: [{ key: "gstinStatus", value: event.target.value }]
          }
        }
      },
      () => this.getClients(false)
    );
  };

  onIncomeTaxFilterChange = (item: any) => {
    if (item.name === "Income Tax Audit Applicable") {
      this.setState(
        {
          incomeTaxFilterSelect: item,
          itrFileReturnSelect: null,
          currPage: 0,
          filters: {
            incomeTax: {
              sub: [{ key: "itApplicable", value: "true" }]
            }
          }
        },
        () => this.getClients(false)
      );
    } else {
      this.setState({ incomeTaxFilterSelect: item, itrFileReturnSelect: null });
    }
  };

  onItrRuturnFilterChange = (item: any) => {
    this.setState(
      {
        itrFileReturnSelect: item,
        currPage: 0,
        filters: {
          incomeTax: {
            sub: [
              {
                key: "filedITreturns",
                value: item.name
              }
            ]
          }
        }
      },
      () => this.getClients(false)
    );
  };

  onOwnershipFilterChange = (item: any) => {
    this.setState(
      {
        ownershipFilterSelect: item,
        currPage: 0,
        filters: {
          ownershipType: { sub: [{ key: "type", value: item.name }] }
        }
      },
      () => this.getClients(false)
    );
  };

  onClientGroupFilterChange = (item: any) => {
    this.setState(
      {
        clientGroupFilterSelect: item,
        currPage: 0,
        filters: {
          clientGroup: [item._id]
        }
      },
      () => this.getClients(false)
    );
  };

  onTagsFilterChange = (item: any) => {
    this.setState(
      {
        tagsFilterSelect: item,
        currPage: 0,
        filters: {
          tags: [item._id]
        }
      },
      () => this.getClients(false)
    );
  };

  onRegistrationStateFilterChange = (item: any) => {
    this.setState({ registrationStateFilterSelect: item });
  };

  getTaskType = () => {
    const { selectedTaskType } = this.state;
    this.setState({ returnTaskLoading: true });
    const workSpaceId = (this.props as any).params.firmId;
    agent.Tasks.taskTypeList(workSpaceId, selectedTaskType._id)
      .then((res: any) => {
        this.setState({
          returnTask: res.tasks.filter(
            (item: any) => item.type === selectedTaskType._id
          ),
          returnTaskLoading: false
        });
      })
      .catch((err: any) => {
        this.setState({ returnTaskLoading: false });
        (this.props as any).addNotification(
          "Error",
          typeof err?.response?.data?.message === "object"
            ? "Error getting task types."
            : err?.response?.data?.message || err?.message || err,
          "error"
        );
      });
  };

  handleSearch = (e: any) => {
    if (this.state.searchTimeout) {
      clearTimeout(this.state.searchTimeout);
    }

    this.setState({
      searchText: e.target.value,
      currPage: 0,
      searchTimeout: setTimeout(() => {
        this.getClients(true);
      }, 700)
    });
  };

  filterClientWithoutContacts = () => {
    const clientWithoutContacts = this.state.selectedClients.filter(
      (item: any) => item.contactPerson.length === 0
    );
    this.setState({ clientWithoutContacts });
  };

  onTaskTypeChange = (selectedTaskType: any) => {
    this.setState({
      selectedTaskType,
      selectedReturnTask: {},
      period: [],
      selectedPeriod: null,
      selectedStatus: [{ _id: "All", name: "All" }],
      messageType: "",
      messageTo: "",
      clients: [],
      displayClients: [],
      contactPersonTypeToMessage: "",
      selectedTemplate: { template: "" },
      selectedClients: [],
      selectedClientsContacts: [],
      uniqueSelectedClientsContacts: [],
      emailSubject: ""
    });
  };

  onReturnTaskChange = (item: any) => {
    const startPeriod = item?.startPeriod ? item.startPeriod : "";
    const endPeriod = item?.endPeriod ? item.endPeriod : "";
    const periodType = item?.periodType
      ? item.periodType
      : item.period.toLowerCase();

    const period = getTaskPeriod(periodType, startPeriod, endPeriod);
    this.setState({
      selectedReturnTask: { _id: item._id, name: item.name },
      period,
      selectedPeriod: null,
      selectedStatus: [{ _id: "All", name: "All" }],
      messageType: "",
      messageTo: "",
      contactPersonTypeToMessage: "",
      selectedTemplate: { template: "" },
      selectedClients: [],
      selectedClientsContacts: [],
      uniqueSelectedClientsContacts: [],
      emailSubject: ""
    });
  };

  onPeriodChange = (selectedPeriod: any) => {
    this.setState({
      selectedPeriod,
      selectedClients: [],
      selectedClientsContacts: [],
      uniqueSelectedClientsContacts: [],
      selectedStatus: [{ _id: "All", name: "All" }],
      messageType: "",
      messageTo: "",
      contactPersonTypeToMessage: "",
      selectedTemplate: { template: "" },
      emailSubject: ""
    });
  };

  onStatusChange = (status: any) => {
    const { statusList, selectedStatus } = this.state;
    const statusNotAlreadySelected =
      selectedStatus.findIndex((item: any) => item._id === status._id) === -1;
    if (
      (statusNotAlreadySelected && status._id === "All") ||
      selectedStatus.length === statusList.length - 2
    ) {
      this.setState({ selectedStatus: statusList });
    } else if (statusNotAlreadySelected) {
      this.setState({
        selectedStatus: [...selectedStatus, status]
      });
    } else if (
      !statusNotAlreadySelected &&
      status._id !== "All" &&
      selectedStatus.length === statusList.length
    ) {
      this.setState({
        selectedStatus: selectedStatus.filter(
          (item: any) => item._id !== status._id && item._id !== "All"
        )
      });
    } else if (!statusNotAlreadySelected && status._id === "All") {
      this.setState({ selectedStatus: [] });
    } else {
      this.setState({
        selectedStatus: selectedStatus.filter(
          (item: any) => item._id !== status._id
        )
      });
    }
  };

  filterBasedOnTaskNotCreated = (clients: any) => {
    const { showTaskNotCreated } = this.state;
    return showTaskNotCreated
      ? clients
      : clients.filter((client: any) => client.taskPresent);
  };

  filterByStatus = (clients: any) => {
    const { selectedStatus } = this.state;
    const selectedStatusIds = selectedStatus.map((item: any) => item._id);
    return selectedStatusIds.includes("All")
      ? clients
      : clients.filter(
          (client: any) =>
            client.taskPresent && selectedStatusIds.includes(client.statusId)
        );
  };

  showClient = (clients: any) => {
    const { showClients } = this.state;
    //updated the logic to show consider all the clients if user filter in only on the basis of client type
    const clientsToShow =
      this.state.selectedTaskType?.name === null ||
      this.state.selectedTaskType?.name === ""
        ? clients
        : this.filterBasedOnTaskNotCreated(clients);
    const clientFilteredByStatus = this.filterByStatus(clientsToShow);
    return showClients === "withContact"
      ? clientFilteredByStatus.filter(
          (item: any) => item.contactPerson.length > 0
        )
      : showClients === "withoutContact"
      ? clientFilteredByStatus.filter(
          (item: any) => item.contactPerson.length === 0
        )
      : clientFilteredByStatus;
  };

  onTaskNotCreatedChange = (e: any) => {
    this.setState(
      {
        showTaskNotCreated: e.target.checked,
        selectedClients: [],
        selectedClientsContacts: [],
        uniqueSelectedClientsContacts: []
      },
      () => {
        this.setState({
          displayClients: this.showClient(this.state.clients)
        });
      }
    );
  };

  handleShowClient = (e: ChangeEvent<HTMLInputElement>) => {
    this.setState(
      {
        showClients: e.target.value as ShowClients,
        currPage: 0,
        selectedClients: [],
        selectedClientsContacts: [],
        uniqueSelectedClientsContacts: []
      },
      () => {
        this.setState({
          displayClients: this.showClient(this.state.clients)
        });
      }
    );
  };

  onMessageTypeChange = (e: any) => {
    this.setState({
      messageType: e.target.value,
      messageTo: "",
      selectedClients: [],
      selectedClientsContacts: [],
      uniqueSelectedClientsContacts: [],
      emailSubject: "",
      selectedTemplate: { template: "" }
    });
  };

  onMessageToChange = (e: any) => {
    this.setState({
      messageTo: e.target.value,
      selectedClients: [],
      selectedClientsContacts: [],
      uniqueSelectedClientsContacts: [],
      emailSubject: "",
      selectedTemplate: { template: "" }
    });
  };

  onContactPersonTypeToMessageChange = (e: any) => {
    this.setState({
      contactPersonTypeToMessage: e.target.value,
      messageType: "",
      messageTo: "",
      selectedClients: [],
      selectedClientsContacts: [],
      uniqueSelectedClientsContacts: [],
      emailSubject: "",
      selectedTemplate: { template: "" }
    });
  };

  onMessageChange = (e: any) => {
    this.setState({ selectedTemplate: { template: e.target.value } });
  };

  onEmailSubjectChange = (e: any) => {
    this.setState({ selectedTemplate: { emailSubject: e.target.value } });
  };

  handleEmailHtmlChange = (value: any) => {
    const parsedValue = parseHtml(value);
    this.setState({
      selectedTemplate: { emailHtml: value, template: parsedValue }
    });
  };

  copyEmail = () => {
    const email = this.state.selectedTemplate.emailHtml;
    const emailBlob = new Blob([email], {
      type: "text/html"
    });

    navigator.clipboard
      .write([
        new ClipboardItem({
          [emailBlob.type]: emailBlob
        })
      ])
      .then(() => {
        (this.props as any).addNotification(
          "Success",
          "Email copied to clipboard",
          "success"
        );
      })
      .catch((err: any) => {
        (this.props as any).addNotification(
          "Error",
          "Error copying email to clipboard",
          "error"
        );
      });
  };

  copyMessage = () => {
    navigator.clipboard
      .writeText(this.state.selectedTemplate.template)
      .then(() => {
        (this.props as any).addNotification(
          "Success",
          "Message copied to clipboard",
          "success"
        );
      })
      .catch((err: any) => {
        (this.props as any).addNotification(
          "Error",
          "Error copying message to clipboard",
          "error"
        );
      });
  };

  copyNumbers = () => {
    const numbers = this.state.uniqueSelectedClientsContacts.join(", ");
    navigator.clipboard.writeText(numbers);
    // }
    (this.props as any).addNotification(
      "Success",
      "Numbers copied to clipboard",
      "success"
    );
  };

  sendEmails = () => {
    const { uniqueSelectedClientsContacts, selectedTemplate } = this.state;

    const emails = uniqueSelectedClientsContacts.join(",");
    const subject = selectedTemplate.emailSubject
      ? selectedTemplate.emailSubject
      : "";
    const emailParam = emails ? `&${createSearchParams({ bcc: emails })}` : "";
    const subjectParam = subject ? `&${createSearchParams({ subject })}` : "";

    const emailLink = `mailto:?${subjectParam}${emailParam}`;
    try {
      (this.props as any).addNotification(
        "Sending email",
        "We have added the email and the subject to the email client. Please copy the content of the email and paste it in the email client to send the email.",
        "success"
      );
      setTimeout(
        () => window.open(this.getValidLengthEmailLink(emailLink), "_blank"),
        2500
      );
    } catch (error) {
      (this.props as any).addNotification(
        "Could not send email",
        error instanceof Error ? error.message : error,
        "error"
      );
    }
  };

  getValidLengthEmailLink = (emailLink: string) => {
    const splittedEmailLink = emailLink.split("&bcc=");
    const mailtoPrefix = splittedEmailLink[0] + "&bcc=";
    const emails = splittedEmailLink[1];

    const maxUrlCharacters = 1900;
    const emailSeparator = ",";
    let currentIndex = 0;
    let nextIndex = 0;

    const validEmailLength = maxUrlCharacters - mailtoPrefix.length;

    if (emails.length < validEmailLength) {
      return mailtoPrefix + emails;
    }

    do {
      currentIndex = nextIndex;
      nextIndex = emails.indexOf(emailSeparator, currentIndex + 1);
    } while (nextIndex !== -1 && nextIndex < validEmailLength);

    if (currentIndex === -1) {
      return mailtoPrefix + emails;
    } else {
      // return mailtoPrefix + emails.slice(0, currentIndex);
      throw new Error(
        `You can only send ${
          currentIndex + 1
        } emails at a time. Please deselect some emails and try again.`
      );
    }
  };

  selectClientsContacts = () => {
    const {
      contactPersonTypeToMessage,
      messageType,
      messageTo,
      selectedClients
    } = this.state;

    if (selectedClients.length > 0) {
      const selectedClientsContacts = selectedClients.map(client => {
        const primaryContactId =
          client.contactPerson.length > 0
            ? client.contactPerson?.find((item: any) => item.isPrimary === true)
                ?.personId
            : null;

        if (
          contactPersonTypeToMessage.toLowerCase() === "primary contact" &&
          primaryContactId
        ) {
          const primaryContact = client.contactPersonDetails.find(
            (item: any) => item._id === primaryContactId
          );
          const { email, mobile } = primaryContact;
          return { email, mobile };
        } else if (
          contactPersonTypeToMessage.toLowerCase() === "other than primary"
        ) {
          const otherContacts = client.contactPersonDetails.filter(
            (item: any) => item._id !== primaryContactId
          );
          return otherContacts.map((item: any) => {
            const { email, mobile } = item;
            return { email, mobile };
          });
        } else {
          const contacts = client.contactPersonDetails.map((item: any) => {
            const { email, mobile } = item;
            return { email, mobile };
          });
          return contacts;
        }
      });

      const selectedContacts = selectedClientsContacts
        .flat(2)
        .map((item: any) => {
          if (messageType.toLowerCase() === "email") {
            return item.email.map((item: any) => ({
              emailId: item.emailId,
              isPrimary: item.isPrimary
            }));
          } else if (messageType.toLowerCase() === "sms") {
            return item.mobile
              .filter((item: any) => !item.isWhatsapp)
              .map((item: any) => ({
                mobileNumber: item.mobileNumber,
                isPrimary: item.isPrimary
              }));
          } else if (messageType.toLowerCase() === "whatsapp") {
            return item.mobile
              .filter((item: any) => item.isWhatsapp)
              .map((item: any) => ({
                mobileNumber: item.mobileNumber,
                isPrimary: item.isPrimary
              }));
          }
          return null;
        })
        .flat(1);

      const primaryContacts = selectedContacts.filter(
        (item: any) => item?.isPrimary
      );
      const otherContacts = Array.from(
        new Set(selectedContacts.filter((item: any) => !item?.isPrimary))
      );
      const allContacts = Array.from(
        new Set(selectedContacts.map((item: any) => item))
      );

      const uniquePrimaryContacts = Array.from(
        new Set(
          primaryContacts.map(item =>
            this.state.messageType.toLowerCase() === "sms" ||
            this.state.messageType.toLowerCase() === "whatsapp"
              ? item.mobileNumber
              : item.emailId
          )
        )
      );
      const uniqueOtherContacts = Array.from(
        new Set(
          otherContacts.map(item =>
            this.state.messageType.toLowerCase() === "sms" ||
            this.state.messageType.toLowerCase() === "whatsapp"
              ? item.mobileNumber
              : item.emailId
          )
        )
      );
      const uniqueAllContacts = Array.from(
        new Set(
          allContacts.map(item =>
            this.state.messageType.toLowerCase() === "sms" ||
            this.state.messageType.toLowerCase() === "whatsapp"
              ? item.mobileNumber
              : item.emailId
          )
        )
      );

      if (messageTo.toLowerCase() === "primary") {
        this.setState({
          selectedClientsContacts: primaryContacts,
          uniqueSelectedClientsContacts: uniquePrimaryContacts
        });
      } else if (messageTo.toLowerCase() === "other") {
        this.setState({
          selectedClientsContacts: otherContacts,
          uniqueSelectedClientsContacts: uniqueOtherContacts
        });
      } else {
        this.setState({
          selectedClientsContacts: allContacts,
          uniqueSelectedClientsContacts: uniqueAllContacts
        });
      }
    }
  };

  onSelectAllClients = () => {
    const { displayClients, selectedClients } = this.state;
    if (selectedClients.length === displayClients.length) {
      this.setState({ selectedClients: [] });
    } else {
      this.setState({ selectedClients: displayClients });
    }
  };

  onSelectClient = (client: any) => {
    const { selectedClients } = this.state;
    const index = selectedClients.findIndex(
      (item: any) => item._id === client._id
    );
    if (index === -1) {
      this.setState({ selectedClients: [...selectedClients, client] });
    } else {
      this.setState({
        selectedClients: selectedClients.filter(
          (item: any) => item._id !== client._id
        )
      });
    }
  };

  componentDidMount() {
    document.title = "Bulk Messages - TaxPido PMS";
  }

  componentDidUpdate(prevProps: any, prevState: State) {
    const prevFirmId = prevProps.params.firmId;
    const currFirmId = (this.props as any).params.firmId;
    if (prevFirmId !== currFirmId) {
      this.resetClientFilter();
      this.resetTaskFilter();
    }
    if (
      prevState.selectedTaskType !== this.state.selectedTaskType &&
      this.state.selectedTaskType._id
    ) {
      this.getTaskType();
    }
    if (
      prevState.currPage !== this.state.currPage ||
      prevState.chunkSize !== this.state.chunkSize
    ) {
      this.state.searchText ? this.getClients(true) : this.getClients(false);
    }
    if (
      (prevState.messageType !== this.state.messageType &&
        this.state.messageType) ||
      (prevState.selectedPeriod !== this.state.selectedPeriod &&
        this.state.selectedPeriod)
    ) {
      this.getClients(false);
    }
    if (
      prevState.selectedReturnTask.name !== this.state.selectedReturnTask.name
    ) {
      const status =
        (this.props as any)?.taskStatus?.[this.state.selectedReturnTask.name] ||
        (this.props as any)?.taskStatus?.["Other"] ||
        (this.props as any)?.statusApplicableToAllTasks;

      this.setState({
        statusList: [{ _id: "All", name: "All" }, ...status],
        selectedStatus: [{ _id: "All", name: "All" }, ...status]
      });
    }
    if (
      prevState.contactPersonTypeToMessage !==
      this.state.contactPersonTypeToMessage
    ) {
      if (
        this.state.contactPersonTypeToMessage.toLowerCase().includes("primary")
      ) {
        this.state.clientTableHeader.indexOf("Primary Contact Person") === -1 &&
          this.setState({
            clientTableHeader: [
              ...this.state.clientTableHeader,
              "Primary Contact Person"
            ]
          });
      } else {
        this.setState({
          clientTableHeader: this.state.clientTableHeader.filter(
            (item: any) => item !== "Primary Contact Person"
          )
        });
      }
    }
    if (prevState.selectedPeriod?.name !== this.state.selectedPeriod?.name) {
      this.state.selectedReturnTask.name && this.state.selectedPeriod?.name
        ? this.state.clientTableHeader.indexOf("Status of Task") === -1 &&
          this.setState({
            clientTableHeader: [
              ...this.state.clientTableHeader,
              "Status of Task"
            ]
          })
        : this.setState({
            clientTableHeader: this.state.clientTableHeader.filter(
              (item: any) => item !== "Status of Task"
            )
          });
    }
    if (prevState.selectedClients !== this.state.selectedClients) {
      this.selectClientsContacts();
      this.filterClientWithoutContacts();
    }
    if (prevState.selectedStatus !== this.state.selectedStatus) {
      this.setState({ displayClients: this.showClient(this.state.clients) });
    }
    const { clientTypeFilter } = this.state;
    const prevclientTypeFilter = prevState.clientTypeFilter;
    if (
      prevclientTypeFilter?.name !== clientTypeFilter?.name &&
      clientTypeFilter?.name === "Client Group"
    ) {
      this.getGroupList();
    }
  }

  render() {
    TagManager.dataLayer(tagManagerArgs);

    return (
      <Dashboard>
        {this.state.showAddTemplateModal && (
          <AddEditTemplateModal
            type="add"
            onLoad={() => null}
            showModal={this.state.showAddTemplateModal}
            closeModal={this.closeAddTemplateModal}
          />
        )}

        <div className="max-w-full mx-auto p-6 xs:px-4 sm:px-4 md:px-8 bg-white rounded-md">
          <h1 className="text-xl font-medium leading-6 text-gray-900 mb-6">
            Messages
          </h1>
          <div
            className={
              this.state.showTaskFilters || this.state.showClientFilters
                ? ""
                : "sm:divide-y-2 sm:divide-gray-200"
            }
          >
            {/* Task Filters */}
            <div>
              <fieldset
                className={`space-y-4 ${
                  this.state.showTaskFilters ? "border-2 p-4" : "pb-2"
                } border-gray-300 rounded-lg transition-[border-color,padding] duration-200 ease-in-out`}
              >
                <legend
                  onClick={this.onTaskFilterToggle}
                  className={`${
                    this.state.showTaskFilters && "px-4 border-2"
                  } rounded-[inherit] py-2 text-base font-semibold text-gray-900 w-full flex gap-4 items-center justify-between`}
                >
                  <div>Filter based on Task Applicability</div>
                  <div className="flex items-center gap-2">
                    <span>Hide</span>
                    <Switch
                      openIcon="subtract"
                      closeIcon="add"
                      label={"Hide Show Task Filters"}
                      enabled={this.state.showTaskFilters}
                      onChange={this.onTaskFilterToggle}
                    />
                    <span>Show</span>
                  </div>
                </legend>
                {this.state.showTaskFilters && (
                  <div className="space-y-4 sm:divide-y-2 divide-gray-200">
                    {/* Task Type Selector for searching clients */}
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Send Message for
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                        <MultiSelect
                          type="taskType"
                          placeholder="Select Task Type"
                          items={taskType}
                          selected={{
                            name: this.state.selectedTaskType.name
                          }}
                          onChange={this.onTaskTypeChange}
                        />
                      </div>
                    </div>
                    {/* Task Selecor */}
                    {this.state.selectedTaskType._id && (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-4">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Select Return type
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                          {this.state.returnTaskLoading ? (
                            <Icon
                              name="loading"
                              className="animate-spin mx-auto"
                            />
                          ) : (
                            <MultiSelect
                              type="returnType"
                              placeholder="Select Return Type"
                              items={this.state.returnTask.map((task: any) => {
                                return {
                                  ...task,
                                  _id: task._id,
                                  name: task.name
                                };
                              })}
                              selected={{
                                name: this.state.selectedReturnTask.name
                              }}
                              onChange={this.onReturnTaskChange}
                            />
                          )}
                        </div>
                      </div>
                    )}
                    {/* Period Selector */}
                    {this.state.selectedReturnTask._id && (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:pt-4">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Select Period
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                          <MultiSelect
                            type="period"
                            placeholder="Select Period"
                            items={this.state.period.map((item: any) => {
                              return {
                                ...item,
                                _id: item._id,
                                name: item.name
                              };
                            })}
                            selected={{ name: this.state.selectedPeriod?.name }}
                            onChange={this.onPeriodChange}
                          />
                        </div>
                      </div>
                    )}
                    {/* Status Selector */}
                    {this.state.selectedPeriod?.name && (
                      <div className="sm:grid sm:grid-cols-3 sm:items-center sm:gap-4 sm:pt-4">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Select Status
                        </label>
                        <div className="sm:col-span-2 xl:flex gap-x-8">
                          <div className="mt-1 sm:mt-0 w-full sm:max-w-xs">
                            <MultiSelectCheckbox
                              type="message-status-filter"
                              placeholder="Select Status"
                              items={this.state.statusList}
                              selected={this.state.selectedStatus}
                              onChange={this.onStatusChange}
                            />
                          </div>
                          <div className="flex gap-3 items-center mt-2 xl:mt-0">
                            <input
                              id="showTaskNotCreated"
                              type="checkbox"
                              checked={this.state.showTaskNotCreated}
                              onChange={this.onTaskNotCreatedChange}
                              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                            />
                            <label
                              htmlFor="showTaskNotCreated"
                              className="text-sm font-medium text-gray-700"
                            >
                              Show Task Not Created
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </fieldset>
            </div>

            {/* Client Filters */}
            <div className="pt-4">
              <fieldset
                className={`space-y-4 ${
                  this.state.showClientFilters ? "border-2 p-4" : "pb-0"
                } border-gray-300 rounded-lg transition-[border-color,padding] duration-200 ease-in-out`}
              >
                <legend
                  onClick={this.onClientFilterToggle}
                  className={`${
                    this.state.showClientFilters && "px-4 border-2"
                  } rounded-[inherit] py-2 text-base font-semibold text-gray-900 w-full flex gap-4 items-center justify-between`}
                >
                  <div>Filter based on Client Properties</div>
                  <div className="flex items-center gap-2">
                    <span>Hide</span>
                    <Switch
                      openIcon="subtract"
                      closeIcon="add"
                      label={"Hide Show Client Filters"}
                      enabled={this.state.showClientFilters}
                      onChange={this.onClientFilterToggle}
                    />
                    <span>Show</span>
                  </div>
                </legend>
                {this.state.showClientFilters && (
                  <div className="space-y-6 sm:space-y-5">
                    {/* Client Type Selector */}
                    <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-gray-200 sm:pt-5">
                      <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                        Clients Type
                      </label>
                      <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                        <MultiSelect
                          items={filterList
                            .initialList("All")
                            ?.map((item: any) => {
                              return {
                                _id: item,
                                name: item
                              };
                            })}
                          selected={{
                            name: this.state.clientTypeFilter?.name
                          }}
                          type="filterclients"
                          onChange={this.onFilterChange}
                          placeholder="Select Type of Clients"
                        />
                      </div>
                    </div>
                    {/* GST Type Selector */}
                    {this.state.clientTypeFilter?.name === "GST" && (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          GST Type
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                          <MultiSelect
                            items={filterList.gstSlectionList?.map(
                              (item: any, index: number) => {
                                return {
                                  _id: `${item}-${index}`,
                                  name: item
                                };
                              }
                            )}
                            selected={{
                              name: this.state.gstFilterSelect?.name
                            }}
                            type="filterclients"
                            onChange={this.onGstFilterChange}
                            placeholder="Select GST Type"
                          />
                        </div>
                      </div>
                    )}
                    {/* GST Registration State Selector */}
                    {this.state.gstFilterSelect?.name ===
                      "GST Registration State" && (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          GST Registration State
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                          <MultiSelect
                            items={filterList.stateList.map(
                              (item: any, index: number) => {
                                return {
                                  _id: `${item}-${index}`,
                                  name: item
                                };
                              }
                            )}
                            selected={{
                              name: this.state.registrationStateFilterSelect
                                ?.name
                            }}
                            type="filterClients"
                            onChange={this.onRegistrationStateFilterChange}
                            placeholder="Select GST Registration State"
                          />
                        </div>
                      </div>
                    )}
                    {/* GSTIN Status Selector */}
                    {this.state.gstFilterSelect?.name === "GSTIN Status" && (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          GST Status
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                          <fieldset className="mt-2">
                            <legend className="sr-only">Create Task</legend>
                            <div className="space-y-4 sm:flex sm:items-center sm:space-y-0 sm:space-x-10">
                              <div className="flex items-center">
                                <input
                                  id={"active"}
                                  name="active"
                                  type="radio"
                                  value={"active"}
                                  checked={
                                    this.state.filterGstStatus === "active"
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                  onChange={this.onGstStatusChange}
                                />
                                <label
                                  htmlFor={"active"}
                                  className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                                >
                                  Active
                                </label>
                              </div>
                              <div className="flex items-center">
                                <input
                                  id={"inactive"}
                                  name="inactive"
                                  type="radio"
                                  value={"inactive"}
                                  checked={
                                    this.state.filterGstStatus === "inactive"
                                  }
                                  className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                                  onChange={this.onGstStatusChange}
                                />
                                <label
                                  htmlFor={"inactive"}
                                  className="ml-3 block text-sm font-medium text-gray-700 cursor-pointer"
                                >
                                  Inactive
                                </label>
                              </div>
                            </div>
                          </fieldset>
                        </div>
                      </div>
                    )}
                    {/* Income Tax Type Selector */}
                    {this.state.clientTypeFilter?.name === "Income Tax" && (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Income Tax Type
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                          <MultiSelect
                            items={filterList.incomeTaxSelectionList?.map(
                              (item: any, index: number) => {
                                return {
                                  _id: `${item}-${index}`,
                                  name: item
                                };
                              }
                            )}
                            selected={{
                              name: this.state.incomeTaxFilterSelect?.name
                            }}
                            type="filterClients"
                            onChange={this.onIncomeTaxFilterChange}
                            placeholder="Select Income Tax Type"
                          />
                        </div>
                      </div>
                    )}
                    {/* IT return Type Selector */}
                    {this.state.incomeTaxFilterSelect?.name ===
                      "Returns You File in IT" && (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Returns You File In IT
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                          <MultiSelect
                            items={returnFileList.map(
                              (item: any, index: number) => {
                                return {
                                  _id: `${item}-${index}`,
                                  name: item
                                };
                              }
                            )}
                            selected={{
                              name: this.state.itrFileReturnSelect?.name
                            }}
                            type="itrfiles"
                            onChange={this.onItrRuturnFilterChange}
                            placeholder="Select ITR Return File"
                          />
                        </div>
                      </div>
                    )}
                    {/* Ownership Type Selector */}
                    {this.state.clientTypeFilter?.name === "Ownership Type" && (
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                          Ownership Type
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                          <MultiSelect
                            items={clientTypesList.map(
                              (item: any, index: number) => {
                                return {
                                  _id: `${item}-${index}`,
                                  name: item
                                };
                              }
                            )}
                            selected={{
                              name: this.state.ownershipFilterSelect?.name
                            }}
                            type="filterClients"
                            onChange={this.onOwnershipFilterChange}
                            placeholder="Select Ownership Type"
                          />
                        </div>
                      </div>
                    )}
                    {/* Group Selector */}
                    {this.state.clientTypeFilter?.name === "Client Group" && (
                      <>
                        {this.state.groupLoading ? (
                          <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                            <Icon
                              name="loading"
                              className="text-indigo-600 mx-auto"
                            />
                          </div>
                        ) : (
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Client Group
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                              <MultiSelect
                                items={this.state.groupFilterList?.map(
                                  (item: any) => {
                                    return {
                                      ...item,
                                      _id: item._id,
                                      name: item.name
                                    };
                                  }
                                )}
                                selected={{
                                  name: this.state.clientGroupFilterSelect?.name
                                }}
                                type="filterClients"
                                onChange={this.onClientGroupFilterChange}
                                placeholder="Select Client Group"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                    {/* Tags Selector */}
                    {this.state.clientTypeFilter?.name === "Tags" && (
                      <>
                        {this.state.tagLoading ? (
                          <div className="sm:border-t sm:border-gray-200 sm:pt-5">
                            <Icon
                              name="loading"
                              className="text-indigo-600 mx-auto"
                            />
                          </div>
                        ) : (
                          <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                            <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2">
                              Tags
                            </label>
                            <div className="mt-1 sm:col-span-2 sm:mt-0 w-full sm:max-w-xs">
                              <MultiSelect
                                items={(this.props as any)?.tags?.map(
                                  (item: any) => {
                                    return {
                                      ...item,
                                      _id: item._id,
                                      name: item.name
                                    };
                                  }
                                )}
                                selected={{
                                  name: this.state.tagsFilterSelect?.name
                                }}
                                type="filterClients"
                                onChange={this.onTagsFilterChange}
                                placeholder="Select Tags"
                              />
                            </div>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                )}
              </fieldset>
            </div>
          </div>

          <div className="space-y-6 sm:divide-y-2 divide-gray-200 mt-4">
            {/* Type fo Contact person To message */}
            {(this.state.selectedTaskType._id ||
              (this.state.showClientFilters &&
                (this.state.clientTypeFilter?.name === "Only Other Client" ||
                  this.state.clientTypeFilter?.name === "All" ||
                  (this.state.gstFilterSelect?.name &&
                    this.state.gstFilterSelect?.name !==
                      "GST Registration State" &&
                    this.state.gstFilterSelect?.name !== "GSTIN Status") ||
                  this.state.filterGstStatus ||
                  this.state.registrationStateFilterSelect?.name ||
                  this.state.filterGstStatus ||
                  (this.state.itrFileReturnSelect?.name &&
                    this.state.itrFileReturnSelect?.name !==
                      "Returns You File In IT") ||
                  this.state.itrFileReturnSelect?.name ||
                  this.state.ownershipFilterSelect?.name ||
                  this.state.clientGroupFilterSelect?.name ||
                  this.state.tagsFilterSelect?.name))) && (
              <div
                className={`grid sm:grid-cols-3 sm:items-center gap-4 sm:pt-4 ${
                  !this.state.showClientFilters &&
                  "sm:border-t-2 border-t-gray-200"
                }`}
              >
                <label className="text-sm font-medium text-gray-700">
                  Select Contact Person
                </label>
                <fieldset className="col-span-2">
                  <legend className="sr-only">Select Contact Person</legend>
                  <div className="grid grid-cols-3 gap-4 items-center">
                    {contactPersonTypeToMessage.map((item: any) => (
                      <div key={item} className="flex items-center gap-3">
                        <input
                          id={item}
                          name="contactPersonTypeToMessage"
                          type="radio"
                          value={item}
                          checked={
                            this.state.contactPersonTypeToMessage === item
                          }
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                          onChange={this.onContactPersonTypeToMessageChange}
                        />
                        <label
                          htmlFor={item}
                          className="text-sm font-medium text-gray-700 cursor-pointer"
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            )}

            {/* Type of Message */}
            {this.state.contactPersonTypeToMessage && (
              <div className="grid sm:grid-cols-3 sm:items-center gap-4 sm:pt-4">
                <label className="text-sm font-medium text-gray-700">
                  Select Message Type
                </label>
                <fieldset className="col-span-2">
                  <legend className="sr-only">Select Message Type</legend>
                  <div className="grid grid-cols-3 gap-4 items-center">
                    {messageType.map((item: any) => (
                      <div key={item} className="flex items-center gap-3">
                        <input
                          id={item}
                          name="messageType"
                          type="radio"
                          value={item}
                          checked={this.state.messageType === item}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                          onChange={this.onMessageTypeChange}
                        />
                        <label
                          htmlFor={item}
                          className="text-sm font-medium text-gray-700 cursor-pointer"
                        >
                          {item}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            )}

            {/* Message to */}

            {this.state.messageType && (
              <div className="grid sm:grid-cols-3 sm:items-center gap-4 sm:pt-4">
                <label className="text-sm font-medium text-gray-700">
                  Send {this.state.messageType} To
                </label>
                <fieldset className="col-span-2">
                  <legend className="sr-only">
                    Send {this.state.messageType} To
                  </legend>
                  <div className="grid grid-cols-3 gap-4 items-center">
                    {messageTo.map((item: any, index) => (
                      <div key={item} className="flex items-center gap-3">
                        <input
                          id={`${item} ${this.state.messageType}`}
                          name="messageTo"
                          type="radio"
                          value={item}
                          checked={this.state.messageTo === item}
                          className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                          onChange={this.onMessageToChange}
                        />
                        <label
                          htmlFor={`${item} ${this.state.messageType}`}
                          className="text-sm font-medium text-gray-700 cursor-pointer"
                        >
                          {item}{" "}
                          {index === 0 &&
                            (this.state.messageType === "SMS"
                              ? "Number"
                              : this.state.messageType)}
                        </label>
                      </div>
                    ))}
                  </div>
                </fieldset>
              </div>
            )}

            {/* Message Content*/}
            {this.state.messageTo && (
              <div className="space-y-4 sm:pt-4">
                <h2 className="text-base font-semibold text-gray-900">
                  Message Content
                </h2>
                <div className="space-y-4 sm:divide-y-2 divide-gray-200">
                  <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4">
                    <label className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2 capitalize">
                      {this.state.messageType}
                    </label>
                    <div className="mt-1 sm:col-span-2 sm:mt-0 w-full">
                      {this.state.messageType.toLowerCase() === "email" ? (
                        <>
                          <textarea
                            id="emailSubject"
                            name="emailSubject"
                            rows={1}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md mb-4"
                            placeholder="Enter Email Subject"
                            value={this.state.selectedTemplate.emailSubject}
                            onChange={this.onEmailSubjectChange}
                          />
                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            value={this.state.selectedTemplate.emailHtml}
                            placeholder="Enter Message Content or Select Template"
                            onChange={this.handleEmailHtmlChange}
                            className="h-[8rem] mb-20 lg:mb-12"
                          />
                        </>
                      ) : (
                        <div className="flex flex-col gap-2">
                          <textarea
                            id="message"
                            name="message"
                            rows={5}
                            className="shadow-sm focus:ring-indigo-500 focus:border-indigo-500 mt-1 block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Enter Message Content or Select Template"
                            value={this.state.selectedTemplate.template}
                            onChange={this.onMessageChange}
                          />
                          {this.state.messageType === "SMS" && (
                            <div
                              className={`text-xs ${
                                this.state.selectedTemplate.template.length > 0
                                  ? "visible"
                                  : "invisible"
                              }`}
                            >
                              <span className="text-gray-600">
                                {this.state.selectedTemplate.template.length}/
                                {this.state.maxCharCount *
                                  Math.ceil(
                                    this.state.selectedTemplate.template
                                      .length / this.state.maxCharCount
                                  )}
                              </span>{" "}
                              <span className="text-gray-800">
                                Count as{" "}
                                {Math.ceil(
                                  this.state.selectedTemplate.template.length /
                                    this.state.maxCharCount
                                )}{" "}
                                SMS ({this.state.maxCharCount} is character
                                limit per sms)
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                    <div className="sm:col-span-3 lg:col-start-2 lg:col-end-4 flex justify-between gap-6 mt-4">
                      <Button
                        name="Add Template"
                        icon="add"
                        onClick={this.openAddTemplateModal}
                      />
                      <Button
                        name={`Select ${this.state.messageType} Template`}
                        icon={CursorArrowRippleIcon}
                        onClick={this.showTemplateModal}
                      />
                      <Button
                        name={
                          this.state.messageType.toLowerCase() === "email"
                            ? "Copy Email"
                            : "Copy Message"
                        }
                        icon={ClipboardDocumentIcon}
                        onClick={
                          this.state.messageType.toLowerCase() === "email"
                            ? this.copyEmail
                            : this.copyMessage
                        }
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {/* Message Template Modal */}
            {this.state.showTemplateModal && (
              <TemplateSelectorModal
                showModal={this.state.showTemplateModal}
                messageType={this.state.messageType}
                closeModal={this.hideTemplateModal}
                selectTemplate={this.selectTemplate}
                maxCharCount={this.state.maxCharCount}
              />
            )}
          </div>

          {/* Client Listing */}
          {this.state.messageTo && (
            <>
              <div className="space-y-4 mt-6">
                {/* Client Search */}
                <div className="w-80 mx-auto">
                  <input
                    id="search"
                    name="search"
                    type="search"
                    value={this.state.searchText}
                    placeholder="Search by File No, Client or Trade Name"
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:border-indigo-500 text-xs sm:text-sm"
                    onChange={this.handleSearch}
                  />
                </div>
                <div className="flex gap-3 items-center justify-between">
                  <div className="flex gap-4">
                    <div className="flex gap-3 items-center">
                      <input
                        id="showAllClient"
                        type="radio"
                        name="showClients"
                        value="all"
                        checked={this.state.showClients === "all"}
                        onChange={this.handleShowClient}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                      />
                      <label
                        htmlFor="showAllClient"
                        className="text-sm font-medium text-gray-700"
                      >
                        Show All Clients
                      </label>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        id="showClientWithContact"
                        type="radio"
                        name="showClients"
                        value="withContact"
                        checked={this.state.showClients === "withContact"}
                        onChange={this.handleShowClient}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                      />
                      <label
                        htmlFor="showClientWithContact"
                        className="text-sm font-medium text-gray-700"
                      >
                        Show Clients with Contact
                      </label>
                    </div>
                    <div className="flex gap-3 items-center">
                      <input
                        id="showClientWithoutContact"
                        type="radio"
                        name="showClients"
                        value="withoutContact"
                        checked={this.state.showClients === "withoutContact"}
                        onChange={this.handleShowClient}
                        className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-400 cursor-pointer"
                      />
                      <label
                        htmlFor="showClientWithoutContact"
                        className="text-sm font-medium text-gray-700"
                      >
                        Show Clients without Contact
                      </label>
                    </div>
                  </div>
                  {/* <Button
                    name="Export"
                    icon="outline/download"
                    // onClick={() => this.handleExport()}
                    disabled={this.state.selectedClients.length === 0}
                  /> */}
                </div>
                {/* Client List Table */}
                <div id="table-scroll" className="overflow-auto">
                  <div className="inline-block min-w-full py-2 px-1 align-middle">
                    <div className="shadow-sm ring-1 ring-black ring-opacity-5">
                      <table className="min-w-full border-collapse border shadow-sm">
                        <thead className="bg-gray-50">
                          <tr>
                            {this.state.clientTableHeader.map(
                              (item: any, index) =>
                                item === "checkbox" ? (
                                  <th
                                    key={`client-table-header-${index}-${item}`}
                                    scope="col"
                                    className="border-b border-gray-300 bg-gray-50 px-4 py-3 text-left text-xs font-bold text-gray-500 uppercase tracking-wider"
                                  >
                                    <div className="flex items-center gap-4">
                                      <div>
                                        <input
                                          type="checkbox"
                                          className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                                          checked={
                                            this.state.clients.length > 0 &&
                                            this.state.clients.length ===
                                              this.state.selectedClients.length
                                          }
                                          onChange={this.onSelectAllClients}
                                        />
                                      </div>
                                    </div>
                                  </th>
                                ) : (
                                  <th
                                    key={`client-table-header-${index}-${item}`}
                                    scope="col"
                                    className={`border-b whitespace-nowrap border-gray-300 bg-gray-50 px-4 py-3 text-xs font-bold text-gray-500 uppercase tracking-wider ${
                                      item
                                        .toLowerCase()
                                        .includes("number of contacts")
                                        ? "text-center"
                                        : "text-left"
                                    }`}
                                  >
                                    {item}
                                  </th>
                                )
                            )}
                          </tr>
                        </thead>
                        <tbody className="bg-white">
                          {this.state.displayClients.length > 0 ? (
                            this.state.displayClients?.map(
                              (client: any, index: any) => (
                                <tr
                                  key={client._id}
                                  className={`${
                                    index % 2 === 0 ? "bg-white" : "bg-gray-100"
                                  } ${
                                    client.contactPerson.length === 0 ||
                                    client.contactPersonDetails.length === 0
                                      ? "!bg-red-100 border-b border-b-red-400"
                                      : ""
                                  }`}
                                >
                                  {this.state.clientTableHeader.map(
                                    (item: any, index) => {
                                      const primaryContactPerson =
                                        client.contactPerson.find(
                                          (person: any) => person.isPrimary
                                        )?.name;

                                      return item === "checkbox" ? (
                                        <td className="whitespace-nowrap py-3 px-4 text-sm font-bold text-gray-900">
                                          <div className="flex items-center gap-3">
                                            <input
                                              type="checkbox"
                                              className="h-4 w-4 rounded border-gray-400 text-indigo-600 focus:ring-indigo-500"
                                              checked={this.state.selectedClients.some(
                                                (item: any) =>
                                                  item._id === client._id
                                              )}
                                              onChange={() =>
                                                this.onSelectClient(client)
                                              }
                                            />
                                          </div>
                                        </td>
                                      ) : item
                                          .toLowerCase()
                                          .includes("client name") ? (
                                        <td className="max-w-[2rem] px-4 py-3 text-sm font-bold text-gray-900 relative">
                                          <Popup
                                            className="w-full max-w-fit cursor-default"
                                            content={`${client.name} (${client.tradeName})`}
                                          >
                                            <p className="truncate">
                                              {client.name}{" "}
                                              {client.tradeName &&
                                                `(${client.tradeName})`}
                                            </p>
                                          </Popup>
                                        </td>
                                      ) : item
                                          .toLowerCase()
                                          .includes("number of contacts") ? (
                                        <td className="px-4 py-3 text-sm font-bold text-gray-900 text-center">
                                          {client.contactPersonDetails.length}
                                        </td>
                                      ) : item
                                          .toLowerCase()
                                          .includes(
                                            "primary contact person"
                                          ) ? (
                                        this.state.contactPersonTypeToMessage
                                          .toLowerCase()
                                          .includes("primary") && (
                                          <td className="px-4 py-3 text-sm font-bold text-gray-900">
                                            <Popup
                                              className="w-full max-w-fit cursor-default"
                                              content={primaryContactPerson}
                                            >
                                              <p className="truncate">
                                                {primaryContactPerson || "-"}
                                              </p>
                                            </Popup>
                                          </td>
                                        )
                                      ) : (
                                        item.toLowerCase().includes("status") &&
                                        this.state.selectedReturnTask?.name &&
                                        this.state.selectedPeriod?.name && (
                                          <td className="px-4 py-3 text-sm font-bold text-gray-900">
                                            {client.taskPresent ? (
                                              <div className="w-32">
                                                <TableMultiSelect
                                                  items={this.state.statusList}
                                                  type="task-status"
                                                  selected={
                                                    this.state.statusList
                                                      .filter(
                                                        (item: any) =>
                                                          item._id ===
                                                          client.statusId
                                                      )
                                                      .map((item: any) => ({
                                                        name: item.name,
                                                        color: item.color
                                                      }))[0]
                                                  }
                                                  disabled={true}
                                                  onChange={(value: any) =>
                                                    null
                                                  }
                                                  placeholder="Select New Status"
                                                />
                                              </div>
                                            ) : (
                                              "No Task Created"
                                            )}
                                          </td>
                                        )
                                      );
                                    }
                                  )}
                                </tr>
                              )
                            )
                          ) : (
                            <tr>
                              <td
                                colSpan={this.state.clientTableHeader.length}
                                className="w-full px-4 py-3 text-sm font-bold text-gray-900 text-center"
                              >
                                No Clients Found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
                <Pagination
                  displayRecords={this.state.displayClients}
                  totalRecords={this.state.totalRecords}
                  currPage={this.state.currPage}
                  chunkSize={this.state.chunkSize}
                  handlePageClick={this.handlePageClick}
                  handleItemPerPage={this.handleItemPerPage}
                />
              </div>
              <div className="text-sm flex items-center justify-between gap-4 mt-4">
                <div>
                  Total Selected Clients - {this.state.selectedClients.length}{" "}
                  {this.state.clientWithoutContacts.length > 0 &&
                    `(${this.state.clientWithoutContacts.length} without contacts)`}{" "}
                  {this.state.selectedClientsContacts.length -
                    this.state.uniqueSelectedClientsContacts.length >
                    0 &&
                    `(Ommitted ${
                      this.state.selectedClientsContacts.length -
                      this.state.uniqueSelectedClientsContacts.length
                    } Duplicate Contacts)`}
                </div>
                {this.state.selectedClients.length > 0 &&
                  (this.state.selectedClientsContacts.length > 0 ? (
                    <Button
                      name={
                        this.state.messageType === "Email"
                          ? `Send ${this.state.uniqueSelectedClientsContacts.length} Email`
                          : this.state.messageType === "SMS"
                          ? `Copy ${this.state.uniqueSelectedClientsContacts.length} Mobile Number`
                          : this.state.messageType === "WhatsApp"
                          ? `Copy ${this.state.uniqueSelectedClientsContacts.length} WhatsApp Number`
                          : ""
                      }
                      onClick={
                        this.state.messageType === "Email"
                          ? this.sendEmails
                          : this.copyNumbers
                      }
                    />
                  ) : (
                    <Button
                      name={
                        this.state.messageType === "Email"
                          ? `No ${
                              this.state.messageTo !== "All"
                                ? this.state.messageTo
                                : ""
                            } Emails in Selected Clients`
                          : this.state.messageType === "SMS"
                          ? `No ${
                              this.state.messageTo !== "All"
                                ? this.state.messageTo
                                : ""
                            } Mobile Numbers in Selected Clients`
                          : this.state.messageType === "WhatsApp"
                          ? `No ${
                              this.state.messageTo !== "All"
                                ? this.state.messageTo
                                : ""
                            } WhatsApp Numbers in Selected Clients`
                          : ""
                      }
                      disabled={true}
                    />
                  ))}
              </div>
            </>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(BulkMessages) as React.ComponentType;
