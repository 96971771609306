import { connect, ConnectedProps } from "react-redux";
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import { withRouter, WithRouterProps } from "../../helpers/withRouter";
import { compose } from "redux";
import { Component, ComponentType } from "react";
import Skeleton from "react-loading-skeleton";
import agent from "../../agent";
import Dashboard, { getUserRights } from "../../components/Dashboard";
import Icon from "../../components/Icon";
import MultiSelect from "../../components/MultiSelect";
import { getTaskPeriod } from "../../helpers/taskPeriod";
import { createSearchParams } from "react-router-dom";
import DueDaysReport from "./DueDaysReport";
import StatusWiseAndTaskTypeWiseReport from "./StatusWiseAndTaskTypeWiseReport";
import UserwiseTaskSummary from "./UserwiseTaskSummary";
import StatuswiseTaskSummary from "./StatuswiseTaskSummary";
import TaskWiseUserWIseReport from "./TaskWiseUserWIseReport";
import { formatDateTimeString } from "../../helpers/formatDate";
import TagManager from "react-gtm-module";
import MessageModal, { MessageModalType } from "../../components/MessageModal";
import { Link } from "react-router-dom";
import {
  monthTillLastMonth,
  quarterTillLastQuarter,
  yearTillLastYear
} from "../../helpers/timePeriod";
import { TodoCountCards, TodoCountTable } from "./TodoListNumbers";
import { getTodoCount, getTodoList } from "../Todo/Index";
import Button from "../../components/Button";
import { AppDispatch, RootState } from "../../store";
import { CommonAction } from "../../store/reducers/common";
import { NotifyType } from "../../store/reducers/notification";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Dashboard"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {}

export const isValidFirmId = (firmId: string | undefined) => {
  return firmId && firmId.length === 24 && firmId !== "undefined"
    ? firmId
    : undefined;
};

export type UserRightsFilter = "admin" | "manager" | "employee";
export type UserTypeFilter = "currentUser" | "otherUser" | "any";

const tabs = ["Common", "GST", "Income Tax", "Other", "Todo"] as const;
export type CurrentTab = (typeof tabs)[number];

interface State {
  userRightsFilter: UserRightsFilter;
  userTypeFilter: UserTypeFilter;
  refreshedAt: Date;
  loading: boolean;
  dueDaysReport: any;
  fetchingTaskBasedOnDueDays: boolean;
  taskRecordsBasedOnDueDays: any;
  taskRecordsBasedOnDueDaysLength: number;
  taskWiseAndPeriodWiseReport: any;
  userWiseReport: any;
  displayUserWiseReport: any;
  statusWiseTaskReport: any;
  statusWiseAndTaskTypeWiseReport: any;
  assignedTasks: any;
  currentTab: CurrentTab;
  showDueDaysReport: string;
  allReturnTaskTypes: any;
  returnTaskTypes: any;
  selectedReturnTaskTypes: any;
  period: any;
  selectedPeriod: any;
  selectedUser: any;
  selectedTask: any;
  selectedTaskPeriod: any;
  showStatusWiseUserWiseReport: boolean;
  statusWiseAndUserWiseReport: any;
  selectedTableStatus: any;
  chunkSize: number;
  showStatusChangeModal: boolean;
  statusChangeModalData: {
    title: string;
    message: string;
    otherData: string;
    type: MessageModalType;
  };
  userRightsFilterList: {
    _id: string;
    name: string;
  }[];
  userType: { _id: string; name: string };
}

class Index extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      userRightsFilter: this.props?.rights?.role || "employee",
      userTypeFilter: "any",
      refreshedAt: new Date(),
      loading: false,
      dueDaysReport: [],
      fetchingTaskBasedOnDueDays: false,
      taskRecordsBasedOnDueDays: [],
      taskRecordsBasedOnDueDaysLength: 0,
      taskWiseAndPeriodWiseReport: [],
      userWiseReport: [],
      displayUserWiseReport: [],
      statusWiseTaskReport: [],
      statusWiseAndTaskTypeWiseReport: [],
      assignedTasks: [],
      currentTab: tabs[0],
      showDueDaysReport: "",
      allReturnTaskTypes: [],
      returnTaskTypes: [],
      selectedReturnTaskTypes: null,
      period: [],
      selectedPeriod: null,
      selectedUser: { _id: "all", name: "all" },
      showStatusWiseUserWiseReport: false,
      selectedTask: null,
      selectedTaskPeriod: null,
      statusWiseAndUserWiseReport: [],
      selectedTableStatus: { taskId: null, status: null },
      chunkSize: 5,
      showStatusChangeModal: false,
      statusChangeModalData: {
        title: "",
        message: "",
        otherData: "",
        type: "success"
      },
      userRightsFilterList: [
        ...(this.props?.rights?.role === "admin"
          ? [{ _id: "admin", name: "admin" }]
          : []),
        ...(this.props?.rights?.role === "admin" ||
        this.props?.rights?.role === "manager"
          ? [{ _id: "manager", name: "manager" }]
          : []),
        { _id: "employee", name: "user" }
      ],
      userType: { _id: "current", name: "Working" }
    };
  }

  userTypeFilter = [
    { _id: "currentUser", name: "Working User" },
    { _id: "otherUser", name: "Other User" },
    { _id: "any", name: "Any where" }
  ];

  handleTabChange = (index: number) => {
    this.setState({ currentTab: tabs[index] });
  };

  openAddTodoModal = () => {
    this.props.updateCommon?.({
      currentModal: { modalName: "ADD_TODO_MODAL", fetchAgain: false }
    });
  };

  getTaskTypes = () => {
    const taskType =
      this.state.currentTab.toLowerCase() === "income tax"
        ? "IT"
        : this.state.currentTab.toLowerCase() === "gst"
        ? "GST"
        : this.state.currentTab.toLowerCase() === "other"
        ? "OTHER"
        : "";

    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    if (!workSpaceId) return;

    this.setState({ loading: true });

    agent.RecurringTask.getTaskTypes(workSpaceId, taskType, "")
      .then((res: any) => {
        this.setState({
          allReturnTaskTypes: res.tasks,
          returnTaskTypes: res.tasks,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Error",
          "Error getting task types",
          "danger"
        );
      });
  };

  onPeriodChange = (item: any) => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    this.setState({ selectedPeriod: item });

    localStorage.setItem(
      `selectedPeriod-${workSpaceId}-${this.state.currentTab}`,
      JSON.stringify(item ?? {})
    );
  };

  handleUserRightsFilterChange = (selected: any) => {
    this.setState({ userRightsFilter: selected._id });
  };

  handleUserTypeFilterChange = (selected: any) => {
    this.setState({ userTypeFilter: selected._id });
  };

  handleUserChange = (item: any) => {
    this.setState({ selectedUser: item });
  };

  handleUserTypeChange = (item: any) => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    this.setState({ userType: item });
    localStorage.setItem(
      `selectedUserTypeUserwise-${workSpaceId}`,
      JSON.stringify(item)
    );
  };

  onReturnTypeChange = (item: any) => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    const startPeriod = item?.startPeriod ? item.startPeriod : "";
    const endPeriod = item?.endPeriod ? item.endPeriod : "";
    const period = getTaskPeriod(
      item.periodType ?? item.period,
      startPeriod,
      endPeriod
    );
    this.setState({
      selectedReturnTaskTypes: item,
      period,
      selectedPeriod: null
    });
    localStorage.setItem(
      `selectedReturnTaskTypes-${workSpaceId}-${this.state.currentTab}`,
      JSON.stringify(item)
    );
  };

  changeTaskStatus = (task: any, status: any, prevStatusName: string) => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    if (!workSpaceId) {
      this.props.addNotification?.("Error", "Workspace id not found", "danger");
      return;
    }

    const {
      _id: taskId,
      name: taskName,
      type: taskType,
      period: taskPeriod
    } = task;
    const { name: statusName, _id: statusId } = status;

    // if (
    //   taskType === "GST" &&
    //   taskName !== "PMT-06" &&
    //   taskName !== "GSTR-4" &&
    //   prevStatusName.toLowerCase() === "completed"
    // ) {
    //   (this.props ).addNotification(
    //     "Could not change task status",
    //     "You cannot change the status of GST task once it is marked as completed",
    //     "danger"
    //   );
    // } else {
    agent.Tasks.changeStatus(workSpaceId, [taskId], statusId)
      .then((response: any) => {
        const tasksNotCompletedIds = response?.tasksNotCompletedIds;
        if (
          tasksNotCompletedIds?.length > 0 &&
          task.type === "GST" &&
          taskName !== "PMT-06" &&
          status?.name.toLowerCase() === "completed"
        ) {
          this.setState({
            showStatusChangeModal: true,
            statusChangeModalData: {
              title: "Return not filed",
              message: `Task ${taskName} (${taskPeriod}) could not be changed to ${statusName} as return is not filed on GSTIN server.`,
              otherData: "",
              type: "error"
            }
          });
        } else if (
          tasksNotCompletedIds?.length === 0 &&
          taskType === "GST" &&
          taskName !== "PMT-06" &&
          statusName.toLowerCase() === "completed"
        ) {
          this.setState({
            showStatusChangeModal: true,
            statusChangeModalData: {
              title: "Return filed successfully",
              message:
                taskName === "GSTR-4"
                  ? `We have marked ${taskName} (${taskPeriod}) as ${statusName} but we cannot confirm the filing on GSTN Servers as ${taskName} filing details are not shared by GSTN Servers`
                  : `Task ${taskName} (${taskPeriod}) has been changed to ${statusName} as return filing is successfully confirmed from GSTN server.`,
              otherData:
                taskName === "GSTR-4"
                  ? ""
                  : `ARN - ${response?.gstArn}\nDate of filing - ${response.gstDateOfFiling[0]}`,
              type: "success"
            }
          });
        } else if (task.type !== "GST") {
          this.props.addNotification?.(
            "Task status changed successfully",
            "All selected tasks status changed successfully.",
            "success"
          );
        }
        this.getTaskRecordsBasedOnDueDays();
        this.getStatusWiseTaskReport();
        this.setState({
          selectedTableStatus: { taskId: null, status: null }
        });
      })
      .catch((err: any) => {
        this.props.addNotification?.(
          "Could not change task status",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
    // }
  };

  onTableStatusChange =
    (task: any, prevStatusName: string) => (status: any) => {
      this.setState({ selectedTableStatus: { taskId: task._id, status } });
      this.changeTaskStatus(task, status, prevStatusName);
    };

  setShowDueDaysReport = (heading: string) => {
    this.setState({
      showDueDaysReport: this.state.showDueDaysReport === heading ? "" : heading
    });
  };

  setShowStatusWiseUserWiseReportModalOpen = (
    showStatusWiseUserWiseReport: boolean
  ) => {
    this.setState({ showStatusWiseUserWiseReport });
  };

  setStatusWiseUserWiseReport = (
    selectedTask: string,
    selectedTaskPeriod: string
  ) => {
    this.setState({ selectedTask, selectedTaskPeriod });
  };

  getDueDaysReport = () => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    if (!workSpaceId) return;

    this.setState({ loading: true });
    agent.dashboard
      .getDueDaysReport(
        workSpaceId,
        this.state.currentTab === "Income Tax"
          ? "IT"
          : this.state.currentTab === "Common"
          ? "all"
          : this.state.currentTab.toUpperCase(),
        this.state.selectedUser._id,
        this.state.userTypeFilter,
        this.state.userRightsFilter
      )
      .then((res: any) => {
        this.setState({ dueDaysReport: res, loading: false });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Error",
          "Error getting due days report",
          "danger"
        );
      });
  };

  getTaskRecordsBasedOnDueDays = () => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    if (!workSpaceId) return;

    this.setState({ fetchingTaskBasedOnDueDays: true });
    agent.dashboard
      .getTaskBasedOnDueDays(
        workSpaceId,
        this.state.currentTab === "Income Tax"
          ? "IT"
          : this.state.currentTab === "Common"
          ? "all"
          : this.state.currentTab.toUpperCase(),
        this.state.selectedUser._id,
        this.state.userTypeFilter,
        this.state.userRightsFilter,
        this.state.showDueDaysReport
      )
      .then((res: any) => {
        this.setState({
          taskRecordsBasedOnDueDaysLength: res.length,
          taskRecordsBasedOnDueDays: res.slice(0, this.state.chunkSize),
          fetchingTaskBasedOnDueDays: false
        });
      })
      .catch((err: any) => {
        this.setState({ fetchingTaskBasedOnDueDays: false });
        this.props.addNotification?.(
          "Error",
          "Error getting task records based on due days",
          "danger"
        );
      });
  };

  getStatusWiseAndTaskTypeWiseReport = () => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    if (!workSpaceId) return;

    this.setState({ loading: true });
    agent.dashboard
      .statuswiseAndTaskwiseReport(
        workSpaceId,
        this.state.currentTab === "Income Tax"
          ? "IT"
          : this.state.currentTab === "Common"
          ? "all"
          : this.state.currentTab.toUpperCase(),
        this.state.selectedUser._id,
        this.state.userTypeFilter,
        this.state.userRightsFilter,
        this.state.selectedReturnTaskTypes?.name,
        this.state.selectedPeriod?.name
      )
      .then((res: any) => {
        this.setState({ statusWiseAndTaskTypeWiseReport: res, loading: false });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Error",
          "Error getting status wise and task type wise report",
          "danger"
        );
      });
  };

  getStatusWiseTaskReport = () => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    if (!workSpaceId) return;

    this.setState({ loading: true });
    agent.dashboard
      .statuswiseReport(
        workSpaceId,
        this.state.currentTab === "Income Tax"
          ? "IT"
          : this.state.currentTab === "Common"
          ? "all"
          : this.state.currentTab.toUpperCase(),
        this.state.userRightsFilter === "admin"
          ? this.state.selectedUser._id
          : "all",
        this.state.userTypeFilter,
        this.state.userRightsFilter
      )
      .then((res: any) => {
        this.setState({ statusWiseTaskReport: res, loading: false });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Error",
          "Error getting status wise report",
          "danger"
        );
      });
  };

  getUserWiseReport = () => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    if (!workSpaceId) return;

    this.setState({ loading: true });
    agent.dashboard
      .userwiseReport(
        workSpaceId,
        this.state.currentTab === "Income Tax"
          ? "IT"
          : this.state.currentTab === "Common"
          ? "all"
          : this.state.currentTab.toUpperCase(),
        this.state.userType._id
      )
      .then((res: any) => {
        this.setState({
          userWiseReport: res,
          displayUserWiseReport: res.slice(0, this.state.chunkSize),
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Error",
          "Error getting user wise report",
          "danger"
        );
      });
  };

  getAssignedTasks = () => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    if (!workSpaceId) return;

    this.setState({ loading: true });
    agent.dashboard
      .assignedTaskReport(
        workSpaceId,
        this.state.currentTab === "Income Tax"
          ? "IT"
          : this.state.currentTab === "Common"
          ? "all"
          : this.state.currentTab.toUpperCase()
      )
      .then((res: any) => {
        this.setState({ assignedTasks: res.tasks, loading: false });
      })
      .catch((err: any) => {
        this.props.addNotification?.(
          "Error",
          "Error getting assigned tasks",
          "danger"
        );
        this.setState({ loading: false });
      });
  };

  getTaskWiseAndPeriodWiseReport = () => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    if (!workSpaceId) return;

    this.setState({ loading: true });
    agent.dashboard
      .taskwiseAndPeriodwiseReport(
        workSpaceId,
        this.state.currentTab === "Income Tax"
          ? "IT"
          : this.state.currentTab === "Common"
          ? "all"
          : this.state.currentTab.toUpperCase(),
        this.state.selectedUser._id,
        this.state.userTypeFilter,
        this.state.userRightsFilter
      )
      .then((res: any) => {
        this.setState({
          taskWiseAndPeriodWiseReport: res.tasks,
          loading: false
        });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Error",
          "Error getting task wise and period wise report",
          "danger"
        );
      });
  };

  getStatusWiseAndUserWiseReport = () => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    if (!workSpaceId) return;

    this.setState({ loading: true });
    agent.dashboard
      .statuswiseAndUserwiseReport(
        workSpaceId,
        this.state.currentTab === "Income Tax"
          ? "IT"
          : this.state.currentTab === "Common"
          ? "all"
          : this.state.currentTab.toUpperCase(),
        this.state.selectedUser._id,
        this.state.userTypeFilter,
        this.state.userRightsFilter,
        this.state.selectedTask,
        this.state.selectedTaskPeriod
      )
      .then((res: any) => {
        this.setState({ statusWiseAndUserWiseReport: res, loading: false });
      })
      .catch((err: any) => {
        this.setState({ loading: false });
        this.props.addNotification?.(
          "Error",
          "Error getting status wise and user wise report",
          "danger"
        );
      });
  };

  refresh = () => {
    this.setState({ refreshedAt: new Date() });

    if (this.state.currentTab === "Todo") return this.getTodoDetails();

    this.getDueDaysReport();
    this.state.showDueDaysReport && this.getTaskRecordsBasedOnDueDays();
    this.state.selectedReturnTaskTypes &&
      this.state.selectedPeriod &&
      this.getStatusWiseAndTaskTypeWiseReport();
    this.getTaskWiseAndPeriodWiseReport();
    this.getStatusWiseAndUserWiseReport();
    this.getStatusWiseTaskReport();

    if (this.state.userRightsFilter === "admin") {
      this.getUserWiseReport();
      this.getAssignedTasks();
    }
  };

  getTodoDetails = () => {
    const currFirmId = this.props.params?.firmId || this.props.currentFirm?._id;

    getTodoList(currFirmId, (loading: boolean) => this.setState({ loading }));
    getTodoCount(currFirmId, (loading: boolean) => this.setState({ loading }));
  };

  getDueDateSearchParams = () => {
    return createSearchParams({
      dueDate:
        this.state?.showDueDaysReport === "dueToday"
          ? "0"
          : this.state?.showDueDaysReport === "dueTomorrow"
          ? "1"
          : this.state?.showDueDaysReport === "dueThisWeek"
          ? "7"
          : this.state?.showDueDaysReport === "overdueupto7days"
          ? "overdueupto7days"
          : this.state?.showDueDaysReport === "overduemorethan7days"
          ? "overduemorethan7days"
          : "0"
    });
  };

  searchParameters = (key: string, filter?: string) => {
    switch (key) {
      case "type":
        filter = filter === "Income Tax" ? "IT" : filter?.toUpperCase();
        return createSearchParams({
          type: filter ? [filter!] : []
        });
      case "dueDate":
        return this.getDueDateSearchParams();
      case "status":
        return createSearchParams({
          statusIds: [
            this.props?.status?.filter((s: any) => s.name === filter)[0]?._id
          ]
        });
      case "name":
        return createSearchParams({
          name: [filter!]
        });
      case "periodType":
        return createSearchParams({
          periodType: [filter!]
        });
      case "period":
        return createSearchParams({
          period: [filter!]
        });
      case "otherPeriod":
        return createSearchParams({
          period:
            filter === "monthly"
              ? monthTillLastMonth().reverse().slice(3).join(", ")
              : filter === "quarterly"
              ? quarterTillLastQuarter().reverse().slice(3).join(", ")
              : filter === "yearly"
              ? yearTillLastYear().reverse().slice(3).join(", ")
              : []
        });
      case "userName":
        return filter === "all" || filter === "Unassigned"
          ? ""
          : createSearchParams({
              userName: [filter!]
            });
      case "user":
        return filter === "all"
          ? ""
          : filter === "Unassigned"
          ? createSearchParams({
              user: ["Unassigned"]
            })
          : filter === "assigned"
          ? createSearchParams({
              user: filter!
            })
          : createSearchParams({
              user: filter!
            });
      case "otherUser":
        return filter === "all" || filter === "Unassigned"
          ? ""
          : createSearchParams({
              otherUser: filter!
            });
      default:
        return createSearchParams({});
    }
  };

  setUserSelectionInLocalStorage = () => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    localStorage.setItem(
      `currentTab-${workSpaceId}`,
      JSON.stringify(this.state.currentTab)
    );
    localStorage.setItem(
      `selectedUserRights-${workSpaceId}-${this.state.currentTab}`,
      JSON.stringify(this.state.userRightsFilter ?? "") //to set empty string otherwise it will set to undefined
    );

    localStorage.setItem(
      `selectedUserType-${workSpaceId}-${this.state.currentTab}`,
      JSON.stringify(this.state.userTypeFilter)
    );

    localStorage.setItem(
      `selectedUserDetails-${workSpaceId}-${this.state.currentTab}`,
      JSON.stringify(this.state.selectedUser)
    );

    localStorage.setItem(
      `selectedUserTypeUserwise-${workSpaceId}`,
      JSON.stringify(this.state.userType)
    );
  };

  getUserSelectionFromLocalStorage = () => {
    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    const currentTab = JSON.parse(
      localStorage.getItem(`currentTab-${workSpaceId}`) as string
    );
    const userRightsFilter = JSON.parse(
      localStorage.getItem(
        `selectedUserRights-${workSpaceId}-${currentTab}`
      ) as string
    );
    const userTypeFilter = JSON.parse(
      localStorage.getItem(
        `selectedUserType-${workSpaceId}-${currentTab}`
      ) as string
    );
    const selectedUser = JSON.parse(
      localStorage.getItem(
        `selectedUserDetails-${workSpaceId}-${currentTab}`
      ) as string
    );

    const userType = JSON.parse(
      localStorage.getItem(`selectedUserTypeUserwise-${workSpaceId}`) as string
    );

    this.setState({
      currentTab: currentTab ? currentTab : "GST",
      userRightsFilter: userRightsFilter ? userRightsFilter : "employee",
      userTypeFilter: userTypeFilter ? userTypeFilter : "any",
      selectedUser: selectedUser ? selectedUser : { _id: "all", name: "all" },
      userType: userType ? userType : { _id: "current", name: "Working" }
    });
  };

  componentDidMount() {
    document.title = "Dashboard - TaxPido PMS";
    const firmId =
      isValidFirmId(this.props.params?.firmId) ||
      isValidFirmId(this.props.currentFirm?._id);

    if (firmId) {
      this.state.currentTab !== "Todo" &&
        getUserRights(
          this.props.params?.firmId,
          this.props.updateCommon,
          this.props.addNotification
        );
      if (this.state.userRightsFilter) {
        this.getUserSelectionFromLocalStorage();
        this.getTaskTypes();
        this.refresh();
      }
      this.getTodoDetails();
    }
  }

  componentDidUpdate(prevProps: Props, prevState: State) {
    const prevFirmId =
      isValidFirmId(prevProps.currentFirm?._id) ||
      isValidFirmId(prevProps.params?.firmId);

    const currentFirmId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    // const localUserRightsFilter = JSON.parse(
    //   localStorage.getItem(
    //     `selectedUserRights-${workSpaceId}-${this.state.currentTab}`
    //   ) as string
    // );
    const localUserTypeFilter = JSON.parse(
      localStorage.getItem(
        `selectedUserType-${currentFirmId}-${this.state.currentTab}`
      ) as string
    );

    if (
      prevFirmId !== currentFirmId &&
      currentFirmId &&
      this.state.userRightsFilter &&
      this.state.currentTab !== "Todo"
    ) {
      this.getUserSelectionFromLocalStorage();
      this.getTaskTypes();
      this.refresh();
    }

    if (
      prevProps.rights?.role !== this.props.rights?.role &&
      this.props.rights?.role
    ) {
      this.setState({
        userRightsFilter:
          /* localUserRightsFilter
          ? localUserRightsFilter
          :  */ this.props.rights?.role,

        userRightsFilterList: [
          ...(this.props?.rights?.role === "admin"
            ? [{ _id: "admin", name: "admin" }]
            : []),
          ...(this.props?.rights?.role === "admin" ||
          this.props?.rights?.role === "manager"
            ? [{ _id: "manager", name: "manager" }]
            : []),
          { _id: "employee", name: "user" }
        ]
      });
    }

    if (
      (prevState.returnTaskTypes !== this.state.returnTaskTypes ||
        prevState.userRightsFilter !== this.state.userRightsFilter) &&
      this.state.returnTaskTypes.length > 0
    ) {
      const periodType = this.state.returnTaskTypes[0]?.periodType;
      const startPeriod = this.state.returnTaskTypes[0]?.startPeriod
        ? this.state.returnTaskTypes[0].startPeriod
        : "";
      const endPeriod = this.state.returnTaskTypes[0]?.endPeriod
        ? this.state.returnTaskTypes[0].endPeriod
        : "";
      const selectedReturnTaskTypes = localStorage.getItem(
        `selectedReturnTaskTypes-${currentFirmId}-${this.state.currentTab}`
      );
      const selectedPeriod = localStorage.getItem(
        `selectedPeriod-${currentFirmId}-${this.state.currentTab}`
      );
      this.setState({
        selectedReturnTaskTypes: selectedReturnTaskTypes
          ? JSON.parse(selectedReturnTaskTypes || "")
          : this.state.returnTaskTypes[0],
        selectedPeriod: selectedPeriod
          ? JSON.parse(selectedPeriod || "")
          : getTaskPeriod(periodType, startPeriod, endPeriod)[0]
      });
    }

    if (prevFirmId !== currentFirmId && currentFirmId) {
      this.getUserSelectionFromLocalStorage();
      this.getTodoDetails();
    }

    if (
      (prevFirmId !== currentFirmId ||
        prevState.userRightsFilter !== this.state.userRightsFilter ||
        (prevState.currentTab !== this.state.currentTab &&
          this.state.currentTab)) &&
      currentFirmId &&
      this.state.userRightsFilter
    ) {
      this.getTaskTypes();
      this.refresh();
    }
    if (
      ((prevState.userTypeFilter !== this.state.userTypeFilter &&
        this.state.userTypeFilter) ||
        (prevState.selectedUser !== this.state.selectedUser &&
          this.state.selectedUser)) &&
      currentFirmId
    ) {
      this.refresh();
    }

    if (
      (prevFirmId !== currentFirmId ||
        prevState.currentTab !== this.state.currentTab ||
        prevState.userRightsFilter !== this.state.userRightsFilter ||
        prevState.userTypeFilter !== this.state.userTypeFilter ||
        prevState.selectedUser !== this.state.selectedUser) &&
      currentFirmId
    ) {
      this.setUserSelectionInLocalStorage();
    }

    if (
      prevState.userRightsFilter !== this.state.userRightsFilter &&
      this.state.userRightsFilter &&
      currentFirmId
    ) {
      if (
        this.state.userRightsFilter === "admin" ||
        this.state.userRightsFilter === "manager"
      ) {
        this.setState({
          userTypeFilter: localUserTypeFilter ? localUserTypeFilter : "any"
        });
      }
      if (this.state.userRightsFilter === "employee") {
        this.setState({
          userTypeFilter: localUserTypeFilter
            ? localUserTypeFilter
            : "currentUser"
        });
      }
    }

    if (this.state.currentTab !== "Todo") {
      if (
        prevState.showDueDaysReport !== this.state.showDueDaysReport &&
        this.state.showDueDaysReport &&
        currentFirmId
      ) {
        this.getTaskRecordsBasedOnDueDays();
      }
      if (
        prevState.selectedPeriod !== this.state.selectedPeriod &&
        this.state.selectedPeriod &&
        this.state.selectedReturnTaskTypes &&
        this.state.userRightsFilter &&
        currentFirmId
      ) {
        this.getStatusWiseAndTaskTypeWiseReport();
      }
      if (
        prevState.showStatusWiseUserWiseReport !==
          this.state.showStatusWiseUserWiseReport &&
        this.state.showStatusWiseUserWiseReport &&
        this.state.selectedTask &&
        this.state.selectedTaskPeriod &&
        currentFirmId
      ) {
        this.getStatusWiseAndUserWiseReport();
      }
      if (
        prevState.userType !== this.state.userType &&
        this.state.userType &&
        currentFirmId
      ) {
        this.getUserWiseReport();
      }
    }
  }

  onConfirm = () => this.setState({ showStatusChangeModal: false });

  onClose = () => this.setState({ showStatusChangeModal: false });

  render() {
    TagManager.dataLayer(tagManagerArgs);

    const workSpaceId =
      isValidFirmId(this.props.currentFirm?._id) ||
      isValidFirmId(this.props.params?.firmId);

    return (
      <Dashboard>
        {this.state.showStatusChangeModal && (
          <MessageModal
            show={this.state.showStatusChangeModal}
            data={this.state.statusChangeModalData}
            confirmText="Close"
            handleConfirm={this.onConfirm}
            handleClose={this.onClose}
          />
        )}

        <div className="max-w-full xs:mx-6 md:mx-8 mt-6 pb-6 mx-auto lg:mx-8 grid gap-x-3 gap-y-2 lg:grid-flow-col items-center justify-between">
          <div className="grid gap-x-3 gap-y-2 xs:grid-flow-col lg:grid-rows-2 xl:grid-rows-1 items-center">
            <h1 className="text-2xl font-semibold text-gray-900">Dashboard</h1>
            <div
              className="text-sm inline-flex gap-2 items-center cursor-pointer"
              onClick={this.refresh}
            >
              <span>
                Last Refreshed at {formatDateTimeString(this.state.refreshedAt)}
              </span>
              <Icon
                name="refresh"
                className={`w-4 h-4 text-gray-500 cursor-pointer ${
                  this.state.loading && "animate-spin"
                }`}
              />
            </div>
          </div>
          {this.state.currentTab !== "Todo" && (
            <div className="flex gap-x-3 gap-y-2 items-center lg:items-start xl:items-center lg:flex-col xl:flex-row">
              <span className="sr-only lg:not-sr-only text-sm font-medium text-gray-700 whitespace-nowrap">
                View As
              </span>
              <div className="grid gap-x-3 gap-y-2 grid-flow-col items-center">
                <div className="sm:w-36 text-sm">
                  <MultiSelect
                    placeholder="View As"
                    type="Dashboard Type"
                    items={this.state.userRightsFilterList}
                    selected={
                      this.state.userRightsFilterList.find(
                        item => item._id === this.state.userRightsFilter
                      ) || {
                        _id: this.state.userRightsFilter,
                        name: this.state.userRightsFilter
                      }
                    }
                    onChange={this.handleUserRightsFilterChange}
                  />
                </div>
                <div className="sm:w-36 text-sm">
                  <MultiSelect
                    placeholder="View As"
                    type="Dashboard Type"
                    items={this.userTypeFilter}
                    selected={
                      this.userTypeFilter.find(
                        item => item._id === this.state.userTypeFilter
                      ) || {
                        _id: this.state.userTypeFilter,
                        name: this.state.userTypeFilter
                      }
                    }
                    onChange={this.handleUserTypeFilterChange}
                  />
                </div>
                {(this.state.userRightsFilter === "admin" ||
                  this.state.userRightsFilter === "manager") && (
                  <div className="sm:w-36 text-sm">
                    <MultiSelect
                      items={[
                        { _id: "all", name: "all" },
                        ...(this.props.users || [])
                      ]?.map((item: any) => {
                        return {
                          ...item,
                          _id: item._id,
                          name: item.name
                        };
                      })}
                      selected={{ name: this.state.selectedUser?.name }}
                      type="user"
                      onChange={this.handleUserChange}
                      placeholder="Select User"
                    />
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
        <div className="max-w-full xs:mx-6 md:mx-8 mt-6 pb-6">
          <div className="px-2 xs:px-4 sm:px-6 md:px-8 bg-white rounded-md">
            <div className="block pt-2">
              <div className="border-b border-gray-200">
                <nav className="-mb-px flex space-x-8 overflow-x-hidden">
                  <ul id="tab-scroll" className="flex gap-x-4 overflow-x-auto">
                    {tabs.map((tab, index) => (
                      <li
                        key={`tab-${tab}`}
                        className={`whitespace-nowrap py-3 px-1 border-b-2 font-medium text-sm cursor-pointer ${
                          tab === this.state.currentTab
                            ? "border-indigo-500 text-indigo-600"
                            : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700"
                        }`}
                        onClick={() => this.handleTabChange(index)}
                      >
                        {tab}
                      </li>
                    ))}
                  </ul>
                </nav>
              </div>
            </div>
          </div>
          {this.state.currentTab === "Todo" ? (
            <div className="md:pl-2 pt-8 space-y-4">
              <div className="flex gap-4 items-center justify-between">
                <h2 className="text-lg font-medium leading-6 text-gray-900">
                  Your Todos
                </h2>

                <Button
                  name="Add Todo"
                  icon="add"
                  onClick={this.openAddTodoModal}
                />
              </div>
              <TodoCountCards
                loading={this.state.loading}
                firmId={
                  this.props.params?.firmId || this.props.currentFirm?._id
                }
              />
            </div>
          ) : (
            <div className="md:pl-2 pt-8 space-y-4">
              <h2 className="text-lg font-medium leading-6 text-gray-900">
                Your {this.state.currentTab} Tasks
              </h2>

              <DueDaysReport
                state={{ ...this.state, workSpaceId }}
                setShowDueDaysReport={this.setShowDueDaysReport}
                onTableStatusChange={this.onTableStatusChange}
                searchParameters={this.searchParameters}
                currentUser={this.props?.currentUser}
              />
              <div className="grid gap-4 lg:grid-cols-2">
                <StatusWiseAndTaskTypeWiseReport
                  state={{ ...this.state, workSpaceId }}
                  onReturnTypeChange={this.onReturnTypeChange}
                  onPeriodChange={this.onPeriodChange}
                  searchParams={this.searchParameters}
                  currentUser={this.props?.currentUser}
                />
                <StatuswiseTaskSummary
                  state={{ ...this.state, workSpaceId }}
                  searchParams={this.searchParameters}
                  currentUser={this.props?.currentUser}
                />
                {this.state.userRightsFilter === "admin" && (
                  <>
                    <UserwiseTaskSummary
                      state={{ ...this.state, workSpaceId }}
                      searchParams={this.searchParameters}
                      handleUserTypeChange={this.handleUserTypeChange}
                      users={this.props?.users}
                    />
                    <div className="flex flex-wrap lg:flex-col gap-2 lg:gap-x-6 w-full">
                      {!this.state.loading ? (
                        <>
                          <Link
                            to={`/${workSpaceId}/tasks/list?${this.searchParameters(
                              "user",
                              "Unassigned"
                            )}&${
                              this.state.currentTab === "Common"
                                ? ""
                                : this.searchParameters(
                                    "type",
                                    this.state.currentTab
                                  )
                            }`}
                            className="text-center rounded-lg bg-white shadow basis-28 flex-grow shrink hover:shadow-xl text-red-600 flex flex-col justify-center items-center gap-4 px-3 py-4"
                          >
                            <span className={`text-6xl font-medium capitalize`}>
                              {this.state.assignedTasks?.unassigned}
                            </span>
                            <span className="capitalize text-gray-600 font-medium">
                              Unassigned Tasks
                            </span>
                          </Link>
                          <Link
                            to={
                              this.props?.users
                                ? `/${workSpaceId}/tasks/list?${
                                    this.state.currentTab === "Common"
                                      ? ""
                                      : this.searchParameters(
                                          "type",
                                          this.state.currentTab
                                        )
                                  }&${this.searchParameters(
                                    "user",
                                    this.props.users
                                      .map((user: { _id: string }) => user._id)
                                      .join(",")
                                  )}`
                                : ""
                            }
                            className="text-center rounded-lg bg-white shadow basis-28 flex-grow shrink hover:shadow-xl text-green-600 flex flex-col justify-center items-center gap-4 px-3 py-4"
                          >
                            <span className={`text-6xl font-medium capitalize`}>
                              {this.state.assignedTasks?.assigned}
                            </span>
                            <span className="capitalize text-gray-600 font-medium">
                              assigned Tasks
                            </span>
                          </Link>
                          <Link
                            to={`/${workSpaceId}/tasks/list?${
                              this.state.currentTab === "Common"
                                ? ""
                                : this.searchParameters(
                                    "type",
                                    this.state.currentTab
                                  )
                            }`}
                            className="text-center rounded-lg bg-white shadow basis-28 flex-grow shrink hover:shadow-xl text-indigo-600 flex flex-col justify-center items-center gap-4 px-3 py-4"
                          >
                            <span className={`text-6xl font-medium capitalize`}>
                              {this.state.assignedTasks?.total}
                            </span>
                            <span className="capitalize text-gray-600 font-medium">
                              Total Tasks
                            </span>
                          </Link>
                        </>
                      ) : (
                        [...Array(3)].map((e, i) => (
                          <button
                            key={`assignedTaskSkeleton-${i}`}
                            className={`divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow basis-28 flex-grow shrink focus:outline-none hover:shadow-xl focus:shadow-2xl focus:ring-2 focus:ring-offset-2 text-indigo-600`}
                          >
                            <div className="flex flex-col items-stretch justify-between p-4 gap-y-2">
                              <Skeleton className="text-6xl" />
                              <Skeleton />
                            </div>
                          </button>
                        ))
                      )}
                    </div>
                  </>
                )}
              </div>
              <TaskWiseUserWIseReport
                state={{ ...this.state, workSpaceId }}
                searchParams={this.searchParameters}
                currentUser={this.props?.currentUser}
                setShowStatusWiseUserWiseReportModalOpen={
                  this.setShowStatusWiseUserWiseReportModalOpen
                }
                setStatusWiseUserWiseReport={this.setStatusWiseUserWiseReport}
              />

              <div className="grid gap-4 lg:grid-cols-2">
                <div className="space-y-4">
                  <h2 className="text-lg font-medium leading-6 text-gray-900">
                    Your Todos
                  </h2>
                  <TodoCountTable
                    loading={this.state.loading}
                    firmId={this.props.params?.firmId}
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </Dashboard>
    );
  }
}

export default compose<ComponentType<Props>>(connector, withRouter)(Index);
