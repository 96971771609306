import React, { Fragment } from "react";
// Import to Display skeleton while loading data
import "react-loading-skeleton/dist/skeleton.css";
// Pagination
import Pagination from "../../components/Pagination";
import { connect, ConnectedProps } from "react-redux";
// Link backend
import agent from "../../agent";
// Dashboard import
import Dashboard from "../../components/Dashboard";
// Icons and styling
import Icon from "../../components/Icon";
// Redux Notify
import { ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import "../style.css";
import TagManager from "react-gtm-module";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import MultiSelect from "../../components/MultiSelect";
import BulkActionModal from "./BulkActionModal";
import { getDueDateFromDays } from "../../helpers/calendar";
import { Menu, Transition } from "@headlessui/react";
import {
  ArrowDownTrayIcon,
  DocumentDuplicateIcon,
  DocumentTextIcon,
  LockClosedIcon,
  MagnifyingGlassIcon,
  PlusIcon
} from "@heroicons/react/24/outline";
import Button from "../../components/Button";
import { downloadFile } from "../../helpers/downloadFile";
import TaskTable, { UserTypeToShow } from "../../components/TaskTable";
import TaskSearch from "../../components/TaskSearch";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Task List"
  },
  dataLayerName: "PageDataLayer"
};

export const showTaskType = [
  "Open",
  "Completed",
  "Ignore Tracking",
  "Not Required"
] as const;

export type ShowTaskType = (typeof showTaskType)[number];

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

export const userTypeOptions: UserTypeToShow[] = ["working", "others"];

class Tasks extends React.Component<any, PropsFromRedux> {
  state: {
    loading: boolean;
    tasks: any;
    redirectedFromDashboard: boolean;
    displayTaskDetails: any;
    createdTaskDetails: any[];
    createdTaskCount: number;
    duplicateTaskDetails: any[];
    duplicateTaskCount: number;
    posX: any;
    posY: any;
    showBackDrop: boolean;
    searchText: string;
    totalRecords: number;
    showSearch: boolean;
    showAdvancedSearch: boolean;
    showBulkActionModal: boolean;
    selectedTasks: any;
    searchDueDate: any;
    selectedShowTaskType: any;
    selectedSearchStatus: any;
    period: any;
    selectedSearchPeriod: any;
    selectedSearchPeriodType: any;
    selectedSearchUser: any;
    selectedSearchTag: any;
    selectedSearchStar: any;
    clients: any;
    selectedSearchClient: any;
    fileNo: any;
    selectedSearchGroup: any;
    selectedSearchFileNo: any;
    customFields: any;
    selectedSearchCustomField: any;
    selectedSearchCustomValue: any;
    selectedSearchEntity: any;
    comment: string;
    selectedTableStatus: any;
    chunkSize: number;
    currPage: number;
    filters: any;
    searchLoading: boolean;
    exportLoading: boolean;
    rotate: boolean;
    selectedUserTypeToShow: UserTypeToShow;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      loading: false,
      tasks: [],
      redirectedFromDashboard: false,
      displayTaskDetails: [],
      createdTaskDetails: [],
      createdTaskCount: 0,
      duplicateTaskDetails: [],
      duplicateTaskCount: 0,
      posX: null,
      posY: null,
      showBackDrop: false,
      searchText: "",
      totalRecords: 0,
      showSearch: false,
      showAdvancedSearch: false,
      showBulkActionModal: false,
      selectedTasks: [],
      searchDueDate: { showCalender: false, value: "", from: "", to: "" },
      selectedShowTaskType: { _id: "Open", name: "Open" },
      selectedSearchStatus: null,
      period: [],
      selectedSearchPeriod: null,
      selectedSearchPeriodType: null,
      selectedSearchUser: null,
      selectedSearchTag: null,
      selectedSearchStar: null,
      clients: [],
      selectedSearchClient: null,
      selectedSearchGroup: null,
      fileNo: [],
      selectedSearchFileNo: null,
      customFields: [],
      selectedSearchCustomField: null,
      selectedSearchCustomValue: null,
      selectedSearchEntity: null,
      comment: "",
      selectedTableStatus: { taskId: null, status: null },
      chunkSize: 100,
      currPage: 0,
      filters: { openTask: true },
      searchLoading: false,
      exportLoading: false,
      rotate: false,
      selectedUserTypeToShow:
        (localStorage.getItem("userTypeToShow") as UserTypeToShow) || "working"
    };
  }

  getTaskTypeList = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    if (workSpaceId) {
      agent.Tasks.taskTypeList(workSpaceId)
        .then((response: any) => {
          let taskTypes = response.tasks;
          // const extraTaskTypes = [
          //   { _id: "ALL_GST", name: "ALL GST" },
          //   { _id: "ALL_IT", name: "ALL IT" },
          //   { _id: "OTHER", name: "OTHER" },
          // ];
          // taskTypes.unshift(...extraTaskTypes);
          this.setState({ taskTypes });
        })
        .catch((err: any) => {
          (this.props as any).onNotify(
            "Could not load task type list",
            err?.response?.data?.error || err?.message || err,
            "danger"
          );
        });
    }
  };

  getClientsList = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const searchText = "";
    const active = true;
    const skip = this.state.chunkSize * this.state.currPage;
    const limit = this.state.chunkSize;
    agent.Clients.getClientList(
      workSpaceId,
      skip,
      limit,
      searchText,
      active,
      {}
    )
      .then((response: any) => {
        const allFileNo: string[] = [];
        response.clients.forEach(
          (client: any) => client.fileNo && allFileNo.push(client.fileNo)
        );
        this.setState({
          clients: response.clients,
          fileNo: allFileNo
        });
      })
      .catch((err: any) => {
        (this.props as any).onNotify(
          "Could not load Client Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getCustomFieldList = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const active = true;
    const searchText = "";
    const skip = this.state.chunkSize * this.state.currPage;
    const limit = this.state.chunkSize;
    agent.CustomField.getCustomFieldList(
      workSpaceId,
      active,
      searchText,
      limit,
      skip
    )
      .then((response: any) => {
        const customFields = response.customFields.filter(
          (item: any) => item.applicableFor === "task"
        );
        this.setState({ customFields });
      })
      .catch((err: any) => {
        (this.props as any).onNotify(
          "Could not load Custom Field Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  getTaskData = (download?: boolean) => {
    const workSpaceId = (this.props as any).params?.firmId;
    let limit: number = this.state.chunkSize;
    let skip: number = this.state.chunkSize * this.state.currPage;
    const searchText = "";
    let filters = this.state.filters;
    filters?.name?.length === 0 && delete filters.name;
    download
      ? this.setState({ exportLoading: true })
      : this.setState({ loading: true });
    agent.Tasks.getTaskList(
      workSpaceId,
      limit,
      skip,
      searchText,
      filters,
      download ? true : false
    )
      .then((response: any) => {
        if (download) {
          this.setState({ exportLoading: false });
          downloadFile(response, "TaxPido PMS Tasks List.xlsx");
        } else {
          this.setState({
            tasks: response.tasks,
            loading: false,
            totalRecords: response.count,
            displayTaskDetails: response.tasks,
            searchLoading: false,
            selectedTasks: []
          });
        }
      })
      .catch((err: any) => {
        if (download) {
          this.setState({ exportLoading: false });
          const data = new Blob([err.response.data], {
            type: err.response.data.type
          });
          data.text().then(text => {
            const error = JSON.parse(text);
            (this.props as any).onNotify(
              "Could not export Task Details",
              error?.message || error,
              "danger"
            );
          });
        } else {
          this.setState({ loading: false, searchLoading: false });
          (this.props as any).onNotify(
            "Could not load Task Details",
            Object.keys(err?.response?.data?.message).length === 0
              ? "Some error occured"
              : err?.response?.data?.message || err?.message || err,
            "danger"
          );
        }
      });
  };

  addDueDateRange = (
    from: string,
    to: string,
    dueType:
      | "0"
      | "1"
      | "7"
      | "overdueupto7days"
      | "overduemorethan7days"
      | "OverDue"
  ) => {
    this.setState({
      searchDueDate: {
        showCalender: false,
        value:
          dueType === "0"
            ? "Today"
            : dueType === "1"
            ? "Tomorrow"
            : dueType === "7"
            ? "Due in Next 7 days"
            : dueType === "overdueupto7days"
            ? "OverDue up to 7 days"
            : dueType === "overduemorethan7days"
            ? "OverDue more than 7 days"
            : dueType === "OverDue"
            ? "OverDue"
            : `${from.split("T")[0]}-${to.split("T")[0]}`,
        from: !dueType ? from : "",
        to: !dueType ? to : ""
      }
    });
  };

  queryFromDashboard = () => {
    if (
      this.props.location.pathname ===
        `/${(this.props as any).params?.firmId}/tasks/list` &&
      this.props.location.search !== ""
    ) {
      const statusId = this.props.searchParams.get("statusIds");
      const period = this.props.searchParams.get("period");
      const periodType = this.props.searchParams.get("periodType");
      const name = this.props.searchParams.get("name");
      const userId = this.props.searchParams.get("user");
      const userName = this.props.searchParams.get("userName");
      const otherUserIds = this.props.searchParams.get("otherUser");
      const dueDateParam = this.props.searchParams.get("dueDate");

      const selectedDueDate = getDueDateFromDays(dueDateParam);

      const newFilters: any = {};

      const queries = this.props.searchParams.entries();
      for (const [key, value] of queries) {
        if (key === "dueDate") {
          this.addDueDateRange(
            new Date(selectedDueDate?.from).toDateString(),
            new Date(selectedDueDate?.to).toDateString(),
            value
          );
          newFilters.dueDate = selectedDueDate;
        } else if (key === "type") {
          newFilters.type = [value];
        } else if (key === "statusIds") {
          newFilters.statusIds = [value];
        } else if (key === "period") {
          newFilters.period = value.split(", ");
        } else if (key === "name") {
          newFilters.name = [value];
        } else if (key === "user") {
          newFilters.currentUserIds =
            value === "Unassigned"
              ? [""]
              : value.includes(",")
              ? value.split(",")
              : [value];
        } else if (key === "userName") {
          newFilters.currentUserName = value;
        } else if (key === "otherUser") {
          newFilters.users =
            newFilters?.users?.length > 0
              ? [...newFilters.users, value]
              : value
              ? [value]
              : [];
        }
      }

      this.setState(
        {
          redirectedFromDashboard: true,
          filters: {
            ...this.state.filters,
            ...newFilters
          },
          selectedSearchStatus:
            (this.props as any)?.status?.find(
              (status: any) => status._id === statusId
            ) ?? "",
          selectedSearchTask: [{ name }],
          selectedSearchPeriod: { name: period },
          selectedSearchPeriodType: { name: periodType ?? "" },
          selectedSearchUser:
            userName === ""
              ? { name: userName }
              : userId === "Unassigned"
              ? { name: "Unassigned" }
              : (this.props as any)?.users
                  ?.filter((user: any) => user._id === userId)
                  ?.map((item: any) => ({
                    name: item.name,
                    _id: item._id
                  })) ?? {
                  name: ""
                },
          selectedOtherUser: (this.props as any)?.users
            ?.filter((user: any) => otherUserIds?.includes(user._id))
            ?.map((item: any) => ({ name: item.name, _id: item._id })) ?? [
            {
              name: ""
            }
          ]
        },
        () => {
          this.getTaskData();
        }
      );
    } else {
      this.getTaskData();
    }
  };

  setFilterQueries = () => {
    const isSearched = this.props.searchParams.get("isSearched");
    if (isSearched) {
      const query = (this.props as any).searchParams;
      const filters: any = {};
      const custom: any = {};
      for (const entry of query.entries()) {
        const [param, value] = entry;
        if (param === "dueDate") {
          if (value.includes("-")) {
            const splitDate = value.split("-");
            const fromDate = splitDate?.[0]?.split("/");
            const toDate = splitDate?.[1]?.split("/");
            const fromIsoDate =
              value === "OverDue"
                ? new Date(2001, 0, 1).toISOString()
                : new Date(
                    fromDate[2],
                    fromDate[1] - 1,
                    fromDate[0]
                  ).toISOString();
            const toIsoDate =
              value === "OverDue"
                ? new Date().toISOString()
                : new Date(toDate[2], toDate[1] - 1, toDate[0]).toISOString();

            filters.dueDate = { from: fromIsoDate, to: toIsoDate };
          } else {
            const selectedDueDate = getDueDateFromDays(value);
            this.addDueDateRange(
              new Date(selectedDueDate?.from).toDateString(),
              new Date(selectedDueDate?.to).toDateString(),
              value
            );
          }
        } else if (param === "dateRange") {
          const splitDate = value.split("-");
          const fromDate = splitDate[0].split("/");
          const toDate = splitDate[1].split("/");

          const fromIsoDate = new Date(
            fromDate[2],
            fromDate[1] - 1,
            fromDate[0]
          ).toISOString();
          const toIsoDate = new Date(
            toDate[2],
            toDate[1] - 1,
            toDate[0]
          ).toISOString();

          filters.dateRange = { from: fromIsoDate, to: toIsoDate };
        } else if (param === "clientName") {
          filters.clientName = [encodeURIComponent(value)];
        } else if (param === "groups" || param === "tags") {
          filters[param] = [value];
        } else if (param === "period") {
          filters[param] = value.split(", ");
        } else if (param === "currentUserIds") {
          filters.currentUserIds = [value];
        } else if (param === "otherUser") {
          filters.users =
            filters?.users?.length > 0 ? [...filters.users, value] : [value];
        } else if (param === "statusIds") {
          filters[param] = [value];
          filters.openTask = false;
        } else if (param === "type") {
          filters.type = [value];
        } else if (param === "star") {
          filters.star = value === "Yes";
        } else if (param === "customField") {
          custom.name = value;
        } else if (param === "customFieldType") {
          custom.type = value;
        } else if (param === "customFieldValue") {
          const customValue =
            custom.type === "date" ? new Date(value).toISOString() : value;
          filters.customField = {
            name: custom.name,
            value: customValue
          };
        } else if (param === "name") {
          filters.name = [value];
        } else if (param === "periodType" || param === "isSearched") {
        } else {
          filters[param] = value;
        }
      }
      this.setState({ filters: { ...this.state.filters, ...filters } }, () =>
        this.getTaskData()
      );
    } else {
      this.queryFromDashboard();
    }
  };

  showCreatedORDuplicateTask = (response: any) => {
    this.setState({
      duplicateTaskDetails: response.duplicateTaskDetails ?? [],
      duplicateTaskCount: response.duplicateTasksCount ?? 0,
      createdTaskDetails: response.newTasks ?? [],
      createdTaskCount: response.tasksCount - response.duplicateTasksCount ?? 0,
      loading: false
    });
  };

  showAllTasks = () => {
    if ((this.props as any)?.taskCreated) {
      this.setState({
        duplicateTaskDetails: [],
        duplicateTaskCount: 0,
        createdTaskDetails: [],
        createdTaskCount: 0,
        loading: false
      });

      (this.props as any).updateCommon({
        taskCreated: false,
        taskCreatedData: null
      });
    } else {
      this.onSearchCancelClick({ openTask: true });
      this.setState({ redirectedFromDashboard: false });
    }
  };

  // onMount Load data
  componentDidMount() {
    this.setFilterQueries();
    document.title = "Tasks - TaxPido PMS";

    const taskCreatedData = (this.props as any)?.taskCreatedData;
    if ((this.props as any)?.taskCreated && taskCreatedData) {
      this.showCreatedORDuplicateTask(taskCreatedData);
    }
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params?.firmId;
    const currFirmId = (this.props as any).params?.firmId;

    if (prevFirmId !== currFirmId) {
      this.getTaskData();
    }
  }

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      this.getTaskData();
    });
  };

  fetchRequired = () => {
    this.setState({ requireFetch: true });
  };

  onFirmChange = (item: any) => {
    this.setState({ selectedFirm: item });
  };

  onActionClick = (e: any) => {
    const screenClosness = window.innerHeight - e.clientY;
    const positionY = screenClosness < 125 ? e.clientY - 125 : e.clientY;
    this.setState({
      posX: e.clientX,
      posY: positionY,
      showBackDrop: true
    });
  };

  onOptionsDropdownClick = () => {
    this.setState({
      rotate: !this.state.rotate,
      showBackDrop: !this.state.showBackDrop
    });
  };

  openAddTaskPage = () => {
    const taskRights = (this.props as any)?.rights?.taskRights;
    const createRight = taskRights.create;
    const currentFirmId = (this.props as any).params?.firmId;
    if (createRight) {
      (this.props as any).navigate(`/${currentFirmId}/tasks/add`);
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  onUserTypeToShowChange = (item: { _id: UserTypeToShow }) => {
    this.setState({ selectedUserTypeToShow: item._id });
    localStorage.setItem("userTypeToShow", item._id);
  };

  bulkActionModalSetOpen = (open: boolean) => {
    this.setState({
      showBulkActionModal: open,
      selectedTasks: []
    });
  };

  openBulkActionModal = () => {
    const { selectedTasks } = this.state;
    if (selectedTasks.length === 0) {
      (this.props as any).onNotify(
        "No Task Selected",
        "Please select at least one task to perform bulk action.",
        "danger"
      );
    } else {
      this.setState({
        showBulkActionModal: true
      });
    }
  };

  onAdvancedSearchClick = () => {
    this.setState({
      showAdvancedSearch: !this.state.showAdvancedSearch,
      showSearch: false
    });
  };

  onSearchClick = () => {
    this.setState({
      showSearch: !this.state.showSearch,
      showAdvancedSearch: false
    });
  };

  onSearchCancelClick = (filters: any) => {
    this.props.navigate(`/${this.props.params.firmId}/tasks/list`);
    this.setState(
      {
        showSearch: false,
        showAdvancedSearch: false,
        filters
      },
      () => {
        this.getTaskData();
      }
    );
  };

  onTaskSearch = (filters: any) => {
    this.setState(
      {
        currPage: 0,
        searchLoading: true,
        filters: {
          ...this.state.filters,
          ...filters
        }
      },
      () => {
        // console.log("task index");
        // console.table(this.state.filters);
        this.getTaskData();
      }
    );
  };

  onTaskCheckBoxChange = (task: any) => {
    const { selectedTasks } = this.state;
    const clientIndex = selectedTasks.findIndex((c: any) => c._id === task._id);
    if (clientIndex === -1) {
      this.setState({ selectedTasks: [...selectedTasks, task] });
    } else {
      const removeClient = selectedTasks.filter((c: any) => c._id !== task._id);
      this.setState({ selectedTasks: removeClient });
    }
  };

  onSelectAllTask = (taskType: "allTask" | "newTask" | "duplicateTask") => {
    const {
      displayTaskDetails,
      createdTaskDetails,
      duplicateTaskDetails,
      selectedTasks
    } = this.state;
    switch (taskType) {
      case "allTask":
        if (selectedTasks.length === displayTaskDetails.length) {
          this.setState({ selectedTasks: [] });
        } else {
          this.setState({ selectedTasks: displayTaskDetails });
        }
        break;
      case "newTask":
        if (selectedTasks.length === createdTaskDetails.length) {
          this.setState({ selectedTasks: [] });
        } else {
          this.setState({ selectedTasks: createdTaskDetails });
        }
        break;
      case "duplicateTask":
        if (selectedTasks.length === duplicateTaskDetails.length) {
          this.setState({ selectedTasks: [] });
        } else {
          this.setState({ selectedTasks: duplicateTaskDetails });
        }
        break;
      default:
        break;
    }
  };

  onShowTaskChange = (item: any) => {
    if (item.name === "Open") {
      const filters = this.state.filters;
      delete filters.statusIds;
      this.setState(
        {
          selectedShowTaskType: item,
          filters: { ...filters, openTask: true },
          currPage: 0
        },
        () => this.getTaskData()
      );
    } else {
      const statusId = (this.props as any).status.find(
        (s: any) => s.name === item.name
      )?._id;
      statusId &&
        this.setState(
          {
            selectedShowTaskType: item,
            currPage: 0,
            filters: {
              ...this.state.filters,
              openTask: false,
              statusIds: [statusId]
            }
          },
          () => this.getTaskData()
        );
    }
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 }, () =>
      this.getTaskData()
    );
  };

  options = [
    {
      name: "Add Task",
      icon: PlusIcon,
      onClick: this.openAddTaskPage,
      disabled: !(this.props as any)?.rights?.taskRights.create
    },
    {
      name: "Search",
      icon: MagnifyingGlassIcon,
      onClick: this.onSearchClick
    },
    {
      name: "Advance Search",
      icon: MagnifyingGlassIcon,
      onClick: this.onAdvancedSearchClick
    },
    {
      name: "Bulk Action",
      icon: DocumentDuplicateIcon,
      onClick: this.openBulkActionModal
    },
    {
      name: "Export",
      icon: ArrowDownTrayIcon,
      onClick: () => this.getTaskData(true)
    },
    {
      name: "Show All Tasks",
      icon: DocumentTextIcon,
      onClick: this.showAllTasks
    }
  ];

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {/* <div
          className="bg-yellow-100 border-l-4 border-yellow-500 text-yellow-700 px-4 py-2 mb-4 flex justify-between"
          role="alert"
        >
          <p className="font-bold">This page is currently in test mode.</p>
        </div> */}
        {this.state.showBulkActionModal && (
          <BulkActionModal
            state={this.state}
            bulkActionModalSetOpen={this.bulkActionModalSetOpen}
            onLoad={() => {
              this.getTaskData();
              /* For now I am showing all the task after bulk action as we cannot update the listing of created or duplicate task
               * we can show the current tasks after bulk action by setting search query to the current listed client name, period and task name
               */
              this.showAllTasks();
            }}
          />
        )}

        <div className="max-w-screen-2xl mx-auto flex justify-between">
          <h1 className="text-2xl font-semibold text-gray-900">Tasks</h1>

          <div className="lg:hidden ml-auto flex gap-2">
            <Menu as="div" className="inline-block relative">
              <Menu.Button
                onClick={this.onOptionsDropdownClick}
                className="relative inline-flex gap-x-2 items-center justify-between px-2 py-2 sm:py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white  bg-indigo-600 hover:bg-indigo-700 focus:outline-none disabled:bg-indigo-600 w-fit sm:pl-4 sm:pr-2"
              >
                <Icon
                  name="outline/settings"
                  className={`h-5 w-5`}
                  aria-hidden="true"
                />
                <span>Options</span>
                <Icon
                  name="dropdown-arrow"
                  className={`h-5 w-5 ${this.state.rotate ? "rotate-90" : ""}`}
                  aria-hidden="true"
                />
              </Menu.Button>
              {this.state.showBackDrop && (
                <div
                  className="fixed top-0 left-0 z-10 w-full h-screen"
                  onClick={this.onOptionsDropdownClick}
                ></div>
              )}
              <Transition
                as={Fragment}
                enter="transition ease-out duration-100"
                enterFrom="transform opacity-0 scale-95"
                enterTo="transform opacity-100 scale-100"
                leave="transition ease-in duration-75"
                leaveFrom="transform opacity-100 scale-100"
                leaveTo="transform opacity-0 scale-95"
              >
                <div className="absolute z-[100] right-0">
                  <Menu.Items className="overscroll-none mt-2 w-max rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="py-1">
                      {this.options.map((option, index) =>
                        (this.props as any)?.taskCreated ||
                        this.state.redirectedFromDashboard ? (
                          option.name === "Add Task" ||
                          option.name === "Bulk Action" ||
                          option.name === "Show All Tasks" ? (
                            <Menu.Item
                              key={`for-new-or-duplicate-task-${option.name}-${index}`}
                              as="div"
                              className={
                                option.name === "Add Task" ||
                                option.name === "Bulk Action"
                                  ? "block md:hidden"
                                  : ""
                              }
                            >
                              <Button
                                name={option.name}
                                onClick={() => {
                                  this.onOptionsDropdownClick();
                                  option.onClick && option.onClick();
                                }}
                                icon={option.icon}
                                disabled={option.disabled ?? this.state.loading}
                                className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900 disabled:cursor-not-allowed"
                              />
                            </Menu.Item>
                          ) : (
                            <Fragment
                              key={`for-new-or-duplicate-task-${option.name}-${index}`}
                            ></Fragment>
                          )
                        ) : option.name === "Show All Tasks" ? (
                          <Fragment
                            key={`for-new-or-duplicate-task-${option.name}-${index}`}
                          ></Fragment>
                        ) : (
                          <Menu.Item
                            key={`for-all-tasks-${option.name}-${index}`}
                            as="div"
                            className={
                              option.name === "Add Task" ||
                              option.name === "Bulk Action"
                                ? "block md:hidden"
                                : ""
                            }
                          >
                            <Button
                              name={option.name}
                              onClick={() => {
                                this.onOptionsDropdownClick();
                                option.onClick && option.onClick();
                              }}
                              icon={option.icon}
                              disabled={option.disabled ?? this.state.loading}
                              className="flex items-center w-full p-1 px-4 py-2 text-sm hover:bg-gray-100 text-gray-900 disabled:cursor-not-allowed"
                            />
                          </Menu.Item>
                        )
                      )}
                    </div>
                  </Menu.Items>
                </div>
              </Transition>
            </Menu>
          </div>
        </div>
        <div className="flex gap-3 items-center justify-between mt-6">
          <div className="flex items-center justify-between gap-4">
            <div className="lg:w-fit flex items-center justify-between gap-4">
              <div className="hidden md:block">
                <Button
                  name="Add Task"
                  onClick={this.openAddTaskPage}
                  icon={
                    (this.props as any)?.rights?.taskRights.create
                      ? PlusIcon
                      : LockClosedIcon
                  }
                />
              </div>
              {!(this.props as any)?.taskCreated && (
                <div className="md:w-40">
                  <MultiSelect
                    items={showTaskType?.map((item: any) => {
                      return {
                        _id: item,
                        name: item
                      };
                    })}
                    selected={{
                      name: this.state.selectedShowTaskType?.name
                    }}
                    type="showTask"
                    onChange={this.onShowTaskChange}
                    placeholder="Show Task"
                  />
                </div>
              )}
            </div>
          </div>

          {this.state.totalRecords > -1 &&
            !(this.props as any)?.taskCreated && (
              <div className="hidden lg:flex justify-between items-center gap-4">
                <Button
                  name="Search"
                  className="text-sm text-gray-600 hover:text-indigo-700 underline"
                  onClick={this.onSearchClick}
                />
                <Button
                  name="Advance Search"
                  className="text-sm text-gray-600 hover:text-indigo-700 underline"
                  onClick={this.onAdvancedSearchClick}
                />
              </div>
            )}
          <div className="flex items-center gap-4">
            <div className="md:w-40">
              <MultiSelect
                items={userTypeOptions?.map((item: any) => {
                  return {
                    _id: item,
                    name: item
                  };
                })}
                selected={{
                  name: this.state.selectedUserTypeToShow
                }}
                type="UserTypeToShow"
                onChange={this.onUserTypeToShowChange}
                placeholder="Show User Type"
              />
            </div>
            <div className="hidden md:block">
              <Button
                name="Bulk Action"
                icon={DocumentDuplicateIcon}
                onClick={this.openBulkActionModal}
              />
            </div>
            <div className="hidden lg:flex gap-4 mt-1">
              {!(this.props as any)?.taskCreated && (
                <Button
                  name="Export"
                  onClick={() => this.getTaskData(true)}
                  icon={
                    this.state.exportLoading ? "loading" : ArrowDownTrayIcon
                  }
                />
              )}
              {((this.props as any)?.taskCreated ||
                this.state.redirectedFromDashboard) && (
                <Button name="Show All Tasks" onClick={this.showAllTasks} />
              )}
            </div>
          </div>
        </div>
        <div>
          {(this.state.showSearch || this.state.showAdvancedSearch) && (
            <TaskSearch
              type={"tasks"}
              showAdvancedSearch={this.state.showAdvancedSearch}
              navigate={this.props.navigate}
              location={this.props.location}
              params={this.props.params}
              setSearchParams={this.props.setSearchParams}
              searchParams={this.props.searchParams}
              onTaskSearch={this.onTaskSearch}
              onSearchCancelClick={this.onSearchCancelClick}
              onLoad={this.getTaskData}
              searchLoading={this.state.searchLoading}
              filters={this.state.filters}
              selectedShowTaskType={this.state.selectedShowTaskType}
            />
          )}
        </div>

        {(this.props as any)?.taskCreated ? (
          <div className="mt-6 space-y-6">
            <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-4">
              New Tasks - {this.state.createdTaskCount}
            </h2>
            {this.state.createdTaskDetails.length > 0 ? (
              <TaskTable
                key={"createdTask"}
                userTypeToShow={this.state.selectedUserTypeToShow}
                type="task"
                loading={this.state.loading}
                displayTaskDetails={this.state.createdTaskDetails}
                showAllTasks={this.showAllTasks}
                totalRecords={this.state.createdTaskCount}
                filters={this.state.filters}
                onLoad={this.getTaskData}
                selectedTasks={this.state.selectedTasks}
                onSelectAllTask={() => this.onSelectAllTask("newTask")}
                onTaskCheckBoxChange={this.onTaskCheckBoxChange}
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
                searchParams={this.props.searchParams}
                setSearchParams={this.props.setSearchParams}
              />
            ) : (
              <div className="text-center w-full p-4 rounded-lg bg-white shadow-lg">
                No task created.
              </div>
            )}
            <h2 className="text-xl font-semibold text-gray-900 flex items-center gap-4">
              Duplicate Tasks - {this.state.duplicateTaskCount}
            </h2>
            {this.state.duplicateTaskDetails.length > 0 ? (
              <TaskTable
                key={"duplicateTask"}
                userTypeToShow={this.state.selectedUserTypeToShow}
                type="task"
                loading={this.state.loading}
                displayTaskDetails={this.state.duplicateTaskDetails}
                showAllTasks={this.showAllTasks}
                totalRecords={this.state.duplicateTaskCount}
                filters={this.state.filters}
                onLoad={this.getTaskData}
                selectedTasks={this.state.selectedTasks}
                onSelectAllTask={() => this.onSelectAllTask("duplicateTask")}
                onTaskCheckBoxChange={this.onTaskCheckBoxChange}
                navigate={this.props.navigate}
                location={this.props.location}
                params={this.props.params}
                searchParams={this.props.searchParams}
                setSearchParams={this.props.setSearchParams}
              />
            ) : (
              <div className="text-center w-full p-4 rounded-lg bg-white shadow-lg">
                No duplicate task.
              </div>
            )}
          </div>
        ) : (
          <div>
            <TaskTable
              key={"allTask"}
              userTypeToShow={this.state.selectedUserTypeToShow}
              type="task"
              loading={this.state.loading}
              displayTaskDetails={this.state.displayTaskDetails}
              filters={this.state.filters}
              totalRecords={this.state.totalRecords}
              onLoad={this.getTaskData}
              selectedTasks={this.state.selectedTasks}
              onSelectAllTask={() => this.onSelectAllTask("allTask")}
              onTaskCheckBoxChange={this.onTaskCheckBoxChange}
              navigate={this.props.navigate}
              location={this.props.location}
              params={this.props.params}
              searchParams={this.props.searchParams}
              setSearchParams={this.props.setSearchParams}
            />
            <Pagination
              displayRecords={this.state.displayTaskDetails}
              totalRecords={this.state.totalRecords}
              chunkSize={this.state.chunkSize}
              currPage={this.state.currPage}
              handlePageClick={this.handlePageClick}
              handleItemPerPage={this.handleItemPerPage}
              className="my-4"
            />
          </div>
        )}
      </Dashboard>
    );
  }
}

export default compose(connector, withRouter)(Tasks) as React.ComponentType;
