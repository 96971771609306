import { ComponentType, Fragment, useEffect, useRef, useState } from "react";
import { Combobox, Dialog, Transition } from "@headlessui/react";
import { MagnifyingGlassIcon } from "@heroicons/react/20/solid";
import Icon from "./Icon";
import agent from "../agent";
import { connect, ConnectedProps } from "react-redux";
import { UPDATE_COMMON, ADD_NOTIFICATION } from "../store/types";
import { compose } from "redux";
import { withRouter, WithRouterProps } from "../helpers/withRouter";
import { useNavigate } from "react-router";
import Skeleton from "react-loading-skeleton";
import Switch from "./switch";
import { XMarkIcon } from "@heroicons/react/24/outline";
import EditPersonModal from "../pages/ContactPerson/Edit";
import { ContactPerson } from "../helpers/types";
import { formatClientName } from "../helpers/formatClientName";
import { AppDispatch, RootState } from "../store";
import { NotifyType } from "../store/reducers/notification";
import { CommonAction } from "../store/reducers/common";

//Redux mapping
const mapStateToProps = (state: RootState) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: AppDispatch) => ({
  updateCommon: (payload: CommonAction["payload"]) =>
    dispatch({ type: UPDATE_COMMON, payload }),
  addNotification: (title: string, message: string, type: NotifyType) =>
    dispatch({ type: ADD_NOTIFICATION, payload: { title, message, type } })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

const projects = [
  {
    id: "01",
    name: "Tasks",
    icon: "outline/document-text",
    action: "addTask"
  },
  {
    id: "02",
    name: "Client",
    icon: "outline/user-plus",
    action: "addClient"
  },
  {
    id: "03",
    name: "Contact Person",
    icon: "outline/user-plus",
    action: "ADD_PERSON_MODAL"
  },
  {
    id: "04",
    name: "Todo",
    icon: "outline/document-text",
    action: "ADD_TODO_MODAL"
  },
  {
    id: "05",
    name: "Receipt",
    icon: "outline/card",
    action: "ADD_RECEIPT_MODAL"
  },
  {
    id: "06",
    name: "Custom Field",
    icon: "outline/document-add",
    action: "ADD_CUSTOM_FIELD_MODAL"
  },
  {
    id: "07",
    name: "Client Group",
    icon: "outline/group",
    action: "ADD_GROUP_MODAL"
  },
  {
    id: "08",
    name: "Status",
    icon: "outline/document-text",
    action: "ADD_STATUS_MODAL"
  },
  {
    id: "09",
    name: "Tag",
    icon: "outline/tag",
    action: "ADD_TAG_MODAL"
  },
  {
    id: "10",
    name: "User",
    icon: "outline/user-plus",
    action: "ADD_USER_MODAL"
  }

  // More projects...
] as const;

interface Props extends Partial<PropsFromRedux & WithRouterProps> {
  openModalHandler: (modalName: string) => void;
}

const SearchNavigation = (props: Props) => {
  const { openModalHandler } = props;
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState("");
  const navigate = useNavigate();

  const searchShortcut = (e: KeyboardEvent) => {
    if ((e.ctrlKey || e.metaKey) && e.key === "f") {
      //adding both the key ctrl and cmd
      e.preventDefault();
      setOpen(true);
    }
  };

  useEffect(() => {
    document.addEventListener("keydown", searchShortcut);
    return () => {
      document.removeEventListener("keydown", searchShortcut);
    };
  }, []);

  const handleSearchTextChange = (searchText: string) => {
    // Remove whitespace from the beginning and end of `searchText`.
    searchText = searchText.replace(/^[\s]$/, "");
    // Replace multiple spaces with a single space in `searchText`.
    searchText = searchText.replace(/[\s]{2,}/, " ");

    const searchRegex = /^[a-zA-Z0-9()-,/&.\s]*$/;
    searchRegex.test(searchText) &&
      searchText.length <= 50 &&
      setQuery(searchText);
  };

  const optionClickHandler = (modalName: string) => {
    const currentFirmId = props?.currentFirm?._id;
    if (modalName === "addClient") {
      props.navigate?.(`/${currentFirmId}/clients/add`);
    } else if (modalName === "addTask") {
      props.navigate?.(`/${currentFirmId}/tasks/add`);
    } else {
      openModalHandler(modalName);
    }
    setOpen(false);
  };

  const [loading, setLoading] = useState(false);

  const [searchedClient, setSearchedClient] = useState<any[]>([]);

  const getClientsList = () => {
    const workSpaceId = props?.currentFirm?._id;

    if (!workSpaceId) return;

    setLoading(true);
    agent.Clients.getClientList(workSpaceId, 0, 100000, query, true, {})
      .then(response => {
        setSearchedClient(response.clients);
        response.clients.length === 0
          ? handleShowList("Clients", false)
          : handleShowList("Clients", true);
        setLoading(false);
      })
      .catch(err => {
        setLoading(false);
        props.addNotification?.(
          "Could not load Client Details",
          typeof err?.response?.data?.message === "object"
            ? "Could not load Client Details"
            : err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  const [groups, setGroups] = useState([]);
  const getGroupsList = () => {
    const workSpaceId = props?.currentFirm?._id;

    if (!workSpaceId) return;

    setLoading(true);
    agent.ClientGroups.getClientGroupList(workSpaceId, true, query, 0, 100000)
      .then(response => {
        setGroups(response.groups);
        response.groups.length === 0
          ? handleShowList("Groups", false)
          : handleShowList("Groups", true);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        props.addNotification?.(
          "Could not load Client Group Details",
          typeof error?.response?.data?.message === "object"
            ? "Could not load Client Group Details"
            : error?.response?.data?.message || error?.message || error,
          "danger"
        );
      });
  };

  const [contactPersons, setContactPersons] = useState([]);
  const getContactPersonsList = () => {
    const workSpaceId = props?.currentFirm?._id;

    if (!workSpaceId) return;

    setLoading(true);
    agent.ContactPerson.getPersonList(workSpaceId, true, query, 100000, 0)
      .then(response => {
        setContactPersons(response.contactPerson);
        response.contactPerson.length === 0
          ? handleShowList("Contact Persons", false)
          : handleShowList("Contact Persons", true);
        setLoading(false);
      })
      .catch(error => {
        setLoading(false);
        props.addNotification?.(
          "Could not load Contact Person Details",
          typeof error?.response?.data?.message === "object"
            ? "Could not load Contact Person Details"
            : error?.response?.data?.message || error?.message || error,
          "danger"
        );
      });
  };
  const localRecentSearches = localStorage.getItem("recentSearches");

  const [recentSearches, setRecentSearches] = useState<string[]>(
    typeof localRecentSearches === "string"
      ? JSON.parse(localRecentSearches)
      : []
  );

  const saveRecentSearch = (query: string) => {
    const searchExist = recentSearches.includes(query);
    if (searchExist) return;
    if (recentSearches.length === 3) {
      setRecentSearches([query, ...recentSearches.slice(0, 2)]);
    } else if (!searchExist) {
      setRecentSearches([query, ...recentSearches]);
    } else {
      const index = recentSearches.indexOf(query);
      const newRecentSearches = recentSearches.filter((item, i) => i !== index);
      setRecentSearches([query, ...newRecentSearches]);
    }
  };

  const removeRecentSearch = (item: string) => {
    setRecentSearches((prev: string[]) => prev.filter(i => i !== item));
  };

  useEffect(() => {
    localStorage.setItem("recentSearches", JSON.stringify(recentSearches));
  }, [recentSearches]);

  const typingTimeout = useRef<NodeJS.Timeout>();

  useEffect(() => {
    setLoading(true);

    if (typingTimeout.current) {
      clearTimeout(typingTimeout.current);
    }
    if (query.length >= 3) {
      typingTimeout.current = setTimeout(() => {
        getClientsList();
        getGroupsList();
        getContactPersonsList();
        saveRecentSearch(query);
      }, 700);
    } else {
      setSearchedClient([]);
      setGroups([]);
      setContactPersons([]);
    }
  }, [query]);

  const onClientClick = (client: any) => {
    const currentFirmId = props?.currentFirm?._id;
    if (currentFirmId) {
      navigate(`/${currentFirmId}/client-profile/${client._id}`);
      setOpen(false);
    }
  };

  const onGroupClick = (group: any) => {
    const currentFirmId = props?.currentFirm?._id;
    if (currentFirmId) {
      navigate(`/${currentFirmId}/group-profile/${group._id}`);
      setOpen(false);
    }
  };

  type ContactPersonState = {
    selectedRow: ContactPerson | null;
    showEditModal: boolean;
  };

  const [state, setState] = useState<ContactPersonState>({
    selectedRow: null,
    showEditModal: false
  });

  const editModalSetOpen = (value: boolean) => {
    setState({ ...state, showEditModal: value });
  };

  const onContactPersonClick = (contactPerson: ContactPerson) => {
    setState({ selectedRow: contactPerson, showEditModal: true });
    setOpen(false);
  };

  const searchList = [
    {
      heading: "Clients",
      list: searchedClient,
      onListItemClick: onClientClick
    },
    {
      heading: "Groups",
      list: groups,
      onListItemClick: onGroupClick
    },
    {
      heading: "Contact Persons",
      list: contactPersons,
      onListItemClick: onContactPersonClick
    }
  ] as const;

  const initialShowList = {
    Clients: true,
    Groups: true,
    "Contact Persons": true
  } as const;

  type showlistKey = keyof typeof initialShowList;

  const [showList, setShowList] = useState(initialShowList);

  const handleShowList = (heading: showlistKey, value?: boolean) => {
    setShowList(prev => ({
      ...prev,
      [heading]: value ?? !prev[heading]
    }));
  };

  return (
    <>
      {state.showEditModal && (
        <EditPersonModal state={state} editModalSetOpen={editModalSetOpen} />
      )}
      <button
        onClick={() => setOpen(true)}
        className="px-2 py-1 md:px-3 flex items-center gap-1 sm:gap-4 cursor-pointer w-fit max-w-full border border-gray-300 rounded-md overflow-clip"
      >
        <MagnifyingGlassIcon className="inline-block shrink-0 h-6 w-6 text-gray-500" />
        <span className="inline-block text-sm text-gray-500 whitespace-nowrap">
          Search{" "}
          <span className="hidden lg:inline-block">
            by File No, Name, PAN, GSTIN etc.
          </span>
          <span className="hidden xs:inline-block">
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Ctrl + F
          </span>
        </span>
      </button>

      <Transition.Root
        show={open}
        as={Fragment}
        afterLeave={() => setQuery("")}
        appear
      >
        <Dialog as="div" className="relative z-10" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-25 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-0 z-10 overflow-y-hidden p-4 sm:p-6 md:p-20">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="mx-auto sm:mt-0 mt-10 max-w-xl transform overflow-hidden rounded-xl bg-white shadow-2xl ring-1 ring-black ring-opacity-5 transition-all">
                <Combobox value="">
                  <div className="relative">
                    <MagnifyingGlassIcon
                      className="pointer-events-none absolute top-3.5 left-4 h-5 w-5 text-gray-400"
                      aria-hidden="true"
                    />
                    <input
                      type="search"
                      className="h-12 w-full border-0 bg-transparent pl-11 pr-20 text-gray-800 outline-none placeholder-gray-400 focus:ring-0 sm:text-sm"
                      placeholder="Search by Name, Trade Name, File No, Email or Mobile number"
                      value={query}
                      onChange={e => handleSearchTextChange(e.target.value)}
                    />
                    <span
                      className="absolute top-3.5 right-4 w-fit text-gray-600 text-xs border border-gray-300 rounded-md px-2 py-1 cursor-pointer hover:bg-gray-100"
                      onClick={() => setOpen(false)}
                    >
                      ESC
                    </span>
                  </div>
                  <Combobox.Options
                    static
                    className="max-h-[70vh] overflow-y-auto vertical-scroll scroll-py-10 scroll-pb-2 py-2 text-sm text-gray-700 divide-y-2 divide-gray-300"
                  >
                    {query === "" ? (
                      <>
                        <li>
                          <h2 className="bg-gray-100 py-2.5 px-4 text-sm font-bold text-gray-900">
                            Recent searches
                          </h2>
                          <ul className="py-2">
                            {recentSearches.length > 0 ? (
                              recentSearches.map((searchItem, index) => (
                                <Combobox.Option
                                  key={`${searchItem}-${index}`}
                                  value={searchItem}
                                  className="select-none px-4 py-2 hover:bg-indigo-600 text-gray-800 hover:text-white truncate flex gap-4 items-center justify-between"
                                >
                                  <span>
                                    <MagnifyingGlassIcon
                                      className="pointer-events-none h-5 w-5 text-gray-400"
                                      aria-hidden="true"
                                    />
                                  </span>
                                  <span
                                    className="grow cursor-pointer"
                                    onClick={() =>
                                      handleSearchTextChange(searchItem)
                                    }
                                  >
                                    {searchItem}
                                  </span>
                                  <span
                                    onClick={() =>
                                      removeRecentSearch(searchItem)
                                    }
                                  >
                                    <XMarkIcon className="h-4 w-4 cursor-pointer" />
                                  </span>
                                </Combobox.Option>
                              ))
                            ) : (
                              <li className="text-center py-2">
                                No recent searches
                              </li>
                            )}
                          </ul>
                        </li>
                        <li>
                          <h2 className="bg-gray-100 py-2.5 px-4 text-sm font-bold text-gray-900">
                            Quick Add
                          </h2>
                          <ul className="py-2 grid grid-cols-2">
                            {projects.map(project => (
                              <Combobox.Option
                                key={project.id}
                                value={project}
                                className="flex gap-3 select-none items-center px-4 py-2 hover:bg-indigo-600 cursor-pointer group"
                                onClick={() =>
                                  optionClickHandler(project.action)
                                }
                              >
                                <Icon
                                  name={project.icon}
                                  className="h-6 w-6 flex-none text-gray-400 group-hover:text-white"
                                  aria-hidden="true"
                                />
                                <span className="flex-auto text-gray-800 group-hover:text-white truncate">
                                  {project.name}
                                </span>
                              </Combobox.Option>
                            ))}
                          </ul>
                        </li>
                      </>
                    ) : query.length < 3 ? (
                      <li className="text-center py-2 border-t">
                        Please enter at least 3 characters to search
                      </li>
                    ) : (
                      searchList.map(({ heading, list, onListItemClick }) => (
                        <li key={heading}>
                          <h2
                            className="bg-gray-100 py-2.5 px-4 flex items-center justify-between cursor-pointer"
                            onClick={() => handleShowList(heading)}
                          >
                            <span className="text-sm font-bold text-gray-900">
                              {heading}
                            </span>
                            <span className="flex items-center gap-4">
                              <span>{list.length}</span>
                              <Switch
                                openIcon="subtract"
                                closeIcon="add"
                                enabled={showList[heading]}
                                onChange={() => handleShowList(heading)}
                              />
                            </span>
                          </h2>
                          {showList[heading] && (
                            <ul className="py-2">
                              {!loading ? (
                                list.length > 0 ? (
                                  list.map(listitem => (
                                    <Combobox.Option
                                      key={listitem._id}
                                      value={listitem}
                                      onClick={() =>
                                        onListItemClick
                                          ? onListItemClick(listitem)
                                          : null
                                      }
                                      className="select-none px-4 py-2 hover:bg-indigo-600 cursor-pointer group"
                                    >
                                      <span className="text-gray-800 group-hover:text-white truncate">
                                        {formatClientName(listitem)}
                                      </span>
                                    </Combobox.Option>
                                  ))
                                ) : (
                                  <li className="py-2 px-6 text-center text-sm sm:px-14">
                                    <Icon
                                      name="warning"
                                      className="mx-auto h-7 w-7 text-gray-400"
                                      aria-hidden="true"
                                    />
                                    <p className="mt-4 font-semibold text-gray-900">
                                      No results found
                                    </p>
                                    <p className="mt-2 text-gray-500">
                                      We couldn't find anything with that term.
                                      Please try again.
                                    </p>
                                  </li>
                                )
                              ) : (
                                [...Array(3)].map((_, i) => (
                                  <Combobox.Option
                                    key={i}
                                    value=""
                                    className="select-none px-4 py-2"
                                  >
                                    <Skeleton className="w-full h-full" />
                                  </Combobox.Option>
                                ))
                              )}
                            </ul>
                          )}
                        </li>
                      ))
                    )}
                  </Combobox.Options>
                  <div className="px-6 py-4 text-sm">
                    <span className="font-bold">Note :-</span> Search in Name,
                    Trade Name, File No, Notes, GSTIN, PAN, TAN, CIN and LLPIN
                    in clients and contacts.
                  </div>
                </Combobox>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
};

export default compose<ComponentType<Props>>(
  connector,
  withRouter
)(SearchNavigation);
