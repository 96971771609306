import React from "react";
import { connect, ConnectedProps } from "react-redux";
import agent from "../../agent";
import Dashboard from "../../components/Dashboard";
import { ADD_GST, ADD_NOTIFICATION, UPDATE_COMMON } from "../../store/types";
import TagManager from "react-gtm-module";
import Icon from "../../components/Icon";
import { withRouter } from "../../helpers/withRouter";
import { compose } from "redux";
import MultiSelect from "../../components/MultiSelect";
import TaskTable, { UserTypeToShow } from "../../components/TaskTable";
import TaskSearch from "../../components/TaskSearch";
import { showTaskType, userTypeOptions } from "../Tasks/Index";
import { downloadFile } from "../../helpers/downloadFile";
import BulkActionModal from "../Tasks/BulkActionModal";
import EditGroupModal from "./Edit";
import Pagination from "../../components/Pagination";

const tagManagerArgs = {
  dataLayer: {
    userId: "001",
    userProject: "TaxPido",
    page: "Group Details"
  },
  dataLayerName: "PageDataLayer"
};

//Redux mapping
const mapStateToProps = (state: any) => ({
  ...state.notification,
  ...state.common
});

const mapDispatchToProps = (dispatch: any) => ({
  updateCommon: (payload: any) => dispatch({ type: UPDATE_COMMON, payload }),
  addGst: (gst: any) =>
    dispatch({
      type: ADD_GST,
      payload: { gst }
    }),
  onNotify: (title: string, message: string, type: string) =>
    dispatch({
      type: ADD_NOTIFICATION,
      payload: {
        title,
        message,
        type
      }
    })
});

const connector = connect(mapStateToProps, mapDispatchToProps);

type PropsFromRedux = ConnectedProps<typeof connector>;

//Main Classs
class GroupDetails extends React.Component<any, PropsFromRedux> {
  state: {
    logging: boolean;
    group: any;
    selectedRow: any;
    filters: any;
    chunkSize: number;
    currPage: number;
    tasks: any;
    loading: boolean;
    totalRecords: number;
    displayTaskDetails: any;
    searchLoading: false;
    selectedTasks: any;
    showGroupDetails: boolean;
    editMode: boolean;
    selectedShowTaskType: any;
    showSearch: boolean;
    showAdvancedSearch: boolean;
    exportLoading: boolean;
    showBulkActionModal: boolean;
    showEditModal: boolean;
    selectedUserTypeToShow: UserTypeToShow;
  };

  constructor(props: any) {
    super(props);

    this.state = {
      logging: false,
      group: {},
      selectedRow: { name: "", description: "" },
      filters: { openTask: true },
      chunkSize: 50,
      currPage: 0,
      tasks: [],
      loading: false,
      totalRecords: 0,
      displayTaskDetails: [],
      searchLoading: false,
      selectedTasks: [],
      showGroupDetails: false,
      editMode: false,
      selectedShowTaskType: { _id: "Open", name: "Open" },
      showSearch: false,
      showAdvancedSearch: false,
      exportLoading: false,
      showBulkActionModal: false,
      showEditModal: false,
      selectedUserTypeToShow:
        (localStorage.getItem("userTypeToShow") as UserTypeToShow) || "working"
    };
  }

  handlePageClick = (data: any) => {
    this.setState({ currPage: data.selected }, () => {
      this.getTaskData();
    });
  };

  handleItemPerPage = (value: any) => {
    this.setState({ chunkSize: value.name, currPage: 0 }, () =>
      this.getTaskData()
    );
  };

  setFilterQueries = () => {
    const isSearched = this.props.searchParams.get("isSearched");
    if (isSearched) {
      const query = (this.props as any).searchParams;
      const filters: any = {};
      const custom: any = {};
      for (const entry of query.entries()) {
        const [param, value] = entry;
        if (param === "dueDate") {
          const splitDate = value.split("-");
          const fromDate = splitDate[0].split("/");
          const toDate = splitDate[1].split("/");
          const fromIsoDate =
            value === "OverDue"
              ? new Date(2001, 0, 1).toISOString()
              : new Date(
                  fromDate[2],
                  fromDate[1] - 1,
                  fromDate[0]
                ).toISOString();
          const toIsoDate =
            value === "OverDue"
              ? new Date().toISOString()
              : new Date(toDate[2], toDate[1] - 1, toDate[0]).toISOString();

          filters.dueDate = { from: fromIsoDate, to: toIsoDate };
        } else if (param === "dateRange") {
          const splitDate = value.split("-");
          const fromDate = splitDate[0].split("/");
          const toDate = splitDate[1].split("/");

          const fromIsoDate = new Date(
            fromDate[2],
            fromDate[1] - 1,
            fromDate[0]
          ).toISOString();
          const toIsoDate = new Date(
            toDate[2],
            toDate[1] - 1,
            toDate[0]
          ).toISOString();

          filters.dateRange = { from: fromIsoDate, to: toIsoDate };
        } else if (param === "clientName") {
          filters.clientName = [encodeURIComponent(value)];
        } else if (
          param === "groups" ||
          param === "currentUserIds" ||
          param === "tags" ||
          param === "period"
        ) {
          filters[param] = [value];
        } else if (param === "statusIds") {
          filters[param] = [value];
          filters.openTask = false;
        } else if (param === "star") {
          filters.star = value === "Yes";
        } else if (param === "customField") {
          custom.name = value;
        } else if (param === "customFieldType") {
          custom.type = value;
        } else if (param === "customFieldValue") {
          const customValue =
            custom.type === "date" ? new Date(value).toISOString() : value;
          filters.customField = {
            name: custom.name,
            value: customValue
          };
        } else if (param === "periodType" || param === "isSearched") {
        } else {
          filters[param] = value;
        }
      }
      return filters;
    }
    return {};
  };

  getTaskData = (download?: boolean) => {
    const workSpaceId = (this.props as any).params?.firmId;
    let limit: number = this.state.chunkSize;
    let skip: number = this.state.chunkSize * this.state.currPage;
    const searchText = "";
    const filters = this.state.filters;
    download
      ? this.setState({ exportLoading: true })
      : this.setState({ loading: true });
    agent.Tasks.getTaskList(
      workSpaceId,
      limit,
      skip,
      searchText,
      filters,
      download ? true : false
    )
      .then((response: any) => {
        if (download) {
          this.setState({ exportLoading: false });
          downloadFile(response, "TaxPido PMS Tasks List.xlsx");
        } else {
          this.setState({
            tasks: response.tasks,
            loading: false,
            totalRecords: response.count,
            displayTaskDetails: response.tasks,
            searchLoading: false,
            selectedTasks: []
          });
        }
      })
      .catch((err: any) => {
        if (download) {
          this.setState({ exportLoading: false });
          const data = new Blob([err.response.data], {
            type: err.response.data.type
          });
          data.text().then(text => {
            const error = JSON.parse(text);
            (this.props as any).onNotify(
              "Could not export Task Details",
              error?.message || error,
              "danger"
            );
          });
        } else {
          this.setState({ loading: false, searchLoading: false });
          (this.props as any).onNotify(
            "Could not load Task Details",
            err?.response?.data?.message || err?.message || err,
            "danger"
          );
        }
      });
  };

  getGroupDetails = () => {
    const workSpaceId = (this.props as any).params?.firmId;
    const groupId = (this.props as any).params?.groupId;
    this.setState({ loading: true });
    agent.ClientGroups.getOneGroup(groupId, workSpaceId)
      .then((response: any) => {
        const queryFilters = this.setFilterQueries();
        this.setState(
          {
            filters: {
              ...this.state.filters,
              groups: [groupId],
              ...queryFilters
            },
            group: response.group,
            selectedRow: response.group
          },
          () => this.getTaskData()
        );
      })
      .catch((err: any) => {
        (this.props as any).onNotify(
          "Could not load Firm Details",
          err?.response?.data?.message || err?.message || err,
          "danger"
        );
      });
  };

  componentDidMount() {
    this.getGroupDetails();
    document.title = "Group Profile - TaxPido PMS";
  }

  componentDidUpdate(prevProps: any, prevState: any) {
    const prevFirmId = prevProps.params.firmId;
    const currFirmId = (this.props as any).params.firmId;
    if (prevFirmId !== currFirmId) {
      this.getGroupDetails();
    }
    const prevGroupId = prevProps.params.groupId;
    const currGroupId = (this.props as any).params?.groupId;
    if (prevGroupId !== currGroupId) {
      this.getGroupDetails();
    }
  }

  onUserTypeToShowChange = (item: { _id: UserTypeToShow }) => {
    this.setState({ selectedUserTypeToShow: item._id });
    localStorage.setItem("userTypeToShow", item._id);
  };

  onTaskCheckBoxChange = (task: any) => {
    const { selectedTasks } = this.state;
    const clientIndex = selectedTasks.findIndex((c: any) => c._id === task._id);
    if (clientIndex === -1) {
      this.setState({ selectedTasks: [...selectedTasks, task] });
    } else {
      const removeClient = selectedTasks.filter((c: any) => c._id !== task._id);
      this.setState({ selectedTasks: removeClient });
    }
  };

  onSelectAllTask = () => {
    const { displayTaskDetails, selectedTasks } = this.state;
    if (selectedTasks.length === displayTaskDetails.length) {
      this.setState({ selectedTasks: [] });
    } else {
      this.setState({ selectedTasks: displayTaskDetails });
    }
  };

  showDetails = () => {
    this.setState({ showGroupDetails: !this.state.showGroupDetails });
  };

  openEditMode = () => {
    this.setState({ showGroupDetails: true, editMode: true });
  };

  closeEditMode = () => {
    this.setState({ showGroupDetails: false, editMode: false });
  };

  openAddUserPage = () => {
    const taskRights = (this.props as any)?.rights?.clientRights;
    const createRight = taskRights.create;
    const currentFirmId = (this.props as any).params?.firmId;
    if (createRight) {
      (this.props as any).navigate(`/${currentFirmId}/tasks/add`);
    } else {
      (this.props as any).onNotify(
        "Rights Not Avilable",
        "Ask Admin to change your user rights.",
        "danger"
      );
    }
  };

  onShowTaskChange = (item: any) => {
    if (item.name === "Open") {
      const filters = this.state.filters;
      delete filters.statusIds;
      this.setState(
        {
          selectedShowTaskType: item,
          filters: { ...filters, openTask: true },
          currPage: 0
        },
        () => this.getTaskData()
      );
    } else {
      const statusId = (this.props as any).status.find(
        (s: any) => s.name === item.name
      )._id;
      this.setState(
        {
          selectedShowTaskType: item,
          currPage: 0,
          filters: {
            ...this.state.filters,
            openTask: false,
            statusIds: [statusId]
          }
        },
        () => this.getTaskData()
      );
    }
  };

  onAdvancedSearchClick = () => {
    this.setState({
      showAdvancedSearch: !this.state.showAdvancedSearch,
      showSearch: false
    });
  };

  onSearchClick = () => {
    this.setState({
      showSearch: !this.state.showSearch,
      showAdvancedSearch: false
    });
  };

  openBulkActionModal = () => {
    const { selectedTasks } = this.state;
    if (selectedTasks.length === 0) {
      (this.props as any).onNotify(
        "No Task Selected",
        "Please select at least one task to perform bulk action.",
        "danger"
      );
    } else {
      this.setState({
        showBulkActionModal: true
      });
    }
  };

  onTaskSearch = (filters: any) => {
    this.setState({ currPage: 0, searchLoading: true, filters }, () => {
      this.getTaskData();
    });
  };

  onSearchCancelClick = (filters: any, isSearched: boolean) => {
    this.setState(
      {
        showSearch: false,
        showAdvancedSearch: false,
        filters
      },
      () => {
        isSearched && this.getTaskData();
      }
    );
  };

  bulkActionModalSetOpen = (open: boolean) => {
    this.setState({
      showBulkActionModal: open,
      selectedTasks: []
    });
  };

  editModalSetOpen = (open: boolean) => {
    this.setState({
      showEditModal: open
    });
  };

  onLoad = () => {
    this.getGroupDetails();
    this.closeEditMode();
  };

  render() {
    TagManager.dataLayer(tagManagerArgs);
    return (
      <Dashboard>
        {this.state.showBulkActionModal && (
          <BulkActionModal
            state={this.state}
            bulkActionModalSetOpen={this.bulkActionModalSetOpen}
            onLoad={this.getTaskData}
          />
        )}

        {this.state.showEditModal && (
          <EditGroupModal
            state={this.state}
            onLoad={this.onLoad}
            editModalSetOpen={this.editModalSetOpen}
          />
        )}
        <div className="w-full mx-auto gstadd">
          <div className="mt-5 md:mt-0 md:col-span-2">
            <form onSubmit={e => e.preventDefault()}>
              <div className="shadow sm:rounded-md sm:overflow-hidden">
                <div className="px-4 py-5 bg-white space-y-6">
                  <div className="flex sm:flex-row flex-col sm:items-start sm:justify-between">
                    <h3 className="text-lg font-medium leading-6 text-gray-900">
                      {this.state.group?.name ? this.state.group.name : "-"}
                    </h3>

                    <div className="sm:flex mt-2 sm:mt-0">
                      <button
                        type="button"
                        className={
                          "mt-3 sm:ml-4 w-full inline-flex whitespace-nowrap items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm px-2 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:text-sm"
                        }
                        onClick={this.showDetails}
                      >
                        {this.state.showGroupDetails
                          ? "Hide Details"
                          : "Show Details"}
                      </button>
                      <button
                        type="button"
                        className={
                          "mt-3 sm:ml-4 w-full inline-flex whitespace-nowrap items-center justify-center rounded-md border border-transparent border-gray-300 shadow-sm px-2 py-2 bg-indigo-600 text-base font-medium text-white hover:bg-indigo-700 focus:outline-none sm:mt-0 sm:text-sm"
                        }
                        onClick={() => this.editModalSetOpen(true)}
                      >
                        <Icon name="edit" className="w-4 h-4 mr-2" />
                        Edit Group
                      </button>
                    </div>
                  </div>
                  {!this.state.showGroupDetails ? (
                    <div className="border-t">
                      <div className="flex items-center justify-between gap-4 mt-6">
                        <div className="w-fit flex items-center sm:flex-row flex-col gap-4">
                          <button
                            type="button"
                            className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                            onClick={this.openAddUserPage}
                          >
                            {(this.props as any)?.rights?.clientRights
                              .create ? (
                              <Icon name="add" className="h-4 w-4 mr-2" />
                            ) : (
                              <Icon
                                name="outline/lock-closed"
                                className="h-4 w-4 mr-2"
                              />
                            )}
                            Add Task
                          </button>
                          <div className="w-40">
                            <MultiSelect
                              items={showTaskType?.map((item: any) => {
                                return {
                                  _id: item,
                                  name: item
                                };
                              })}
                              selected={{
                                name: this.state.selectedShowTaskType?.name
                              }}
                              type="showTask"
                              onChange={this.onShowTaskChange}
                              placeholder="Show Task"
                            />
                          </div>
                        </div>

                        {this.state.totalRecords > -1 && (
                          <div className="flex sm:flex-row flex-col sm:justify-between gap-4 w-64">
                            <button
                              type="button"
                              className="text-sm text-gray-600 hover:text-indigo-700 underline"
                              onClick={this.onSearchClick}
                            >
                              Search
                            </button>
                            <button
                              type="button"
                              className="text-sm text-gray-600 hover:text-indigo-700 underline"
                              onClick={this.onAdvancedSearchClick}
                            >
                              Advance Search
                            </button>
                          </div>
                        )}
                        <div className="flex items-center gap-4">
                          <div className="w-40">
                            <MultiSelect
                              items={userTypeOptions?.map((item: any) => {
                                return {
                                  _id: item,
                                  name: item
                                };
                              })}
                              selected={{
                                name: this.state.selectedUserTypeToShow
                              }}
                              type="UserTypeToShow"
                              onChange={this.onUserTypeToShowChange}
                              placeholder="Show User Type"
                            />
                          </div>
                          <div className="flex sm:flex-row flex-col gap-4 mt-1">
                            <button
                              type="button"
                              className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                              onClick={this.openBulkActionModal}
                            >
                              Bulk Action
                            </button>
                            <button
                              type="button"
                              className="relative whitespace-nowrap inline-flex items-center sm:px-4 px-3 py-2 border border-transparent shadow-sm sm:text-sm text-xs font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none"
                              onClick={() => this.getTaskData(true)}
                            >
                              {this.state.exportLoading ? (
                                <Icon name="loading" className="h-4 w-4 mr-2" />
                              ) : (
                                <Icon
                                  name="outline/download"
                                  className="h-4 w-4 mr-2"
                                />
                              )}
                              Export
                            </button>
                          </div>
                        </div>
                      </div>

                      <div>
                        {(this.state.showSearch ||
                          this.state.showAdvancedSearch) && (
                          <TaskSearch
                            type={"group"}
                            group={this.state.group?.name}
                            showAdvancedSearch={this.state.showAdvancedSearch}
                            navigate={this.props.navigate}
                            location={this.props.location}
                            params={this.props.params}
                            setSearchParams={this.props.setSearchParams}
                            searchParams={this.props.searchParams}
                            onTaskSearch={this.onTaskSearch}
                            onSearchCancelClick={this.onSearchCancelClick}
                            onLoad={this.getTaskData}
                            searchLoading={this.state.searchLoading}
                            filters={this.state.filters}
                            selectedShowTaskType={
                              this.state.selectedShowTaskType
                            }
                          />
                        )}
                      </div>
                      <div>
                        <TaskTable
                          type="group"
                          userTypeToShow={this.state.selectedUserTypeToShow}
                          loading={this.state.loading}
                          displayTaskDetails={this.state.displayTaskDetails}
                          filters={this.state.filters}
                          totalRecords={this.state.totalRecords}
                          onLoad={this.getTaskData}
                          selectedTasks={this.state.selectedTasks}
                          onSelectAllTask={this.onSelectAllTask}
                          onTaskCheckBoxChange={this.onTaskCheckBoxChange}
                          navigate={this.props.navigate}
                          location={this.props.location}
                          params={this.props.params}
                          searchParams={this.props.searchParams}
                          setSearchParams={this.props.setSearchParams}
                        />
                      </div>
                      <Pagination
                        displayRecords={this.state.displayTaskDetails}
                        totalRecords={this.state.totalRecords}
                        chunkSize={this.state.chunkSize}
                        currPage={this.state.currPage}
                        handlePageClick={this.handlePageClick}
                        handleItemPerPage={this.handleItemPerPage}
                        className="my-4"
                      />
                    </div>
                  ) : (
                    <div>
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:py-5">
                        <label
                          htmlFor="first-name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Name
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <input
                            type="text"
                            name="first-name"
                            id="first-name"
                            autoComplete="given-name"
                            className={`block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:max-w-xs sm:text-sm ${
                              this.state.editMode ? "" : "cursor-not-allowed"
                            }`}
                            placeholder="Name"
                            onChange={() => null}
                            value={this.state.group?.name || ""}
                            disabled={!this.state.editMode}
                          />
                        </div>
                      </div>
                      <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5">
                        <label
                          htmlFor="trade-name"
                          className="block text-sm font-medium text-gray-700 sm:mt-px sm:pt-2"
                        >
                          Description
                        </label>
                        <div className="mt-1 sm:col-span-2 sm:mt-0">
                          <textarea
                            id="registeredAddress"
                            name="registeredAddress"
                            rows={4}
                            disabled={!this.state.editMode}
                            className={`"block w-full max-w-lg rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm ${
                              this.state.editMode ? "" : "cursor-not-allowed"
                            }`}
                            value={this.state.group?.description || ""}
                            placeholder="Add Registered Address..."
                            onChange={() => null}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </form>
          </div>
        </div>
      </Dashboard>
    );
  }
}

export default compose(
  connector,
  withRouter
)(GroupDetails) as React.ComponentType;
